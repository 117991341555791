import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import vwEmployeeEvaluationDetailedReport from "./vw_EmployeeEvaluationDetailedReport";
import EvalutionSetup from "./EvalutionSetup";
import Highcharts from "highcharts";
import Charts from "../../../Reuseable_Components/Chart/Charts";

(function (H) {
  H.seriesTypes.pie.prototype.animate = function (init) {
    const series = this,
      chart = series.chart,
      points = series.points,
      { animation } = series.options,
      { startAngleRad } = series;

    function fanAnimate(point, startAngleRad) {
      const graphic = point?.graphic,
        args = point?.shapeArgs;

      // Check if 'graphic' is defined before accessing its properties
      if (graphic && args) {
        graphic
          // Set initial animation values
          .attr({
            start: startAngleRad,
            end: startAngleRad,
            opacity: 1,
          })
          // Animate to the final position
          .animate(
            {
              start: args.start,
              end: args.end,
            },
            {
              duration: animation.duration / points.length,
            },
            function () {
              // On complete, start animating the next point
              if (points[point?.index + 1]) {
                fanAnimate(points[point?.index + 1], args.end);
              }
              // On the last point, fade in the data labels, then apply the inner size
              if (point?.index === series.points.length - 1) {
                series.dataLabelsGroup.animate(
                  {
                    opacity: 1,
                  },
                  void 0,
                  function () {
                    points.forEach((point) => {
                      point.opacity = 1;
                    });
                    series.update(
                      {
                        enableMouseTracking: true,
                      },
                      false
                    );
                  }
                );
              }
            }
          );
      } else {
        // If graphic is not available, skip animation for this point
        console.warn(
          `Graphic for point at index ${point?.index} is not available.`
        );
      }
    }

    if (init) {
      // Hide points on init
      points.forEach((point) => {
        point.opacity = 0;
      });
    } else {
      fanAnimate(points[0], startAngleRad);
    }
  };
})(Highcharts);

const groupDataByEmployee = (data) => {
  const grouped = data.reduce((acc, item) => {
    const {
      UserId,
      EmployeeName,
      EvalutionSetupId,
      EmployeeScore,
      EmployeeRating,
      DistrictName,
    } = item;
    if (!acc[UserId]) {
      acc[UserId] = {
        userId: UserId,
        employeeName: EmployeeName,
        district: DistrictName,
        evaluations: {},
        totalScore: 0,
      };
    }
    const score = EmployeeScore || EmployeeRating || 0; // Use EmployeeScore or EmployeeRating, default to 0
    if (!acc[UserId].evaluations[EvalutionSetupId]) {
      acc[UserId].evaluations[EvalutionSetupId] = { score, count: 1 };
    } else {
      acc[UserId].evaluations[EvalutionSetupId].score += score;
      acc[UserId].evaluations[EvalutionSetupId].count += 1;
    }
    acc[UserId].totalScore += score;
    return acc;
  }, {});

  return Object.values(grouped);
};

const getEvaluationTitle = (evalId) => {
  const evaluation = EvalutionSetup.find(
    (evalSetup) => evalSetup.EvalutionSetupId === evalId
  );
  return evaluation ? evaluation.EvalutionSetupTitle : "Unknown Evaluation";
};

const EvalutionDashboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    const groupedEmployees = groupDataByEmployee(
      vwEmployeeEvaluationDetailedReport
    );
    const sortedEmployees = groupedEmployees.sort(
      (a, b) => b.totalScore - a.totalScore
    );
    setLeaderboard(sortedEmployees);
  }, []);

  const pieData = leaderboard.map((employee) => ({
    name: employee.employeeName,
    y: employee.totalScore,
    // showInLegend: true,
  }));

  // const colors = [
  //   "#8884d8",
  //   "#82ca9d",
  //   "#ffc658",
  //   "#d0ed57",
  //   "#8dd1e1",
  //   "#a4de6c",
  // ];

  const handleRowClick = (employee) => {
    alert(
      `Employee: ${employee.employeeName}\nTotal Score: ${employee.totalScore}\nDistrict: ${employee.district}`
    );
  };

  const chartOptions = {
    chart: {
      type: "pie",
      marginTop: 50,
    },
    title: {
      text: "",
    },

    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25cf</span> ' +
        "{point.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        borderWidth: 2,
        cursor: "pointer",
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f}",
          // format: "<b>{point.name}</b><br>{point.percentage}%",
          distance: 20,
        },
      },
    },
    // Customizing the legend
    legend: {
      enabled: true, // Show legend (true/false)
      align: "center", // 'left', 'center', 'right'
      verticalAlign: "top", // 'top', 'middle', 'bottom'
      layout: "horizontal", // 'horizontal' or 'vertical'
      itemStyle: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#333333",
        cursor: "pointer",
      },
      itemDistance: 10, // Distance between legend items
      symbolHeight: 14, // Height of the symbol in the legend
      symbolWidth: 14, // Width of the symbol in the legend
      symbolRadius: 0, // Set to 0 for square-shaped symbols (default is circle)
    },
    series: [
      {
        // Disable mouse tracking on load, enable after custom animation
        enableMouseTracking: true,
        animation: {
          duration: 2000,
        },
        colorByPoint: true,
        data: pieData,
      },
    ],
  };

  return (
    <Box sx={{ padding: "16px", backgroundColor: "#ffffff" }}>
      <Typography
        variant="h4"
        gutterBottom
        fontFamily={`"Poppins", sans-serif`}
      >
        Employee Dashboard
      </Typography>

      <Grid container spacing={3}>
        {/* Leaderboard */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              //   backgroundColor: "#1a2138",
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0px 0px 20px rgba(144, 144, 144, 0.25)",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              fontFamily={`"Poppins", sans-serif`}
            >
              Leaderboard
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "8px",
                // backgroundColor: "#1a2138"
                boxShadow: "0px 0px 20px rgba(144, 144, 144, 0.25)",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {["Rank", "Name", "Total Score"].map((header) => (
                      <TableCell
                        key={header}
                        sx={{
                          fontFamily: `"Poppins", sans-serif`,
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaderboard.map((employee, index) => (
                    <TableRow
                      key={employee.userId}
                      sx={{
                        // backgroundColor:
                        //   index % 2 === 0 ? "#1e293b" : "#2c3e50",
                        cursor: "pointer",
                        "&:hover": { opacity: 0.9 },
                      }}
                      onClick={() => handleRowClick(employee)}
                    >
                      <TableCell
                        sx={{
                          fontFamily: `"Poppins", sans-serif`,
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: `"Poppins", sans-serif`,
                        }}
                      >
                        {employee.employeeName}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: `"Poppins", sans-serif`,
                        }}
                      >
                        {employee.totalScore}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        {/* Pie Chart */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              //   backgroundColor: "#1a2138",
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0px 0px 20px rgba(144, 144, 144, 0.25)",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              fontFamily={`"Poppins", sans-serif`}
            >
              Score Distribution
            </Typography>
            <Charts
              series={chartOptions}
              highcharts={Highcharts}
              position="top"
            />
            {/* <ResponsiveContainer width="100%" height={500}>
              <PieChart>
                <Pie
                  data={pieData}
                  dataKey="value"
                  nameKey="name"
                  outerRadius={200}
                  label
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      // fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Legend verticalAlign="top" height={36} />
              </PieChart>
            </ResponsiveContainer> */}
          </Box>
        </Grid>
      </Grid>

      {/* Full Employee Table */}
      <Box
        sx={{
          marginTop: "24px",
          // boxShadow: "0px 0px 20px rgba(144, 144, 144, 0.25)",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          fontFamily={`"Poppins", sans-serif`}
        >
          Employee Details
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "8px",
            //  backgroundColor: "#1a2138"
            boxShadow: "0px 0px 20px rgba(144, 144, 144, 0.25)",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {["Name", "District", "Evaluations", "Total Score"].map(
                  (header) => (
                    <TableCell
                      key={header}
                      sx={{
                        fontFamily: `"Poppins", sans-serif`,
                      }}
                    >
                      {header}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboard.map((employee) => (
                <TableRow
                  key={employee.userId}
                  sx={{
                    // backgroundColor:
                    //   leaderboard.indexOf(employee) % 2 === 0
                    //     ? "#1e293b"
                    //     : "#2c3e50",
                    cursor: "pointer",
                    "&:hover": { opacity: 0.9 },
                  }}
                  onClick={() => handleRowClick(employee)}
                >
                  <TableCell
                    sx={{
                      fontFamily: `"Poppins", sans-serif`,
                    }}
                  >
                    {employee.employeeName}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: `"Poppins", sans-serif`,
                    }}
                  >
                    {employee.district}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: `"Poppins", sans-serif`,
                    }}
                  >
                    {Object.entries(employee.evaluations).map(
                      ([evalId, evalData]) => (
                        <Typography key={evalId}>
                          {getEvaluationTitle(evalId)}: {evalData.score} (Count:{" "}
                          {evalData.count})
                        </Typography>
                      )
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: `"Poppins", sans-serif`,
                    }}
                  >
                    {employee.totalScore}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default EvalutionDashboard;
