import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { DeleteDataFor } from "../../../../reducers/user";
import axios from "axios";
import { Box, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import Config from "../../../../Utils/Config";
import Swal from "sweetalert2";
import { PostCallApi } from "../../../../Action/Action";
import { SwalError, SwalSuccess } from "../../../../components/SwalToast";
import { useSignalR } from "../../../../Utils/SignalRContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function Toggle_button(props) {
  const [open, setOpen] = useState(false);
  const [seDate, setSeDate] = useState();
  const [openCircle, setOpenCircle] = React.useState(false);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const {
    checked,
    disabled,
    offstyle = "btn-danger",
    onstyle = "btn-success",
    url,
  } = props;
  const [checkvalue, setCheckValue] = useState(true);
  useEffect(() => {
    if (checked === "0") {
      setCheckValue(false);
    } else {
      setCheckValue(true);
    }
  }, [checked]);
  const deletedSuccess = useSelector((state) => state.user?.deleteData);
  let displayStyle = checkvalue ? onstyle : offstyle;
  const dispatch = useDispatch();
  const { connection } = useSignalR();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const handlechnage = (e) => {
    handleOpen();
  };
  useEffect(() => {
    if (open) {
      setSeDate(new Date().toISOString().split("T")[0]);
    }
  }, [open]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handledelete = async () => {
    // this is for isactive
    setOpenCircle(true);
    let md = props.method;
    if (!md) {
      axios
        .delete(url, { headers })
        .then((responce) => {
          if (responce.data.success) {
            if (props.menuApi) {
              connection.invoke("MenuUpdateOnlineUser", LogDetail?.userId, "The menu has been updated. To reflect the changes, you will need to log in again.");
            }
            if (deletedSuccess) {
              dispatch(DeleteDataFor(false));
            } else {
              dispatch(DeleteDataFor(true));
            }
            setOpenCircle(false);
            handleClose();
            if (checkvalue === true) {
              setCheckValue(false);
            } else {
              setCheckValue(true);
            }
          } else {
            dispatch(DeleteDataFor(false));
            setOpenCircle(false);
            handleClose();

            Swal.fire({
              position: "center",
              width: 400,
              // icon: "success",
              text: responce.data?.message,
              // showConfirmButton: false,
              // timer: 1500,
              // color: "#ffffff",
              // background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              // showClass: {
              //   popup: "animate__animated animate__fadeInDown",
              // },
              // hideClass: {
              //   popup: "animate__animated animate__fadeOutRight",
              // },
            });
          }
        })
        .catch((error) => {
          setOpenCircle(false);
          console.log(error);
        });
    } else {
      const responce = await PostCallApi({ url: url, body: {} })

      if (responce?.status === 200 && responce?.data.success) {
        if (props.menuApi) {
          connection.invoke("MenuUpdateOnlineUser", LogDetail?.userId, "The menu has been updated. To reflect the changes, you will need to log in again.");
        }
        if (deletedSuccess) {
          dispatch(DeleteDataFor(false));
        } else {
          dispatch(DeleteDataFor(true));
        }
        setOpenCircle(false);
        handleClose();
        if (checkvalue === true) {
          setCheckValue(false);
        } else {
          setCheckValue(true);
        }
        SwalSuccess(responce.data?.message)
      } else {
        setOpenCircle(false);
        SwalError(responce?.data?.message)
      }

    }
  };
  const onSubmit = (data) => {
    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    axios
      .post(
        `${Config.API_HOST_URL_live}/api/User/ActiveInActiveUserWithNotes`,
        {
          ...data,
          dateChange: seDate ? convert(seDate) : convert(new Date()),
          userId: props?.userId,
          createdBy: LogDetail?.userId,
        },
        { headers }
      )
      .then((responce) => {
        if (responce.data.success) {
          if (deletedSuccess) {
            dispatch(DeleteDataFor(false));
          } else {
            dispatch(DeleteDataFor(true));
          }
          setOpenCircle(false);
          handleClose();
          if (checkvalue === true) {
            setCheckValue(false);
          } else {
            setCheckValue(true);
          }
        } else {
          dispatch(DeleteDataFor(false));
          setOpenCircle(false);
          handleClose();
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <label className="toggle_button ms-2" key={props.key}>
        <span className={`switch-wrapper`}>
          <input
            type="checkbox"
            checked={checkvalue}
            disabled={disabled}
            onChange={(e) => handlechnage(e)}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
      </label>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data1"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                {/* <h6>{props?.title}</h6>  */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="popup_delete_detail">
                    <h5>Alert</h5>
                    <p className="delete_text">
                      Are you sure you want to
                      {checkvalue ? " DeActive" : " Active"}
                      <span className=""> {props?.text}</span>?
                    </p>
                    {props?.addNote ? (
                      <div className="mx-3 mb-3">
                        <input
                          type="date"
                          className="form-control mb-3"
                          value={seDate}
                          onChange={(e) => setSeDate(e.target.value)}
                        />
                        <textarea
                          rows="5"
                          className="form-control"
                          placeholder="Enter Note"
                          {...register("notes", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        ></textarea>
                        {errors.notes && (
                          <span role="alert" className="error_text">
                            {errors.notes.message}
                          </span>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      {props?.addNote ? (
                        <button type="submit" className="popup_btn delete">
                          Submit
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="popup_btn delete"
                          onClick={handledelete}
                        >
                          Confirm
                        </button>
                      )}{" "}
                      <button
                        type="button"
                        className="popup_btn cancel"
                        onClick={handleClose}
                      >
                        cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default React.memo(Toggle_button);
