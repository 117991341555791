import React from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import "./Table_Com.scss";
// import active_img from "../../../images/Icon/active_green.svg";
// import Config from "../../../Utils/Config";
// import pro_img from "../../../images/default_profile.jpg";

function Table_Com(props) {
  let navigate = useNavigate();
  const togglePopup = (id) => {
    navigate(`/certificate/report/${id}`, { replace: true });
  };

  return (
    <>
      <Table className="">
        <thead>
          <tr>
            {props.headCells?.map((header) => (
              <React.Fragment key={header?.id}>
                <th>{header?.head}</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {props?.cource &&
            props?.cource?.map((data, i) => (
              <tr key={i + 1}>
                {props?.headCells?.map((column) => {
                  const value = data[column?.id];
                  if (value === "View Report") {
                    return (
                      <td
                        className="view_btn"
                        onClick={() => togglePopup(data?.userId)}
                      >
                        {value}
                      </td>
                    );
                  }
                  return <td key={column?.id}>{value}</td>;
                })}
                {/* <td style={{ width: "50%" }}>
                  <div className="d-flex align-items-center gap-2">
                    {data?.name}
                  </div>
                </td>
                {data?.no === "View Report" ? (
                  <td
                    className="view_btn"
                    onClick={() => togglePopup(data?.userId)}
                  >
                    {data?.no}
                  </td>
                ) : (
                  <td className="text-center">{data?.no}</td>
                )} */}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}
export default React.memo(Table_Com);
