import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import CreateData from "./Create_Data";
import { useDispatch, useSelector } from "react-redux";
import { CreteDataFor } from "../../../reducers/user";
import { styled } from "@mui/material/styles";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import Pagination from "../../Reuseable_Components/Paginations/Pagination";
import { useDownloadExcel } from "react-export-table-to-excel";
import axios from "axios";
import Config from "../../../Utils/Config";
// import Deletepopup from "./Delete_popup";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  fontFamily: `"Poppins", sans-serif`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const headCells = [
  {
    id: "CEUName",
    label: "CEU Name",
  },
  {
    id: "ceuallocation",
    label: "CEU",
  },
  {
    id: "DistrictName",
    label: "District Name",
  },
  {
    id: "instructor",
    label: "Instructor",
  },
  {
    id: "draftManualCEUDate",
    label: "Date",
  },
  {
    id: "status",
    label: "Status",
  },
];

function DraftManualCeu(prop) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("GroupName");
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [dataOfTable, setDataOfTable] = useState([]);
  const [dataEditId, setDataEditId] = useState();
  const [downloadExcel, setDownloadExcel] = useState(false);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Draft Manual CEU",
    sheet: "Users",
  });
  useEffect(() => {
    if (downloadExcel) {
      onDownload();
      setDownloadExcel(false);
    }
    // eslint-disable-next-line
  }, [downloadExcel]);
  function descendingComparator(a, b, orderBy) {
    if (
      b[orderBy]?.toString()?.toLowerCase() <
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString()?.toLowerCase() >
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return 1;
    }

    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => (
            <StyledTableCell
              key={headCell?.id}
              align="left"
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {downloadExcel ? (
                  ""
                ) : (
                  <>
                    {orderBy === headCell?.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </>
                )}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          {downloadExcel ? (
            ""
          ) : (
            <>
              <StyledTableCell>Actions</StyledTableCell>
            </>
          )}
        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const cret = useSelector((state) => state.user?.create_data);
  const deletedSuccess = useSelector((state) => state.user?.deleteData);
  const [createData, setCreateData] = useState(false);
  const MenuName = useSelector((state) => state.setting?.MenuName);
  useEffect(() => {
    if (cret !== undefined) {
      setCreateData(cret);
      setSearchField("");
    }
  }, [cret]);
  const [searchField, setSearchField] = useState("");
  const formattedSearchResults = dataOfTable?.filter((user) => {
    if (searchField) {
      let state = false;
      headCells?.forEach((property) => {
        let value = String(user[property?.id]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [MenuName]);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LogUserId = useSelector((state) => state.login.LoginDetails);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleEdit = (e) => {
    setDataEditId(e);
    dispatch(CreteDataFor(true));
  };
  useEffect(() => {
    if (cret === false) {
      getCEUList();
    }
    // eslint-disable-next-line
  }, [deletedSuccess, cret]);
  const getCEUList = () => {
    setOpen(true);
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/UserCourse/GetDraftManualCEUList?createdBy=${LogUserId?.userId}`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          setOpen(false);
          const user = response?.data.payload?.map((item, i) => {
            return {
              CEUName: item?.courseTitle ? item?.courseTitle : "",
              ceuallocation: item?.ceuallocation ? item?.ceuallocation : "",
              draftManualCEUDate: item?.draftManualCEUDate
                ? item?.draftManualCEUDate
                : "",
              UserNameList: item?.draftManualCEUUsers,
              DistrictName: item?.schoolDistrictName
                ? item?.schoolDistrictName
                : "",
              instructor: item?.instructor ? item?.instructor : "",
              status: item?.status ? item?.status : "",
              Action: (
                <>
                  {item?.status === "Draft" ? (
                    <button
                      type="button"
                      className="btn_edit"
                      onClick={(e) => handleEdit(item)}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.8899 3.00006C18.1525 2.73741 18.4643 2.52907 18.8075 2.38693C19.1507 2.24479 19.5185 2.17163 19.8899 2.17163C20.2613 2.17163 20.6291 2.24479 20.9723 2.38693C21.3154 2.52907 21.6272 2.73741 21.8899 3.00006C22.1525 3.2627 22.3609 3.57451 22.503 3.91767C22.6452 4.26083 22.7183 4.62862 22.7183 5.00006C22.7183 5.37149 22.6452 5.73929 22.503 6.08245C22.3609 6.42561 22.1525 6.73741 21.8899 7.00006L8.38989 20.5001L2.88989 22.0001L4.38989 16.5001L17.8899 3.00006Z"
                          stroke="#1EBEC8"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  ) : (
                    ""
                  )}
                  {/* <Deletepopup
                    title="Delete Menual Ceu"
                    text={`${item?.userList?.userName}'s ${item?.ceuTitle} CEU`}
                    url={`${Config.API_HOST_URL_live}/api/Ceu/DeleteCEU?CEUId=${item.ceuId}&UserId=${item.userId}`}
                  /> */}
                </>
              ),
            };
          });
          setDataOfTable(user);
        } else {
          setOpen(false);
          setDataOfTable();
        }
      });
  };
  const DashBtnList = useSelector((state) => state?.setting?.DashBtnList);
  const handleCreate = (data) => {
    setDataEditId();
    setCreateData(true);
    dispatch(CreteDataFor(true));
  };
  return (
    <>
      {createData === false ? (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="mb-4 d-flex justify-content-between listdata_control_sec">
            <label>Draft Manual CEU</label>

            <div className=" search_sec w-25 ms-auto me-4">
              <div className="search_input w-100">
                <input
                  onChange={(e) => {
                    setSearchField(e.target.value);
                    setCurrentPage(1);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <img src={search_icon} alt="" />
              </div>
            </div>
            <div className="d-flex">
              <Button
                type="button"
                className="cource_reschedule_button light_btn"
                onClick={onDownload}
              >
                Download
              </Button>
              {DashBtnList?.map((check, i) => {
                if (
                  check?.menuName === "Add Manual CEU Dashboard" &&
                  check?.isAssigned === true
                ) {
                  return (
                    <Button
                      key={i}
                      className="cource_reschedule_button btn_space"
                      onClick={() => handleCreate(check?.menuName)}
                    >
                      Add Draft Manual CEU
                    </Button>
                  );
                }
                return "";
              })}
            </div>
          </div>
          <hr className="tabpannel_hr" />
          <div className="mt-3 school_district">
            <Paper className="table-box">
              <TableContainer className="" ref={tableRef}>
                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(
                      formattedSearchResults,
                      getComparator(order, orderBy)
                    )
                      ?.slice(
                        (currentPage - 1) * pageSize,
                        (currentPage - 1) * pageSize + pageSize
                      )
                      ?.map((row, index) => {
                        return (
                          <StyledTableRow
                            className="table_body"
                            key={index + 1}
                          >
                            {headCells?.map((column) => {
                              const value = row[column?.id];
                              return (
                                <StyledTableCell
                                  className="column_data"
                                  key={column?.id}
                                >
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                                </StyledTableCell>
                              );
                            })}
                            {downloadExcel ? (
                              ""
                            ) : (
                              <>
                                <StyledTableCell
                                  className="column_data"
                                  key={index + 1}
                                >
                                  <div className="edit_delete">
                                    {row?.Action}
                                  </div>
                                </StyledTableCell>
                              </>
                            )}
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="page_nation">
                <div className="rowsPerPageOptions">
                  <span className="p_18_text">show</span>
                  <select
                    className="pagination_select p_16_text"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(parseInt(e.target.value));
                      setCurrentPage(1);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                  <span className="p_18_text">entries</span>
                </div>
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={formattedSearchResults?.length}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </Paper>
          </div>
        </>
      ) : (
        <>
          {dataEditId ? (
            <CreateData title="Create Draft Manual Ceu" editId={dataEditId} />
          ) : (
            <CreateData title="Create Draft Manual Ceu" />
          )}
        </>
      )}
    </>
  );
}
export default React.memo(DraftManualCeu);
