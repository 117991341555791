const EvalutionSetup_data = [
    {
        "EvalutionSetupId":"B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTitle":"Initial Setup",
        "IsActive":true,
        "CreatedOn":1721779090647,
        "CreatedBy":"74F18CF6-876A-447D-923C-D9425F973CFA",
        "LastUpdatedOn":1735716453500,
        "LastUpdatedBy":"EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "EvalutionGroupId":"F431ACA3-A47F-414C-B52A-56F9EDA9E555"
    },
    {
        "EvalutionSetupId":"EBF3AB13-A2DE-4C11-B509-4C09F5B1485C",
        "EvalutionSetupTitle":"Initial Setup",
        "IsActive":false,
        "CreatedOn":1721779365410,
        "CreatedBy":"74F18CF6-876A-447D-923C-D9425F973CFA",
        "LastUpdatedOn":1721779383390,
        "LastUpdatedBy":"74F18CF6-876A-447D-923C-D9425F973CFA",
        "EvalutionGroupId":null
    },
    {
        "EvalutionSetupId":"C978663F-454B-4275-85FB-7216440A8B9C",
        "EvalutionSetupTitle":"Self Evaluation",
        "IsActive":true,
        "CreatedOn":1731935286313,
        "CreatedBy":"74F18CF6-876A-447D-923C-D9425F973CFA",
        "LastUpdatedOn":null,
        "LastUpdatedBy":null,
        "EvalutionGroupId":null
    },
    {
        "EvalutionSetupId":"84C16AF3-223B-4E6B-B9B4-C94A1EA9076F",
        "EvalutionSetupTitle":"test setup",
        "IsActive":false,
        "CreatedOn":1735635436033,
        "CreatedBy":"242B01BA-CAE4-4FC4-B5EF-E6E3FD543E71",
        "LastUpdatedOn":1735725094663,
        "LastUpdatedBy":"EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "EvalutionGroupId":"53D4D134-BDDA-48E4-B0CD-B9FB52B64E48"
    },
    {
        "EvalutionSetupId":"3B3356A3-6697-4B53-B7E5-00B7F87B5C4D",
        "EvalutionSetupTitle":"test up for testing",
        "IsActive":false,
        "CreatedOn":1735686052470,
        "CreatedBy":"242B01BA-CAE4-4FC4-B5EF-E6E3FD543E71",
        "LastUpdatedOn":1735709811253,
        "LastUpdatedBy":"74F18CF6-876A-447D-923C-D9425F973CFA",
        "EvalutionGroupId":"EAEEC4A4-F5F9-4B4B-8228-FE5971C1B751"
    },
    {
        "EvalutionSetupId":"21C147EE-A44E-4D3F-86D6-3C8B4B7187CB",
        "EvalutionSetupTitle":"hello123",
        "IsActive":false,
        "CreatedOn":1735686536743,
        "CreatedBy":"242B01BA-CAE4-4FC4-B5EF-E6E3FD543E71",
        "LastUpdatedOn":1735722544060,
        "LastUpdatedBy":"EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "EvalutionGroupId":"D32214B4-C47A-4EF0-ACA2-0972990D9B84"
    },
    {
        "EvalutionSetupId":"3A3A6216-D2A8-4549-8B6C-5F24978AAA7D",
        "EvalutionSetupTitle":"testsetup",
        "IsActive":false,
        "CreatedOn":1735687806950,
        "CreatedBy":"242B01BA-CAE4-4FC4-B5EF-E6E3FD543E71",
        "LastUpdatedOn":1735710460333,
        "LastUpdatedBy":"74F18CF6-876A-447D-923C-D9425F973CFA",
        "EvalutionGroupId":"D32214B4-C47A-4EF0-ACA2-0972990D9B84"
    },
    {
        "EvalutionSetupId":"BD9EB9A6-3DE0-4528-A4CC-FC6584FE3686",
        "EvalutionSetupTitle":"evl setup1",
        "IsActive":false,
        "CreatedOn":1735713717777,
        "CreatedBy":"242B01BA-CAE4-4FC4-B5EF-E6E3FD543E71",
        "LastUpdatedOn":1735722558270,
        "LastUpdatedBy":"EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "EvalutionGroupId":"05F15D94-612A-4998-9D84-6326B4CBA6FC"
    }
];export default EvalutionSetup_data;
