import React from 'react';
import { ReactTyped } from "react-typed";

const MotionType = ({ text = "", setDisable }) => {


    return (
        <>
            <ReactTyped
                strings={[text ?? ""]}
                typeSpeed={20}  // Typing speed
                backSpeed={50}   // Speed of backspacing
                backDelay={1000} // Delay before starting to backspace
                startDelay={500} // Delay before typing starts
                loop={false}     // Disable looping
                showCursor={false} // Remove cursor after typing
                onBegin={() => setDisable(true)}
                // onStart={()=>console.log("start",true)}
                onComplete={() => setDisable(false)}
            />
        </>
    );
};

export default MotionType;


