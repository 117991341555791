import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav, Navbar } from "reactstrap";
import "./index.scss";
import Logo from "../../../images/fig_CROP.png";
import togglemenu from "../../../images/toggle-menu.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

function WebMenu() {
  /* == Navigation Menu Start */
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const menulist = [
    {
      name: "Home",
      pathname: "/Home",
    },
    {
      name: "About Us",
      pathname: "/about",
    },
    {
      name: "Ceu Courses",
      pathname: "/ceu",
    },
    {
      name: "Fig Lifestyle",
      pathname: "/figlifestyle",
    },
    {
      name: "Coaches",
      pathname: "/coaches",
    },
    {
      name: "Contact Us",
      pathname: "/contactus",
    },
  ];
  const list = (anchor) => (
    <div
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 280 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menulist?.map((text, i) => (
          <ListItem key={i} disablePadding className="tab-list">
            <Link to={text?.pathname} className="tab-link">
              <ListItemText primary={text?.name} className="tab-text" />
            </Link>
          </ListItem>
        ))}
      </List>
      <div className="emp-re-side">
        <Link
          className="primary-btn"
          style={{
            textDecoration: "none",
            boxShadow: "5px 4px 8px 0px rgba(0, 0, 0, 0.25)",
          }}
          target="_blank"
          to="https://netorgft6407944.sharepoint.com/sites/allcompany580/SitePages/CollabHome.aspx"
        >
          Employee Resources
        </Link>
      </div>
    </div>
  );

  /* == End Navigation Menu */

  // Sticky Header Js
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };
  // End Sticky Headser Js

  return (
    <div className="webmenu">
      <div className="container">
        <Navbar expand="lg" className={`nav_bar ${stickyClass}`}>
          <div to="" className="main-togggle">
            <div className="left-sidebar">
              {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button onClick={toggleDrawer(anchor, true)}>
                    <img src={togglemenu} alt="Toggle" />
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className="tab-box"
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="FIG" className="img-fluid" />
              </Link>
            </div>
          </div>

          <Nav className="nav_action align-items-center">
            <li>
              <NavLink
                to="/"
                // to="/home"
                // className="link-item"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "link-item"
                    : isActive
                    ? "link-item active"
                    : "link-item"
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                // className="link-item"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "link-item"
                    : isActive
                    ? "link-item active"
                    : "link-item"
                }
              >
                About Us
              </NavLink>
              <NavLink
                to="/ceu"
                // className="link-item"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "link-item"
                    : isActive
                    ? "link-item active"
                    : "link-item"
                }
              >
                Ceu Courses
              </NavLink>
              <NavLink
                to="/figlifestyle"
                // className="link-item"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "link-item"
                    : isActive
                    ? "link-item active"
                    : "link-item"
                }
              >
                Fig Lifestyle
              </NavLink>
              <NavLink
                to="/coaches"
                // className="link-item"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "link-item"
                    : isActive
                    ? "link-item active"
                    : "link-item"
                }
              >
                Coaches
              </NavLink>
              <NavLink
                to="/free"
                // className="link-item"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "link-item"
                    : isActive
                    ? "link-item active"
                    : "link-item"
                }
              >
                Free Sources
              </NavLink>
              <NavLink
                to="/contactus"
                // className="link-item"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "link-item"
                    : isActive
                    ? "link-item active"
                    : "link-item"
                }
              >
                Contact Us
              </NavLink>
            </li>
          </Nav>
          <div className="d-flex">
            <div className="sign-btn me-2">
              <Link to="/login">Sign in </Link>
            </div>
            <div className="emp-re">
              <Link
                className="primary-btn"
                style={{
                  textDecoration: "none",
                  boxShadow: "5px 4px 8px 0px rgba(0, 0, 0, 0.25)",
                }}
                target="_blank"
                to="https://netorgft6407944.sharepoint.com/sites/allcompany580/SitePages/CollabHome.aspx"
              >
                Employee Resources
              </Link>
            </div>
          </div>
        </Navbar>
      </div>
    </div>
  );
}

export default React.memo(WebMenu);
