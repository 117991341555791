import React, { useEffect } from "react";
import ReportCommonTable from "../ReportCommonTable";
import { Backdrop, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import EmployeeDetailsPage from "./EmployeeDetailsPage";
import { MdFileUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  SwalError,
  SwalMessage,
  SwalSuccess,
} from "../../../../components/SwalToast";
import { validateImage } from "../../../../components/Validation";
import { Link } from "react-router-dom";
import Config from "../../../../Utils/Config";
import { set } from "react-hook-form";

const columns = [
  { id: "no", headerName: "No" },
  { id: "userName", headerName: "Employee Name" },
  { id: "dateOfEmployeeReview", headerName: "Evalution Date" },
  { id: "averageRatingForEval", headerName: "Average Rating" },
  { id: "setupTitle", headerName: "Setup Title" },
  { id: "evalutionGroupName", headerName: "Evalution Group Name", widthFix:600 },
  { id: "action", headerName: "Action", download: false },
];

function EvaluationReport() {
  const [openCircle, setOpenCircle] = React.useState(false);
  const [listData, setListData] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const handleFileChange = async (e, item) => {
    const file = e.target.files[0];
    if (file) {
      if (validateImage(file.name)) {
        const formData = new FormData();
        formData.append("EmployeeSignImage", file);
        formData.append("EvalutionEmployeeId", item.evalutionEmployeeId);
        formData.append("CreatedBy", LogDetail.userId);
        // formData.append("employeeName", selectedEmployee.employeeName);
        // formData.append("dateOfReview", selectedEmployee.dateOfReview);
        // formData.append("averageRatingForEval", selectedEmployee.averageRatingForEval);
        // formData.append("evalutionId", selectedEmployee.evalutionId
        // );
        // const config = {
        //   headers: {
        //     "content-type": "multipart/form-data",
        //   },

        try {
          const response = await PostCallApi({
            url: "/api/EvalutionEmployee/UploadEmployeeSignature",
            body: formData,
          });
          if (response.data.success) {
           fetchData();
            SwalSuccess("File uploaded successfully");
          } else {
            SwalError("Failed to upload file");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        SwalMessage("Please select valid image file");
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setOpenCircle(true);
    try {
      const response = await GetCallApi({
        url: `/api/Report/GetEmployeeEvalutionReport?UserId=${LogDetail.userId}`,
      });
      if (response.data.success && response.data.payload?.length > 0) {
        const data = response.data.payload?.map((item, index) => {
          let signImage = item.signatureImageUrl
            ? Config.API_HOST_URL_live + item.signatureImageUrl
            : null;
          return {
            ...item,
            no: index + 1,
            signatureImageUrl: signImage,
            action: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className="theme_btn"
                  onClick={() => {
                    setSelectedEmployee({
                      ...item,
                      signatureImageUrl: signImage,
                    });
                  }}
                >
                  View Details
                </button>
                {signImage ? (
                  <Tooltip title="View Image" placement="bottom" arrow>
                    <Link
                      target="_blank"
                      to={signImage}
                      className="theme_btn ms-2"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      View Signature
                    </Link>
                  </Tooltip>
                ) : (
                  <Tooltip title="Upload Image" placement="bottom" arrow>
                    <IconButton
                      size="small"
                      component="label"
                      type="button"
                      sx={{
                        marginLeft: "0.5rem",
                        background:
                          "linear-gradient(90deg, #1ebec8 -7.19%, #86c65a 105.94%)",
                        color: "#fff",
                        // "&:hover": {
                        //   backgroundColor: theme.palette.secondary.dark,
                        // },
                      }}
                    >
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        // multiple
                        // max={5}
                        onChange={(e) =>
                          handleFileChange(e,item)
                        }
                      />
                      <MdFileUpload color="#fff" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ),
          };});
        setListData(data);
      } else {
        setListData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setOpenCircle(false);
    } finally {
      setOpenCircle(false);
    }
  };

  const handleCloseModal = () => {
    setSelectedEmployee(null);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className=" listdata_control_sec d-flex">
        <label style={{marginBottom:"1.5rem"}}>Evaluation Report</label>
      </div>
      {selectedEmployee ? (
        <EmployeeDetailsPage
          employee={selectedEmployee}
          onClose={handleCloseModal}
        />
      ) : (
        <ReportCommonTable
          headCell={columns}
          data={listData}
          setOpen={setOpenCircle}
          open={openCircle}
          searchShow={true}
          download={true}
          fileName="Evaluation Report"
        />
      )}
    </>
  );
}

export default EvaluationReport;
