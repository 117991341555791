import React, { useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DeleteDataFor } from "../../../../reducers/user";
import { Box, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { PostCallApi } from "../../../../Action/Action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function CopySetup(props) {
  const [open, setOpen] = useState(false);
  const [openCircle, setOpenCircle] = React.useState(false);

  const { evalutionSetupId } = props;

  const deletedSuccess = useSelector((state) => state.user?.deleteData);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (data) => {
    setOpenCircle(true);
    let response = await PostCallApi({
      url: `/api/EvalutionSetup/CopyEvalutionSetup`,
      body: {
        evalutionSetupId: evalutionSetupId,
        newEvalutionSetupTitle: data?.newEvalutionSetupTitle,
      },
    });
    if (response.status === 200) {
      if (response.data.success) {
        if (deletedSuccess) {
          dispatch(DeleteDataFor(false));
        } else {
          dispatch(DeleteDataFor(true));
        }
        setOpenCircle(false);
        handleClose();
      } else {
        dispatch(DeleteDataFor(false));
        setOpenCircle(false);
        handleClose();
      }
    }
  };
  return (
    <>
      <button className="cl_q p_14_text ms-3" onClick={() => handleOpen()}>
        Copy Setup
      </button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data1"
            sx={{ zIndex: 9 }}
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                {/* <h6>{props?.title}</h6>  */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="popup_delete_detail">
                    <h5 className="copy_title">Copy Setup</h5>

                    <div className="form-group mx-3 mb-3 text-start">
                      <label className="form-label">Setup Title</label>
                      <input
                        rows="5"
                        className="form-control"
                        placeholder="Enter Title"
                        {...register("newEvalutionSetupTitle", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                      ></input>
                      {errors.newEvalutionSetupTitle && (
                        <span role="alert" className="error_text">
                          {errors.newEvalutionSetupTitle.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <button type="submit" className="popup_btn submit">
                        Submit
                      </button>

                      <button
                        type="button"
                        className="popup_btn cancel"
                        onClick={handleClose}
                      >
                        cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default React.memo(CopySetup);
