import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AllTableShow from "./All_TableShow";
import {
  Backdrop,
  CircularProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { DashboardRe } from "../../../../reducers/user";
import Swal from "sweetalert2";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { SwalError, SwalSuccess } from "../../../../components/SwalToast";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const headCellsSchedule = [
  {
    id: "No",
    label: "No",
  },
  {
    id: "Name",
    label: "Name",
  },
  {
    id: "employeeNumber",
    label: "Employee Number",
  },
  {
    id: "Email",
    label: "Email",
  },
  {
    id: "site",
    label: "Site/Location",
  },
  {
    id: "CourseName",
    label: "Course Name",
  },
  {
    id: "DueDate",
    label: "Due Date",
  },
  {
    id: "Status",
    label: "Status",
  },
];
const headCellsCourseName = [
  {
    id: "No",
    label: "No",
  },
  {
    id: "CourseName",
    label: "Course Name",
  },
  {
    id: "NumOfCom",
    label: "Number of completions",
  },
];
const headCellsStudent = [
  {
    id: "No",
    label: "No",
  },
  {
    id: "StudentName",
    label: "Student Name",
  },
  {
    id: "employeeNumber",
    label: "Employee Number",
  },
  {
    id: "jobTitle",
    label: "Job Title",
  },
  {
    id: "siteLocation",
    label: "Site/Location",
  },
  {
    id: "ceuRequired",
    label: "CEU Required",
  },
  {
    id: "ComCEu",
    label: "Completed CEUs",
  },
  {
    id: "totalCompletedCEU",
    label: "Total Completed CEUs",
  },
  // {
  //   id: "academicYear",
  //   label: "Academic Year",
  // },
];
const headCellsCompleteCourse = [
  {
    id: "No",
    label: "No",
  },
  {
    id: "StudentName",
    label: "Student Name",
  },
  {
    id: "employeeNumber",
    label: "Employee Number",
  },
  {
    id: "district",
    label: "District Name",
  },
  {
    id: "site",
    label: "Site/Location",
  },
  {
    id: "CourseName",
    label: "Course Name",
  },
  {
    id: "date",
    label: "Due Date",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "Action",
    label: "Action",
  },
];
function SepratePage_TableData(props) {
  const { id, status } = useParams();
  let loc = useLocation();
  // const [pathNames, setPathNames] = useState("");
  const [open, setOpen] = useState(false);

  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  let changeDash = useSelector((state) => state.user.Drefresh);
  let dispatch = useDispatch();

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [courceData, setCourseData] = useState([]);
  const [schId, setSchId] = useState({
    value: LogDetail?.schoolDistrictId,
    label: LogDetail?.schooldistrictName,
  });
  const [ceuCourseList, setCeuCourseList] = useState([]);
  const [courseRosterID, setCourseRosterID] = useState("");

  const [opencsDelete, setOpencsDelete] = React.useState(false);
  const [userAllDe, setUserAllDe] = useState("");
  const [courceData2, setCourseData2] = useState([
    {
      id: 1,
      name: "NA",
      no: "NA",
    },
  ]);
  const [acdYS, setAcdYS] = useState("");

  const handleCourseUserDelete = (id) => {
    setUserAllDe(id);
    setOpencsDelete(true);
    setDerrors({ comD: "" });
  };
  const [derrors, setDerrors] = useState({});
  let navigate = useNavigate();

  const togglePopup = (id) => {
    navigate(`/certificate/report/${id}`, { replace: true });
  };
  useEffect(() => {
    // setPathNames(loc.pathname);
    getDashData(schId?.value, loc?.pathname, acdYS);
    // eslint-disable-next-line
  }, [loc, changeDash, props.title]);
  useEffect(() => {

    if (id && acdYS) {
      setCourseRosterID(id);
      getCourseRosterData(id, schId?.value, acdYS);
    } else {
      if (courseRosterID !== "") {
        if (acdYS) {
          getCourseRosterData(courseRosterID, schId?.value, acdYS);
        }
      } else {
        setCourseData2([]);
      }
    }
    // eslint-disable-next-line
  }, [courseRosterID, schId, acdYS, id]);

  const getDashData = async (schoolId, path, e) => {
    setCourseData();
    let url = "";
    let is_success = true;
    if (path === "/dashboard/top-student" || props.title === "Top Students") {
      url = `/api/Dashboard/GetTopStudentByFilter?userId=${LogUserId}&schoolDistrictId=${schoolId}&totalRecord=0&academicYearId=${e}`;
      if (!e) {
        is_success = false;
      }
    } else if (
      path?.split("/")[2] === "completed-course-roster" ||
      props.title === "Course Roster"
    ) {
      url = `/api/Course/GetCoursesDropDown?userId=${LogUserId}&courseTypes=1`;
    } else if (
      path === "/dashboard/course-schedule" ||
      props.title === "Course Schedule"
    ) {
      url = `/api/Dashboard/GetCourseScheduleList?userId=${LogUserId}&schoolDistrictId=${schoolId}&totalRecord=0&academicYearId=${e}`;
      if (!e) {
        is_success = false;
      }
    } else {
      url = `/api/Dashboard/GetDashboardList?userId=${LogUserId}&schoolDistrictId=${schoolId}&totalRecord=0&academicYearId=${e}`;
      if (!e) {
        is_success = false;
      }
    }
    if (is_success) {
      setOpen(true);
      // axios.get(url, { headers }).then((response) => {
      let seManage = {
        url: url,
        headers: headers,
      };
      let response = await GetCallApi(seManage);
      setOpen(false);
      // axios.get(url, { headers }).then((response) => {
      if (response?.status === 200) {
        if (response.data.success) {
          let today = new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
          function convertDate(str) {
            var date = new Date(str?.replace(/-/g, "/")),
              mnth = ("0" + (date.getMonth() + 1)).slice(-2),
              day = ("0" + date.getDate()).slice(-2);
            return [mnth, day, date.getFullYear()].join("/");
          }
          const dashData = response?.data;
          setOpen(false);
          if (
            path === "/dashboard/course-schedule" ||
            props.title === "Course Schedule"
          ) {
            const courseSE = dashData?.payload?.map((item, i) => {
              let td = convertDate(today);
              let ex = convertDate(item.dueDate);
              let st = new Date(td) <= new Date(ex) ? "a" : "e";

              return {
                No: i + 1,
                id: item?.userId,
                Name: item?.firstName + " " + item?.lastName,
                name: item?.firstName + " " + item?.lastName,
                employeeNumber: item?.employeeNumber,
                Email:
                  item?.email === "" || item?.email === null ? "" : item?.email,
                courseId: item?.courseId,
                courseTypes: item?.courseTypes,
                courceName: item?.courseName,
                CourseName:
                  item?.courseName === "" || item?.courseName === null
                    ? ""
                    : item?.courseName,
                DueDate:
                  item?.dueDate === "" || item?.dueDate === null
                    ? ""
                    : item?.dueDate,
                dueDate: item?.dueDate,
                site:
                  item?.siteLocation === "" || item?.siteLocation === null
                    ? ""
                    : item?.siteLocation,
                Status:
                  item?.courseExpiringStatus === null ||
                    item?.courseExpiringStatus === ""
                    ? ""
                    : item?.courseExpiringStatus === "Completed"
                      ? "Completed"
                      : st === "e"
                        ? "Expired"
                        : item?.courseExpiringStatus === "Scheduled"
                          ? "Scheduled"
                          : item?.courseExpiringStatus === "In Progress"
                            ? "In Progress"
                            : "",
                st: st,
                expire:
                  item?.courseExpiringStatus === null ||
                    item?.courseExpiringStatus === ""
                    ? ""
                    : item?.courseExpiringStatus === "Completed"
                      ? "Completed"
                      : st === "e"
                        ? "Expired"
                        : item?.courseExpiringStatus === "Scheduled"
                          ? "Scheduled"
                          : item?.courseExpiringStatus === "In Progress"
                            ? "In Progress"
                            : "",
                userCourseAllocationId: item?.userCourseAllocationId,
                userStatus: item?.userStatus,
                profileUrl: item?.profileUrl,
              };
            });
            // if (loc?.pathname === "/settings") {
            //   let withoutComplete = courseSE?.filter(
            //     (x) => x.Status !== "Completed"
            //   );
            //   setCourseData(withoutComplete);
            // } else {
            setCourseData(courseSE);
            // }
          } else if (
            path === "/dashboard/course-name" ||
            props.title === "Top Courses"
          ) {
            const topCourse = dashData.topCourses?.map((item, i) => {
              return {
                No: i + 1,
                id:
                  item?.courseId === "" || item?.courseId === null
                    ? ""
                    : item?.courseId,
                CourseName:
                  item?.courseName === "" || item?.courseName === null
                    ? ""
                    : item?.courseName,
                NumOfCom:
                  item?.numberOfCompletions === "" ||
                    item?.numberOfCompletions === null
                    ? ""
                    : item?.numberOfCompletions,
              };
            });
            setCourseData(topCourse);
          } else if (
            path === "/dashboard/top-student" ||
            props.title === "Top Students"
          ) {
            const topStudent = dashData.topStudents?.map((item, i) => {
              return {
                No: i + 1,
                StudentName:
                  item?.studentName === "" || item?.studentName === null
                    ? ""
                    : item?.studentName,
                employeeNumber: item?.employeeNumber,
                jobTitle:
                  item?.jobTitile === "" || item?.jobTitile === null
                    ? ""
                    : item?.jobTitile,
                siteLocation:
                  item?.siteLocation === "" || item?.siteLocation === null
                    ? ""
                    : item?.siteLocation,
                ceuRequired:
                  item?.requiredCEU === "" || item?.requiredCEU === null
                    ? ""
                    : item?.requiredCEU,
                ComCEu:
                  item?.completedCEUs === "" || item?.completedCEUs === null
                    ? ""
                    : item?.completedCEUs,
                totalCompletedCEU:
                  item?.totalCompletedCEU === "" ||
                    item?.totalCompletedCEU === null
                    ? ""
                    : item?.totalCompletedCEU,
                academicYear:
                  item?.academicYear === "" || item?.academicYear === null
                    ? ""
                    : item?.academicYear,
                districtName: item?.districtName,
                userStatus: item?.userStatus,
                profileUrl: item?.profileUrl,
              };
            });

            setCourseData(topStudent);
          } else if (
            path?.split("/")[2] === "completed-course-roster" ||
            props.title === "Course Roster"
          ) {
            const drpdwnCourse = dashData?.payload?.map((item) => {
              return {
                courseId: item?.courseId,
                courseTitle: item?.courseTitle,
              };
            });
            if (id) {
              if (drpdwnCourse?.find((x) => x?.courseId === id)) {
                setCeuCourseList(drpdwnCourse);
                setCourseRosterID(id ?? drpdwnCourse[0]?.courseId);
              } else {
                let crList = await GetCallApi({
                  url: `/api/Course/GetCoursesDropDown?userId=${LogUserId}&courseTypes=2`,
                });
                if (crList?.status === 200) {
                  if (crList.data.success) {
                    const drpdwnCourseLife = dashData?.payload?.map((item) => {
                      return {
                        courseId: item?.courseId,
                        courseTitle: item?.courseTitle,
                      };
                    });
                    setCeuCourseList(drpdwnCourseLife);
                    setCourseRosterID(id ?? drpdwnCourseLife[0]?.courseId);
                  }
                }
              }
            } else {
              setCeuCourseList(drpdwnCourse);
              setCourseRosterID(id ?? drpdwnCourse[0]?.courseId);
            }
          }
        } else {
          setOpen(false);
          setCourseData();
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: response?.data?.message
              ? response?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
      // }).catch ((error) => {
      //   setOpen(false);
      //   console.log(error);
      //   Swal.fire({
      //     position: "top-end",
      //     width: 400,
      //     // icon: "success",
      //     text: "Something went wrong please try again.",
      //     showConfirmButton: false,
      //     timer: 1500,
      //     color: "#ffffff",
      //     background: "red",
      //     showClass: {
      //       popup: "animate__animated animate__fadeInDown",
      //     },
      //     hideClass: {
      //       popup: "animate__animated animate__fadeOutRight",
      //     },
      //   });
      // });
    } else {
      setOpen(false);
    }
  };
  const getCourseRosterData = (id, schoolId, Aeid) => {
    if (id) {
      axios
        .get(
          `${Config.API_HOST_URL_live}/api/Dashboard/GetCompletedCourseRosterByCourseId?courseId=${id}&userId=${LogUserId}&schoolDistrictId=${schoolId}&totalRecord=0&academicYearId=${Aeid}`,
          { headers }
        )
        .then((response) => {
          if (response.data.success) {
            let couseName = ceuCourseList?.filter(
              (c) => c.courseId === courseRosterID
            )[0];
            const corsRoster = response.data.payload?.map((item, i) => {
              return {
                No: i + 1,
                StudentName: item?.studentName,
                employeeNumber: item?.employeeNumber,
                Action: (
                  <span
                    className="view_btn"
                    onClick={() => togglePopup(item?.userId)}
                  >
                    {item?.action}
                  </span>
                ),
                userId: item?.userId,
                userCourseAllocationId: item?.userCourseAllocationId,
                district:
                  item?.districtName === undefined ||
                    item?.districtName === null ||
                    item?.districtName === ""
                    ? ""
                    : item?.districtName,
                date:
                  item?.dueDate === undefined ||
                    item?.dueDate === null ||
                    item?.dueDate === ""
                    ? ""
                    : item?.dueDate,
                status:
                  item?.status === undefined ||
                    item?.status === null ||
                    item?.status === ""
                    ? ""
                    : item?.status,
                site:
                  item?.siteLocation === null || item?.siteLocation === ""
                    ? ""
                    : item?.siteLocation,
                CourseName: couseName?.courseTitle,
                userStatus: item?.userStatus,
                profileUrl: item?.profileUrl,
              };
            });
            setCourseData2(corsRoster);
          } else {
            setCourseData2([]);
          }
        });
    } else {
      setCourseData2([]);
    }
  };
  const handleClosecsDelete = () => {
    setOpencsDelete(false);
  };
  const Deleteschedule = async () => {
    let url = `${Config.API_HOST_URL_live}/api/UserCourse/DeleteAssignCourse?userCourseAllocationId=${userAllDe}&deletedBy=${LogUserId}`;
    await PostCallApi({ url: url, body: {} }).then((responce) => {
      handleClosecsDelete();
      setUserAllDe("");
      if (changeDash) {
        dispatch(DashboardRe(false));
      } else {
        dispatch(DashboardRe(true));
      }
      setDerrors({ comD: "" });
      if (responce?.status === 200 && responce?.data.success) {
        SwalSuccess(responce.data?.message);
      } else {
        SwalError(responce?.data?.message);
      }
    });
    // axios
    //   .delete(
    //     `${Config.API_HOST_URL_live}/api/UserCourse/DeleteAssignCourse?userCourseAllocationId=${userAllDe}&deletedBy=${LogUserId}`,
    //     { headers }
    //   )
    //   .then((response) => {

    //     if (response.data.success) {
    //       handleClosecsDelete();
    //       setUserAllDe("");
    //       if (changeDash) {
    //         dispatch(DashboardRe(false));
    //       } else {
    //         dispatch(DashboardRe(true));
    //       }
    //       Swal.fire({
    //         position: "top-end",
    //         width: 400,
    //         // icon: "success",
    //         text: titleCase(response.data?.message),
    //         showConfirmButton: false,
    //         timer: 1500,
    //         color: "#ffffff",
    //         background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    //         showClass: {
    //           popup: "animate__animated animate__fadeInDown",
    //         },
    //         hideClass: {
    //           popup: "animate__animated animate__fadeOutRight",
    //         },
    //       });
    //       setDerrors({ comD: "" });
    //     } else {
    //       setDerrors({ comD: response.data.message });
    //     }
    //   });
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  if (LogDetail?.roleId === 1) {
    let lab = headCellsStudent?.filter((x) => x.id === "districtName");
    if (lab?.length === 0) {
      headCellsStudent?.splice(2, 0, {
        id: "districtName",
        label: "District Name",
      });
    }
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {loc?.pathname === "/reports" || loc?.pathname === "/settings" ? (
        <div className="cr seprate">
          {loc?.pathname === "/dashboard/course-schedule" ||
            props.title === "Course Schedule" ? (
            <AllTableShow
              headCells={headCellsSchedule}
              title="Course Schedule"
              dataValues={courceData}
              getDashData={getDashData}
              handleCourseUserDelete={handleCourseUserDelete}
              schId={schId}
              setSchId={setSchId}
              setOpen={setOpen}
              acdYS={acdYS}
              setAcdYS={setAcdYS}
            />
          ) : loc?.pathname === "/dashboard/course-name" ||
            props.title === "Top Courses" ? (
            <AllTableShow
              headCells={headCellsCourseName}
              title="Top Courses"
              dataValues={courceData}
              getDashData={getDashData}
              schId={schId}
              setSchId={setSchId}
              setOpen={setOpen}
              acdYS={acdYS}
              setAcdYS={setAcdYS}
            />
          ) : loc?.pathname === "/dashboard/top-student" ||
            props.title === "Top Students" ? (
            <AllTableShow
              headCells={
                LogDetail?.roleId === 1 ? headCellsStudent : headCellsStudent
              }
              title="Top Students"
              dataValues={courceData}
              getDashData={getDashData}
              schId={schId}
              setSchId={setSchId}
              setOpen={setOpen}
              acdYS={acdYS}
              setAcdYS={setAcdYS}
            />
          ) : loc?.pathname === "/dashboard/completed-course-roster" ||
            props.title === "Course Roster" ? (
            <AllTableShow
              headCells={headCellsCompleteCourse}
              title="Course Roster"
              dataValues={courceData2}
              getDashData={getDashData}
              courseRosterID={courseRosterID}
              setCourseRosterID={setCourseRosterID}
              ceuCourseList={ceuCourseList}
              schId={schId}
              setSchId={setSchId}
              setOpen={setOpen}
              acdYS={acdYS}
              setAcdYS={setAcdYS}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="report_page main_backcolor">
          {/* <section
          className="dashboard_page main_backcolor"
          id="dashboard_section"
        >
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="seprate"> */}
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="report_page_box">
                <Box
                  sx={{
                    flexGrow: 1,
                    // bgcolor: "transparent",
                    // bgcolor: "background.paper",
                  }}
                >
                  <div
                    className="tabpannel_content"
                    style={{
                      backgroundColor:
                        "box-shadow: 0px 7px 8px rgba(50, 50, 50, 0.25)",
                    }}
                  >
                    {" "}
                    <div className="cr seprate">
                      {loc?.pathname === "/dashboard/course-schedule" ||
                        props.title === "Course Schedule" ? (
                        <AllTableShow
                          headCells={headCellsSchedule}
                          title="Course Schedule"
                          dataValues={courceData}
                          getDashData={getDashData}
                          handleCourseUserDelete={handleCourseUserDelete}
                          schId={schId}
                          setSchId={setSchId}
                          setOpen={setOpen}
                          acdYS={acdYS}
                          setAcdYS={setAcdYS}
                        />
                      ) : loc?.pathname === "/dashboard/course-name" ||
                        props.title === "Top Courses" ? (
                        <AllTableShow
                          headCells={headCellsCourseName}
                          title="Top Courses"
                          dataValues={courceData}
                          getDashData={getDashData}
                          setOpen={setOpen}
                          setSchId={setSchId}
                          schId={schId}
                          acdYS={acdYS}
                          setAcdYS={setAcdYS}
                        />
                      ) : loc?.pathname === "/dashboard/top-student" ||
                        props.title === "Top Students" ? (
                        <AllTableShow
                          headCells={headCellsStudent}
                          title="Top Students"
                          dataValues={courceData}
                          getDashData={getDashData}
                          schId={schId}
                          setSchId={setSchId}
                          setOpen={setOpen}
                          acdYS={acdYS}
                          setAcdYS={setAcdYS}
                        />
                      ) : loc?.pathname?.split("/")[2] ===
                        "completed-course-roster" ||
                        props.title === "Course Roster" ? (
                        <AllTableShow
                          headCells={headCellsCompleteCourse}
                          title="Course Roster"
                          dataValues={courceData2}
                          getDashData={getDashData}
                          courseRosterID={courseRosterID}
                          setCourseRosterID={setCourseRosterID}
                          ceuCourseList={ceuCourseList}
                          schId={schId}
                          setSchId={setSchId}
                          setOpen={setOpen}
                          acdYS={acdYS}
                          setAcdYS={setAcdYS}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>
          {/* </section> */}
        </div>
      )}
      <BootstrapDialog
        onClose={handleClosecsDelete}
        aria-labelledby="customized-dialog-title"
        open={opencsDelete}
        className="block_popup"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClosecsDelete}
          dividers
        >
          Delete Course Schedule
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography>
            Please confirm you want to Delete this Course Schedule.
          </Typography>
          {/* <Typography>
            <b>Please note:</b>
            This action will also remove this user from this Course.
          </Typography> */}
          {derrors?.comD && (
            <span role="alert" className="error_text">
              {derrors?.comD}
            </span>
          )}
        </DialogContent>
        <DialogActions className="popup_btn">
          <Button className="w-25 theme_button me-2" onClick={Deleteschedule}>
            Confirm
          </Button>
          <Button autoFocus onClick={handleClosecsDelete} className="w-25">
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default React.memo(SepratePage_TableData);
