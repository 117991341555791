import React, { useEffect, useState } from "react";
import "./Setting.scss";
import Box from "@mui/material/Box";
import SchoolDistrict from "./SchoolDistrict";
import pro_img from "../../../images/fig_CROP.png";
import {
  CreteDataFor,
  CreteQuestionQuiz,
  DeleteDataFor,
} from "../../../reducers/user";
import { useDispatch, useSelector } from "react-redux";
import AceessManagement from "./AccessManagement";
import Config from "../../../Utils/Config";
import Togglebutton from "./Toggle_button";
import DisplayMenu from "./displayMenu.jsx/Index";
import ImgPopup from "./Img_Popup";
import { Backdrop, CircularProgress } from "@mui/material";
import CreateData from "./Create_Data";
import EditManualCEUList from "./EditManualCEUList";
import vegi from "../../../images/Icon/veg.svg";
import nonvegi from "../../../images/Icon/nonveg.svg";
import DraftManualCeu from "./DraftManualCeu";
import PostDeletePop from "./PostDeletePop";
import { FaEye } from "react-icons/fa";
import SepratePageTableData from "../Dashboard/SepratePage_TableData";
import TableHeaders from "./TableHeaders.json";
import { useSignalR } from "../../../Utils/SignalRContext";
import { SwalError, SwalSuccess } from "../../../components/SwalToast";
import CopySetup from "./CopySetup/CopySetup";
import EvaluationReport from "../Reports/EvaluationReport/EvaluationReport";
import { fetchData, postData } from "../../../Action/NewAction";

function Setting() {
  const dispatch = useDispatch();
  const cret = useSelector((state) => state.user?.create_data);
  const deletedSuccess = useSelector((state) => state.user?.deleteData);
  const MenuName = useSelector((state) => state.setting?.MenuName);
  const MenuList = useSelector((state) => state.setting?.MenuList);

  useEffect(() => {
    if (cret === false) {
      setEditable(false);
      setDataEditId();
    }
    // eslint-disable-next-line
  }, [cret]);

  const [dataEditId, setDataEditId] = useState();
  const [dataOfTable, setDataOfTable] = useState([]);

  const [editable, setEditable] = useState(false);
  const LogUserId = useSelector((state) => state.login.LoginDetails);
  const [open, setOpen] = useState(false);
  const [schId, setSchId] = useState({
    value: "00000000-0000-0000-0000-000000000000",
    label: "All School District",
  });
  const [webinarSelect, setWebinarSelect] = useState({
    value: "00000000-0000-0000-0000-000000000000",
    label: "All Webinar",
  });
  const [questionStatus, setQuestionStatus] = useState();
  const handleEdit = (e) => {
    setDataEditId(e);
    setEditable(true);
    dispatch(CreteDataFor(true));
    dispatch(CreteQuestionQuiz({ createQuestion: false }));
  };
  useEffect(() => {
    dispatch(CreteDataFor(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (MenuName === "Job Title") {
      if (schId) {
        getJobTitle();
      }
    }
    if (MenuName === "User") {
      if (schId) {
        getUserList();
      }
    } else {
      GetData();
    }
    // eslint-disable-next-line
  }, [MenuName, deletedSuccess, cret, schId]);

  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  function EditButton({ editValues, handleEdit }) {
    return (
      <button
        type="button"
        className="btn_edit"
        onClick={() => handleEdit(editValues)}
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.8899 3.00006C18.1525 2.73741 18.4643 2.52907 18.8075 2.38693C19.1507 2.24479 19.5185 2.17163 19.8899 2.17163C20.2613 2.17163 20.6291 2.24479 20.9723 2.38693C21.3154 2.52907 21.6272 2.73741 21.8899 3.00006C22.1525 3.2627 22.3609 3.57451 22.503 3.91767C22.6452 4.26083 22.7183 4.62862 22.7183 5.00006C22.7183 5.37149 22.6452 5.73929 22.503 6.08245C22.3609 6.42561 22.1525 6.73741 21.8899 7.00006L8.38989 20.5001L2.88989 22.0001L4.38989 16.5001L17.8899 3.00006Z"
            stroke="#1EBEC8"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>);
  }

  const handleCloneQuiz = async (id) => {
    let seData = {
      url: `/api/Quiz/CloneQuiz?quizid=${id}&createdBy=${LogUserId.userId}`,
      method: "post",
      body: {}
    };
    const { res, message } = await ApiFunc(seData?.url, seData.method, seData.body);
    if (res) {
      if (deletedSuccess) {
        dispatch(DeleteDataFor(false));
      } else {
        dispatch(DeleteDataFor(true));
      }
      SwalSuccess(message)
    }
  };
  const handleCloneCourse = async (id) => {
    let seData = {
      url:
        `/api/Course/CloneCourse?courseId=${id}&createdBy=${LogUserId?.userId}`,
      method: "post",
      body: {}
    };
    const { res, message } = await ApiFunc(seData?.url, seData.method, seData.body);
    if (res) {
      if (deletedSuccess) {
        dispatch(DeleteDataFor(false));
      } else {
        dispatch(DeleteDataFor(true));
      }
      SwalSuccess(message)
    }
  };
  const ApiFunc = async (url, method, send) => {
    if (method === "post") {
      setOpen(true);
      const { data, error } = await postData(url, send);
      let res = data;
      if (res.success && res.payload?.length > 0) {
        setOpen(false);
        return { res: res.payload, error: null, message: res.message };
      } else {
        setOpen(false);
        SwalError(res?.message ? res?.message : error);
        return { res: null, error: res?.message ? res?.message : error };
      }
    } else if (method === "get") {
      setOpen(true);
      const { data, error } = await fetchData(url);
      let res = data;
      if (res.success && res.payload?.length > 0) {
        setOpen(false);
        return { res: res.payload, error: null };
      } else {
        setOpen(false);
        SwalError(res?.message ? res?.message : error);
        return { res: null, error: res?.message ? res?.message : error };
      }
    }
  };

  const GetData = async () => {
    let schId =
      LogUserId?.roleId === 1
        ? "00000000-0000-0000-0000-000000000000"
        : LogUserId?.schoolDistrictId;
    if (cret === false) {
      setDataOfTable();
      if (MenuName === "District Management") {
        let seData = {
          url: `/api/SchoolDistrict/GetAllSchoolDistrictList?userId=${LogUserId.userId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item) => {
            return {
              eveImage: item?.thumbnailLogoUrl ? (
                <ImgPopup
                  img={
                    item?.thumbnailLogoUrl
                      ? `${Config.API_HOST_URL_live}${item?.thumbnailLogoUrl}`
                      : pro_img
                  }
                  popupImage={
                    item?.districtLogo
                      ? Config.API_HOST_URL_live + item?.districtLogo
                      : pro_img
                  }
                />
              ) : (
                ""
              ),
              ...item,
              Action: (
                <>
                  {item?.isActive === true && (
                    <EditButton editValues={item?.schoolDistrictId} handleEdit={handleEdit} />

                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete school district"
                    text={
                      item?.districtName +
                      `${item?.isActive === true
                        ? " if you deactive this district then in this district all user will be deactive"
                        : " if you active this district then in this district all user will be active"
                      }`
                    }
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method={"post"}
                    url={`/api/SchoolDistrict/DeleteSchoolDistrict?schoolDistrictId=${item?.schoolDistrictId}`}
                  // url={`${Config.API_HOST_URL_live}/api/SchoolDistrict/DeleteSchoolDistrict?schoolDistrictId=${item?.schoolDistrictId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Role") {
        let seData = {
          url: `/api/Role/RoleList`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item) => {
            return {
              ...item,
              IsActive: item?.isActive === true ? "True" : "False",
              CreatedOn: item?.createdOn !== null ? item?.createdOn : "--",
              CreatedBy: item?.createdBy !== null ? item?.createdBy : "--",
              LastUpdatedOn:
                item?.lastUpdatedOn !== null ? item?.lastUpdatedOn : "--",
              LastUpdatedBy:
                item?.lastUpdatedBy !== null ? item?.lastUpdatedBy : "--",
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.roleId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item.isActive === true ? "1" : "0"}
                    editId={item.roleId}
                    disabled={false}
                    title="Delete Role"
                    text={item?.roleName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/Role/DeleteRole?RoleId=${item?.roleId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Menu") {
        let seData = {
          url: `/api/Menu/MenuList`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item) => {
            return {
              ...item,
              IsActive: item?.isActive === true ? "True" : "False",
              CreatedOn:
                item?.createdOn !== null
                  ? convertDate(item?.createdOn)
                  : "--",
              CreatedBy: item?.createdBy !== null ? item?.createdBy : "--",
              LastUpdatedOn:
                item?.lastUpdatedOn !== null
                  ? convertDate(item?.lastUpdatedOn)
                  : "--",
              LastUpdatedBy:
                item?.lastUpdatedBy !== null ? item?.lastUpdatedBy : "--",
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.menuId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.menuId}
                    disabled={false}
                    title="Delete Menu"
                    text={item?.menuName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    menuApi={true}
                    url={`${Config.API_HOST_URL_live}/api/Menu/DeleteMenu?MenuId=${item?.menuId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "User Groups") {
        let seData = {
          url: `/api/Group/GetGroupList?userId=${LogUserId.userId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item) => {
            return {
              ...item,
              IsActive: item?.isActive === true ? "True" : "False",
              CreatedOn:
                item?.createdOn !== null
                  ? convertDate(item?.createdOn)
                  : "--",
              CreatedBy: item?.createdBy !== null ? item?.createdBy : "--",
              LastUpdatedOn:
                item?.lastUpdatedOn !== null
                  ? convertDate(item?.lastUpdatedOn)
                  : "--",
              LastUpdatedBy:
                item?.lastUpdatedBy !== null ? item?.lastUpdatedBy : "--",
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.groupId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item.isActive === true ? "1" : "0"}
                    editId={item.groupId}
                    disabled={false}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    title="Delete Group"
                    text={item?.groupName}
                    method={"post"}
                    url={`${Config.API_HOST_URL_live}/api/Group/DeleteGroup?GroupId=${item?.groupId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (
        MenuName === "Courses" ||
        MenuName === "District Courses" ||
        MenuName === "Partner Courses"
      ) {
        let seData = {
          url: "",
          method: "get"
        };
        if (MenuName === "Courses") {
          seData.url = `/api/Course/CourseList?createdBy=${LogUserId?.userId}`;
        } else {
          seData.url = `/api/Course/GetCourseDataByUserId?userId=${LogUserId?.userId
            }&courseType=${MenuName === "District Courses"
              ? "DistrictContent"
              : "PartnerContent"
            }`;
        }
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              CourseImg: (
                <div style={{ margin: "auto" }}>
                  <img
                    src={
                      item?.thumbnailCourseUrl
                        ? `${Config.API_HOST_URL_live}${item?.thumbnailCourseUrl}`
                        : pro_img
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = pro_img;
                    }}
                    alt="course"
                    height={30}
                    width={30}
                  />
                </div>
              ),

              CourseCategories:
                item?.courseTypes === "1"
                  ? "CEU Courses"
                  : item?.courseTypes === "2"
                    ? "Fig LifeStyle"
                    : "",
              MainCat: item?.mainCategory
                ? item?.mainCategory?.toString()
                : "",
              LearCat: item?.learningObjective
                ? item?.learningObjective?.toString()
                : "",
              TraiCat: item?.trainingTopic
                ? item?.trainingTopic?.toString()
                : "",
              Author:
                item?.instructor === null ||
                  item?.instructor?.firstName === "" ||
                  item?.instructor?.firstName === null
                  ? ""
                  : item?.instructor?.firstName +
                  " " +
                  item?.instructor?.lastName,
              status: item?.status ? item?.status : "Draft",
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.courseId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete Course"
                    text={item?.courseTitle}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/Course/DeleteCourse?CourseId=${item?.courseId}&createdBy=${LogUserId.userId}`}
                  />
                  {item.isActive === true && MenuName === "Courses" ? (
                    <button
                      className="cl_q p_14_text ms-3"
                      onClick={() => handleCloneCourse(item?.courseId)}
                    >
                      Clone Course
                    </button>
                  ) : (
                    ""
                  )}
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Course Category") {
        let seData = {
          url: `/api/CourseCategory/GetAllCourseCategoryData?createdBy=${LogUserId.userId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              CreatedOn: item?.createdOn !== null ? item?.createdOn : "--",
              CreatedBy: item?.createdBy !== null ? item?.createdBy : "--",
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.courseCategoryId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete Course Category"
                    text={item?.courseCategoryName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/CourseCategory/DeleteCourseCategory?CourseCategoryId=${item?.courseCategoryId}&createdBy=${LogUserId.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Quizzes") {
        let seData = {
          url: `/api/Quiz/GetQuizList?createdBy=${LogUserId.userId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              Author:
                item?.author !== null
                  ? item?.author?.firstName !== "" ||
                    item?.author?.firstName !== null
                    ? item?.author?.firstName + " " + item?.author?.lastName
                    : ""
                  : "",
              Publish:
                item.createdOn !== null || item.createdOn !== ""
                  ? convertDate(item?.createdOn.split("T")[0])
                  : "",
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.quizId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete Quizzes"
                    text={item?.quizTitle}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/Quiz/ActiveDeactiveQuizById?QuizId=${item?.quizId}&createdBy=${LogUserId.userId}`}
                  />
                  <button
                    className="cl_q p_14_text ms-3"
                    onClick={() => handleCloneQuiz(item?.quizId)}
                  >
                    Clone Quiz
                  </button>
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Certificate") {
        let seData = {
          url:
            `/api/CourseCertificate/GetCourseCertificateData?createdBy=${LogUserId.userId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.certificateId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete Quizzes"
                    text={item?.certificateName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/CourseCertificate/DeleteCourseCertificate?certificateId=${item?.certificateId}&userId=${LogUserId?.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Block & Report") {
        let seData = {
          url: `/api/BlockReportPost/GetBlockReportPostList`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              RepoUR:
                item?.blockingReason === "" ||
                  item?.blockingReason === null ? (
                  ""
                ) : (
                  <div
                    style={{
                      width: "600px",
                      lineBreak: "anywhere",
                      whiteSpace: "normal",
                    }}
                  >
                    {item?.blockingReason}
                  </div>
                ),
              Action: item?.isDeleted ? (
                "Deleted Already"
              ) : (
                <PostDeletePop
                  text={item?.communityPostCreatedBy}
                  postBlockReportId={item?.postBlockReportId}
                />
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Event Promotion") {
        let seData = {
          url: `/api/EventPromotion/GetAllEventPromotionList`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              eveImage: item?.eventImageUrl ? (
                <ImgPopup
                  img={
                    item?.eventImageUrl
                      ? `${Config.API_HOST_URL_live}${item?.eventImageUrl}`
                      : pro_img
                  }
                />
              ) : (
                ""
              ),
              Action: (
                <>
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete Course"
                    text={item?.courseTitle}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/EventPromotion/DeleteEventPromotionByID?EventPromotionId=${item?.eventPromotionId}&deletedBy=${LogUserId.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Site/Location") {
        let seData = {
          url: `/api/SiteLocation/GetSiteLocationList?userId=${LogUserId?.userId}&schoolDistrictId=${schId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.schoolId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete Quizzes"
                    text={item?.quizTitle}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/SiteLocation/DeleteSiteLocation?siteLocationId=${item?.schoolId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Recipe Management") {
        let seData = {
          url: `/api/Recipe/GetAllRecipeData`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              RecipeImg: (
                <div style={{ margin: "auto" }}>
                  <img
                    src={
                      item?.coverImage
                        ? `${Config.API_HOST_URL_live}${item?.coverImage}`
                        : pro_img
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = pro_img;
                    }}
                    alt="course"
                    height={30}
                    width={30}
                  />
                </div>
              ),
              Type: <img src={item?.isVeg ? vegi : nonvegi} alt="" />,
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.recipeId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete Recipe"
                    text={item?.name}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/Recipe/DeleteRecipe?recipeId=${item?.recipeId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Recipe Category") {
        let seData = {
          url: `${Config.API_HOST_URL_live}/api/Category/GetAllCategoriesList?createdBy=${LogUserId?.userId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.categoryId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete Recipe Category"
                    text={item?.categoryName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/Category/DeleteCategories?CategoryId=${item?.categoryId}&createdBy=${LogUserId?.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Recipe Sub Category") {
        let seData = {
          url: `${Config.API_HOST_URL_live}/api/SubCategories/GetAllSubCategoriesDataList`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              Action: (
                <>
                  {item.isActive === true && (
                    <EditButton editValues={item?.subCategoriesId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    disabled={false}
                    title="Delete Recipe Sub Category"
                    text={item?.subName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/SubCategories/DeleteSubCategories?SubCategoriesId=${item?.subCategoriesId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Academic Year") {
        let seData = {
          url: `${Config.API_HOST_URL_live}/api/SchoolAcademicYear/GetSchoolAcademicYearList`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              isCurrentYear: item?.isCurrentYear ? "True" : "False",
              CreatedOn: item?.createdOn
                ? convertDate(item?.createdOn?.split("T")[0])
                : "",
              Action: (
                <>
                  <EditButton editValues={item} handleEdit={handleEdit} />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Automated Email") {

        let seData = {
          url: `/api/AutomatedEmailSetting/GetAutomatedEmailSetting`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,

              automatedTime: item?.automatedTime
                ? new Date(item?.automatedTime).toLocaleTimeString()
                : "",
              createdOn: item?.createdOn
                ? new Date(item?.createdOn).toLocaleDateString()
                : "",
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item} handleEdit={handleEdit} />

                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.automatedsettingId}
                    disabled={false}
                    title="Delete Automated Email"
                    text={item?.automatedTitle}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/AutomatedEmailSetting/DeleteAutomatedEmailSetting?automatedsettingId=${item?.automatedsettingId}&createdBy=${LogUserId?.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "What We Do (Boxes)") {

        let seData = {
          url: `/api/WhatWeDoBoxes/GetAllWhatWeDoBoxesList`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              whatWeDoDescription:
                item?.whatWeDoDescription?.length > 75
                  ? item?.whatWeDoDescription.slice(0, 75) + "..."
                  : item?.whatWeDoDescription,
              iconimg: (
                <div style={{ margin: "auto" }}>
                  <img
                    src={
                      item?.imageUrl
                        ? `${Config.API_HOST_URL_live}${item?.imageUrl}`
                        : pro_img
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = pro_img;
                    }}
                    alt=""
                    height={30}
                    width={30}
                  />
                </div>
              ),
              createdOn: item?.createdOn
                ? new Date(item?.createdOn).toLocaleDateString()
                : "",
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.whatWeDoBoxesId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.whatWeDoBoxesId}
                    disabled={false}
                    title="Delete What We Do (Boxes)"
                    text={item?.title}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/WhatWeDoBoxes/DeleteWhatWeDoBoxes?whatWeDoBoxesId=${item?.whatWeDoBoxesId}&createdBy=${LogUserId?.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Core Values (Boxes)") {

        let seData = {
          url: `/api/CoreValuesBoxes/GetAllCoreValuesBoxesList`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              iconimg: (
                <div style={{ margin: "auto" }}>
                  <img
                    src={
                      item?.coreValuesUploadURL
                        ? `${Config.API_HOST_URL_live}${item?.coreValuesUploadURL}`
                        : pro_img
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = pro_img;
                    }}
                    alt="course"
                    height={30}
                    width={30}
                  />
                </div>
              ),
              coreValuesDescription:
                item?.coreValuesDescription?.length > 75
                  ? item?.coreValuesDescription.slice(0, 75) + "..."
                  : item?.coreValuesDescription,
              createdOn: item?.createdOn
                ? new Date(item?.createdOn).toLocaleDateString()
                : "",
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.coreValuesBoxesId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.coreValuesBoxesId}
                    disabled={false}
                    title="Delete Core Values (Boxes)"
                    text={item?.coreValuesTitle}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/CoreValuesBoxes/DeleteCoreValuesBoxes?coreValuesBoxesId=${item?.coreValuesBoxesId}&createdBy=${LogUserId?.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Demonstration") {

        let seData = {
          url: `/api/RequestDemonstration/GetRequestDemonstration`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              createdOn: item?.createdOn
                ? new Date(item?.createdOn).toLocaleDateString()
                : "",
              prefferedDate: item?.prefferedDate
                ? new Date(item?.prefferedDate).toLocaleDateString()
                : "",
              schoolDistrictName: item?.schoolDistrictName
                ? item?.schoolDistrictName
                : "",
              Action: (
                <>
                  <button
                    type="button"
                    className="btn_edit"
                    onClick={(e) => handleEdit(item?.requestDemonstrationId)}
                  >
                    <FaEye color="#31b680" fontSize={25} />
                  </button>
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Webinar Join Request") {

        let seData = {
          url: `/api/JoinUpcomingWebinar/GetJoinUpcomingWebinar`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              No: i + 1,
              createdOn: item?.createdOn
                ? new Date(item?.createdOn).toLocaleDateString()
                : "",
              Action: (
                <>
                  <button
                    type="button"
                    className="btn_edit"
                    onClick={(e) => handleEdit(item?.joinUpcomingWebinarId)}
                  >
                    <FaEye color="#31b680" fontSize={25} />
                  </button>
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Webinar") {
        let seData = {
          url: `/api/Webinar/GetWebinarData`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              iconimg: (
                <div style={{ margin: "auto" }}>
                  <img
                    src={
                      item?.webinarImage
                        ? `${Config.API_HOST_URL_live}${item?.webinarImage}`
                        : pro_img
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = pro_img;
                    }}
                    alt=""
                    height={30}
                    width={30}
                  />
                </div>
              ),
              showOnMainSite: item.showOnMainSite ? "True" : "False",
              webinarDate: item.webinarDate
                ? new Date(item?.webinarDate).toLocaleDateString()
                : "",
              webinarTime: item.webinarTime
                ? new Date(item?.webinarTime).toLocaleTimeString()
                : "",

              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.webinarId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.webinarId}
                    disabled={false}
                    title="Delete Webinar"
                    text={item?.title}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/Webinar/DeleteWebinar?webinarId=${item?.webinarId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Proposal") {
        let seData = {
          url: `/api/RequestProposolYourDistrict/GetRequestProposolYourDistrict`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              createdOn: item?.createdOn
                ? new Date(item?.createdOn).toLocaleDateString()
                : "",
              Action: (
                <>
                  <button
                    type="button"
                    className="btn_edit"
                    onClick={(e) =>
                      handleEdit(item?.requestProposolYourDistrictId)
                    }
                  >
                    <FaEye color="#31b680" fontSize={25} />
                  </button>
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Email Template") {
        let seData = {
          url: `/api/EmailTemplate/GetEmailTemplateData`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              isDefault: item?.isDefault === true ? "True" : "False",
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.tempateId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.tempateId}
                    disabled={false}
                    title="Delete Email Template"
                    text={item?.templateName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/EmailTemplate/DeleteEmailTemplate?templateId=${item?.tempateId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Free Sources") {
        let seData = {
          url: `/api/Resources/GetAllResources?returnAll=false&userId=${LogUserId?.userId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.resourcesId}
                    disabled={false}
                    title="Delete Free Source"
                    text={item?.title}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/Resources/DeleteResources?resourcesId=${item?.resourcesId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "District Map") {
        let seData = {
          url: `/api/DistrictMap/GetDistrictMapList`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              Action: (
                <>
                  {item?.isDeActiveDistrict && (
                    <EditButton editValues={item?.districtMapId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isDeActiveDistrict === true ? "1" : "0"}
                    editId={item?.districtMapId}
                    disabled={false}
                    title="Delete District Map"
                    text={item?.schooldistrict}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/DistrictMap/DeleteDistrictMapById?districtMapId=${item?.districtMapId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Goal Category") {
        let seData = {
          url: `/api/GoalCategory/GetAllGoalCategory`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              createdOn: item?.createdOn
                ? new Date(item?.createdOn).toLocaleDateString()
                : "",

              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.goalCategoryId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.goalCategoryId}
                    disabled={false}
                    title="Delete Goal Category"
                    text={item?.categoryName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/GoalCategory/DeleteGoalCategory?goalCategoryId=${item?.goalCategoryId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Evaluation Question") {
        let seData = {
          url: `/api/EvalQuestion/GetAllEvalQuestionData`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              isGenericQuestion:
                item?.isGenericQuestion === true ? "True" : "False",
              Answer:
                item?.evalQuestionTypeName === "MCQ"
                  ? item?.correctAnswerForMcq
                  : item?.evalQuestionTypeName === "Text"
                    ? item?.textAnswer
                    : item?.evalQuestionTypeName === "Rating"
                      ? item?.rating
                      : item?.evalQuestionTypeName === "Pass/Fail"
                        ? item?.trueFalse === true
                          ? "Pass"
                          : "Fail"
                        : item?.evalQuestionTypeName === "Score"
                          ? item?.score
                          : "",
              inActive: item?.isActive === true ? "True" : "False",
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.evalQuestionId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.evalQuestionId}
                    disabled={false}
                    title="Delete Eval Question"
                    text={item?.evalQuestionTitle}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config.API_HOST_URL_live}/api/EvalQuestion/DeleteEvalQuestionEvalQuestion?EvalQuestionId=${item?.evalQuestionId}&createdBy=${LogUserId?.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Evaluation Group") {
        let seData = {
          url: `/api/EvalutionGroup/GetEvalutionGroupData`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.evalutionGroupId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.evalutionGroupId}
                    disabled={false}
                    title="Delete Eval Group"
                    text={item?.evalutionGroupName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config?.API_HOST_URL_live}/api/EvalutionGroup/DeleteEvalutionGroup?evalutionGroupId=${item?.evalutionGroupId}&createdBy=${LogUserId?.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Evaluation Group Category") {
        let seData = {
          url: `/api/EvalutionGroupCategory/GetAllEvalutionGroupCategoryData`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              isActive: item?.isActive === true ? "True" : "False",
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.evalutionGroupCategoryId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.evalutionGroupCategoryId}
                    disabled={false}
                    title="Delete Eval Group Category"
                    text={item?.evalutionCategoryName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config?.API_HOST_URL_live}/api/EvalutionGroupCategory/DeleteEvalutionGroupCategory?evalutionGroupCategoryId=${item?.evalutionGroupCategoryId}&createdBy=${LogUserId?.userId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Evaluation Setup") {
        let seData = {
          url: `/api/EvalutionSetup/GetAllEvalutionSetupData`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              id: i + 1,
              Action: (
                <div className="edit_delete">
                  {item?.isActive && (
                    <EditButton editValues={item?.evalutionSetupId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.evalutionSetupId}
                    disabled={false}
                    title="Delete Eval Setup"
                    text={item?.evalutionSetupTitle}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config?.API_HOST_URL_live}/api/EvalutionSetup/DeleteEvalutionSetup?evalutionSetupId=${item?.evalutionSetupId}&createdBy=${LogUserId?.userId}`}
                  />
                  {item.isActive === true ? (
                    <CopySetup evalutionSetupId={item?.evalutionSetupId} />
                  ) : (
                    // <button
                    //   className="cl_q p_14_text ms-3"
                    //   onClick={() => handleCopySetup(item)}
                    // >
                    //   Copy Setup
                    // </button>
                    ""
                  )}
                </div>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Evaluation Employee") {
        let seData = {
          url: `/api/EvalutionEmployee/GetEvalutionEmployeeListManagerWise?LoginUserId=${LogUserId?.userId}`,
          // url: `/api/EvalutionEmployee/GetAllEvalutionEmployee?userId=${LogUserId?.userId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              id: i + 1,
              Action: (
                <>
                  <button
                    type="button"
                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium cource_reschedule_button btn_space css-1e6y48t-MuiButtonBase-root-MuiButton-root"
                    onClick={(e) => handleEdit(item)}
                  >
                    Do Evalution
                  </button>
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Template") {
        let seData = {
          url: `/api/Template/GetTemplateList?schoolDistrictId=${LogUserId?.schoolDistrictId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              id: i + 1,
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.templateId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.templateId}
                    disabled={false}
                    title="Delete Template"
                    text={item?.templateTitle}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config?.API_HOST_URL_live}/api/Template/DeleteTemplateById?emailTemplateId=${item?.templateId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Campaign") {
        let seData = {
          url: `/api/Campaign/GetCampaignList?schoolDistrictId=${LogUserId?.schoolDistrictId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              id: i + 1,
              startDate: item?.startDate
                ? convert(item?.startDate?.split("T")[0])
                : "",
              createdOn: item?.createdOn
                ? convert(item?.createdOn?.split("T")[0])
                : "",
              isActive: item?.isActive ? "True" : "False",
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.campaignId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.campaignId}
                    disabled={false}
                    title="Delete Campaign"
                    text={item?.campaignName}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config?.API_HOST_URL_live}/api/Campaign/DeleteCampaignById?CampaignId=${item?.campaignId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      } else if (MenuName === "Smtp Config") {
        let seData = {
          url: `/api/SmtpConfiguration/GetSmtpList?schoolDistrictId=${LogUserId?.schoolDistrictId}`,
          method: "get"
        };
        const { res } = await ApiFunc(seData?.url, seData.method);
        if (res) {
          const data = res?.map((item, i) => {
            return {
              ...item,
              id: i + 1,
              Action: (
                <>
                  {item?.isActive && (
                    <EditButton editValues={item?.smtpId} handleEdit={handleEdit} />
                  )}
                  <Togglebutton
                    checked={item?.isActive === true ? "1" : "0"}
                    editId={item?.smtpId}
                    disabled={false}
                    title="Delete Smtp"
                    text={item?.smtpServer}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                    method="{post}"
                    url={`${Config?.API_HOST_URL_live}/api/SmtpConfiguration/DeleteSmtpData?SmtpId=${item?.smtpId}`}
                  />
                </>
              ),
            };
          });
          setDataOfTable(data);
        }
      }
    }
  };

  const getJobTitle = async () => {
    setDataOfTable();
    let seData = {
      url: `/api/JobTitle/JobTitileList?userId=${LogUserId.userId}&schoolDistrictId=${schId?.value}`,
      method: "get"
    };
    const { res } = await ApiFunc(seData?.url, seData.method);
    if (res) {
      const data = res?.map((item) => {
        return {
          ...item,
          IsActive: item?.isActive === true ? "True" : "False",
          CreatedOn:
            item?.createdOn !== null ? convertDate(item?.createdOn) : "--",
          CreatedBy: item?.createdBy !== null ? item?.createdBy : "--",
          LastUpdatedOn:
            item?.lastUpdatedOn !== null
              ? convertDate(item?.lastUpdatedOn)
              : "--",
          LastUpdatedBy:
            item?.lastUpdatedBy !== null ? item?.lastUpdatedBy : "--",
          Action: (
            <>
              {item.isActive === true && (
                <EditButton editValues={item?.jobTitleId} handleEdit={handleEdit} />
              )}
              <Togglebutton
                checked={item?.isActive === true ? "1" : "0"}
                editId={item?.jobTitleId}
                disabled={false}
                title="Delete Job"
                text={item?.jobTitleName}
                offstyle="btn-danger"
                onstyle="btn-success"
                method="{post}"
                url={`${Config.API_HOST_URL_live}/api/JobTitle/DeleteJobTitle?JobTitleID=${item?.jobTitleId}`}
              />
            </>
          ),
        };
      });
      setDataOfTable(data);
    }
  };
  const { connection } = useSignalR();
  useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setDataOfTable((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });
      });
    }
  }, [connection?.state]);

  const getUserList = async () => {
    setDataOfTable();
    let seData = {
      url:
        // LogUserId?.roleId === 2
        //   ? `/api/User/GetAdditionalDistrictWiseUserData?userId=${LogUserId.userId}`
        //   :
        `/api/User/UserList?userId=${LogUserId.userId}&schoolDistrictId=${schId?.value}`,
      method: "get"
    };
    const { res } = await ApiFunc(seData?.url, seData.method);
    if (res) {
      const data = res?.map((item, i) => {
        return {
          No: i + 1,
          ...item,
          userStatus: item?.userStatus,
          Action: (
            <>
              {item?.isActive === true && (
                <EditButton editValues={item} handleEdit={handleEdit} />
              )}
              <Togglebutton
                checked={item?.isActive === true ? "1" : "0"}
                editIdName="userManagementId"
                editId={item?.userManagementId}
                disabled={false}
                offstyle="btn-danger"
                onstyle="btn-success"
                title="Delete Job"
                addNote={true}
                userId={item.userId}
                text={item?.firstName + " " + item?.lastName}
                url={`${Config.API_HOST_URL_live}/api/User/DeleteUser?UserId=${item?.userId}`}
              />
            </>
          ),
        };
      });
      setDataOfTable(data);
    }
  };

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const filterWebinarUserList = dataOfTable?.filter((user) => {
    if (webinarSelect?.value !== "00000000-0000-0000-0000-000000000000") {
      return user.webinarId
        ?.toLowerCase()
        ?.includes(webinarSelect?.value?.toLowerCase());
    }
    return user;
  });
  const filterQuestionList = dataOfTable?.filter((user) => {
    if (questionStatus) {
      return user.inActive
        ?.toLowerCase()
        ?.includes(questionStatus?.toLowerCase());
    }
    return user;
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="setting_page main_backcolor">
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="setting_wrapper">
              <DisplayMenu />
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                }}
              >
                <div className="tabpannel_content">
                  {MenuName === "Role" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsRole}
                      dataValues={dataOfTable}
                      title="Role List"
                      btn_name={editable === true ? "Edit Role" : "Create Role"}
                      editId={dataEditId}
                    />
                  ) : MenuName === "Menu" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsMenu}
                      dataValues={dataOfTable}
                      title="Menu List"
                      btn_name={editable === true ? "Edit Menu" : "Create Menu"}
                      editId={dataEditId}
                    />
                  ) : MenuName === "Job Title" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsJob}
                      dataValues={dataOfTable}
                      title="Job Title List"
                      schId={schId}
                      setSchId={setSchId}
                      btn_name={
                        editable === true
                          ? "Edit Job Title"
                          : "Create Job Title"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Access Levels" ? (
                    <AceessManagement title="Access Level " />
                  ) : MenuName === "District Management" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsSchool}
                      dataValues={dataOfTable}
                      title="District List"
                      btn_name={
                        editable === true ? "edit district" : "create district"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "User" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsUser}
                      dataValues={dataOfTable}
                      title="User List"
                      schId={schId}
                      setSchId={setSchId}
                      btn_name={editable === true ? "Edit User" : "Create User"}
                      editId={dataEditId}
                    />
                  ) : MenuName === "User Groups" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsGroup}
                      dataValues={dataOfTable}
                      title="Group List"
                      btn_name={
                        editable === true ? "Edit Group" : "Create Group"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Courses" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsCourse}
                      dataValues={dataOfTable}
                      title="Course List"
                      btn_name={
                        editable === true ? "Edit Course" : "Create Course"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "District Courses" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsCourse}
                      dataValues={dataOfTable}
                      title="District Course List"
                      btn_name={
                        editable === true ? "Edit Course" : "Create Course"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Partner Courses" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsCourse}
                      dataValues={dataOfTable}
                      title="Partner Course List"
                      btn_name={
                        editable === true ? "Edit Course" : "Create Course"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Course Category" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsCourseCat}
                      dataValues={dataOfTable}
                      title="Course Category List"
                      btn_name={
                        editable === true
                          ? "Edit Course Category"
                          : "Create Course Category"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Quizzes" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsQuiz}
                      dataValues={dataOfTable}
                      title="Quiz List"
                      btn_name={editable === true ? "Edit Quiz" : "Create Quiz"}
                      editId={dataEditId}
                    />
                  ) : MenuName === "Certificate" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsCertificate}
                      dataValues={dataOfTable}
                      title="Certificate List"
                      btn_name={
                        editable === true
                          ? "Edit Certificate"
                          : "Create Certificate"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Block & Report" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsBlock}
                      dataValues={dataOfTable}
                      title="Block & Report List"
                    />
                  ) : MenuName === "Event Promotion" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsEvent}
                      dataValues={dataOfTable}
                      title="Event Promotion List"
                      btn_name={
                        editable === true
                          ? "Edit Event Promotion"
                          : "Create Event Promotion"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Site/Location" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsSite}
                      dataValues={dataOfTable}
                      title="Site/Location List"
                      btn_name={
                        editable === true
                          ? "Edit Site/Location"
                          : "Create Site/Location"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "MultiUser FIG CEU" ? (
                    <CreateData title="Manual Course Completed" />
                  ) : MenuName === "Manual Course Scheduled" ? (
                    <CreateData title="Manual Course Scheduled" />
                  ) : MenuName === "Reporting Manager" ? (
                    <CreateData title="Reporting Manager" />
                  ) : MenuName === "Hierarchical Chart" ? (
                    <CreateData title="Hierarchical Chart" />
                  ) : MenuName === "Assign Site/Location" ? (
                    <CreateData title="Assign Site/Location" />
                  ) : MenuName === "Manual CEU" ? (
                    <EditManualCEUList schId={schId} setSchId={setSchId} />
                  ) : MenuName === "Draft Manual CEU" ? (
                    <DraftManualCeu title="Create Draft Manual Ceu" />
                  ) : MenuName === "Delete System Generated CEU" ? (
                    <CreateData title="Delete CEU" />
                  ) : MenuName === "Recipe Management" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsRacipe}
                      dataValues={dataOfTable}
                      title="Recipe List"
                      btn_name={
                        editable === true ? "Edit Recipe" : "Create Recipe"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Recipe Category" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsRacipeCategory}
                      dataValues={dataOfTable}
                      title="Recipe Category List"
                      btn_name={
                        editable === true
                          ? "Edit Recipe Category"
                          : "Create Recipe Category"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Recipe Sub Category" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsRacipeSubCategory}
                      dataValues={dataOfTable}
                      title="Recipe Sub Category List"
                      btn_name={
                        editable === true
                          ? "Edit Recipe Sub Category"
                          : "Create Recipe Sub Category"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Academic Year" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsAcademicYear}
                      dataValues={dataOfTable}
                      title="Academic Year List"
                      btn_name={
                        editable === true
                          ? "Edit Academic Year"
                          : "Create Academic Year"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "App Version Update" ? (
                    <CreateData title="App Version Update" />
                  ) : MenuName === "Send Mail" ? (
                    <CreateData title="Send Mail" />
                  ) : MenuName === "Automated Email" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headAutoEmail}
                      dataValues={dataOfTable}
                      title="Automated Email List"
                      btn_name={
                        editable === true
                          ? "Edit Automated Email"
                          : "Create Automated Email"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Email Template" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.EmailTemplate}
                      dataValues={dataOfTable}
                      title="Email Template List"
                      btn_name={
                        editable === true
                          ? "Edit Email Template"
                          : "Create Email Template"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Home Page" ? (
                    <CreateData title="Home Page" />
                  ) : MenuName === "What We Do (Boxes)" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsWhatWeDo}
                      dataValues={dataOfTable}
                      title="What We Do (Boxes) List"
                      btn_name={
                        editable === true
                          ? "Edit What We Do (Boxes)"
                          : "Create What We Do (Boxes)"
                      }
                      editId={dataEditId}
                    />
                  ) : MenuName === "Core Values (Boxes)" ? (
                    <SchoolDistrict
                      headCells={TableHeaders?.headCellsCoreValues}
                      dataValues={dataOfTable}
                      title="Core Values (Boxes) List"
                      btn_name={
                        editable === true
                          ? "Edit Core Values (Boxes)"
                          : "Create Core Values (Boxes)"
                      }
                      editId={dataEditId}
                    />
                  ) : // ) : MenuName === "CEU Course Page" ? (
                    //   <CreateData title="CEU Course Page" />
                    // ) : MenuName === "Fig Lifestyle Page" ? (
                    //   <CreateData title="Fig Lifestyle Page" />
                    // ) : MenuName === "Coaches Page" ? (
                    //   <CreateData title="Coaches Page" />
                    MenuName === "About Us Page" ? (
                      <CreateData title="About Us Page" />
                    ) : MenuName === "Contact Us Page" ? (
                      <CreateData title="Contact Us Page" />
                    ) : MenuName === "Footer Page" ? (
                      <CreateData title="Footer Page" />
                    ) : MenuName === "Demonstration" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.headCellsDemonstration}
                        dataValues={dataOfTable}
                        title="Demonstration Form List"
                        btn_name={"View Demonstration"}
                        editId={dataEditId}
                      />
                    ) : MenuName === "Webinar Join Request" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.headCellsWebinarForm}
                        dataValues={filterWebinarUserList}
                        title="Webinar Join Request List"
                        btn_name={"View Webinar"}
                        editId={dataEditId}
                        webinarSelect={webinarSelect}
                        setWebinarSelect={setWebinarSelect}
                      />
                    ) : MenuName === "Webinar" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.headCellsWebinar}
                        dataValues={dataOfTable}
                        title="Webinar List"
                        btn_name={
                          editable === true ? "Edit Webinar" : "Create Webinar"
                        }
                        editId={dataEditId}
                      />
                    ) : MenuName === "Proposal" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.headCellsProposal}
                        dataValues={dataOfTable}
                        title="Proposal Form List"
                        btn_name={"View Proposal"}
                        editId={dataEditId}
                      />
                    ) : MenuName === "Free Sources" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.FreeSources}
                        dataValues={dataOfTable}
                        title="Free Sources List"
                        btn_name={
                          editable === true
                            ? "Edit Free Sources"
                            : "Create Free Sources"
                        }
                        editId={dataEditId}
                      />
                    ) : MenuName === "Community Access" ? (
                      <CreateData title="Community Access" />
                    ) : MenuName === "Course Schedule" ? (
                      <SepratePageTableData title="Course Schedule" />
                    ) : MenuName === "District Map" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.headCellsDistrictMap}
                        dataValues={dataOfTable}
                        title="District Map List"
                        btn_name={
                          editable === true
                            ? "Edit District Map"
                            : "Create District Map"
                        }
                        editId={dataEditId}
                      />
                    ) : MenuName === "Goal Category" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.headCellsGoalCategory}
                        dataValues={dataOfTable}
                        title="Goal Category List"
                        btn_name={
                          editable === true
                            ? "Edit Goal Category"
                            : "Create Goal Category"
                        }
                        editId={dataEditId}
                      />
                    ) : MenuName === "Evaluation Question" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.EvalQuestionHeader}
                        dataValues={filterQuestionList}
                        title="Evaluation Question List"
                        btn_name={
                          editable === true ? "Edit Question" : "Create Question"
                        }
                        editId={dataEditId}
                        questionStatus={questionStatus}
                        setQuestionStatus={setQuestionStatus}
                      />
                    ) : MenuName === "Evaluation Group" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.EvalGroupHeader}
                        dataValues={dataOfTable}
                        title="Evaluation Group List"
                        btn_name={
                          editable === true
                            ? "Edit Evaluation Group"
                            : "Create Evaluation Group"
                        }
                        editId={dataEditId}
                      />
                    ) : MenuName === "Evaluation Group Category" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.EvalGroupCategoryHeader}
                        dataValues={dataOfTable}
                        title="Evaluation Group Category List"
                        btn_name={
                          editable === true
                            ? "Edit Group Category"
                            : "Create Group Category"
                        }
                        editId={dataEditId}
                      />
                    ) : MenuName === "Evaluation Setup" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.EvalSetupHeader}
                        dataValues={dataOfTable}
                        title="Evaluation Setup List"
                        btn_name={
                          editable === true ? "Edit Setup" : "Create Setup"
                        }
                        editId={dataEditId}
                      />
                    ) : MenuName === "Evaluation Employee" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.EvalEmployeeHeader}
                        dataValues={dataOfTable}
                        title="Evaluation Employee List"
                        btn_name={
                          editable === true
                            ? "Edit Employee"
                            : "Create Evaluation"
                        }
                        editId={dataEditId}
                        action={true}
                      />
                    ) : MenuName === "Template" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.TemplateHeader}
                        dataValues={dataOfTable}
                        title="Template List"
                        btn_name={
                          editable === true ? "Edit Template" : "Create Template"
                        }
                        editId={dataEditId}
                        action={true}
                      />
                    ) : MenuName === "Campaign" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.CampaignHeader}
                        dataValues={dataOfTable}
                        title="Campaign List"
                        btn_name={
                          editable === true ? "Edit Campaign" : "Create Campaign"
                        }
                        editId={dataEditId}
                        action={true}
                      />
                    ) : MenuName === "Smtp Config" ? (
                      <SchoolDistrict
                        headCells={TableHeaders?.SmtpHeader}
                        dataValues={dataOfTable}
                        title="Smtp List"
                        btn_name={editable === true ? "Edit Smtp" : "Create Smtp"}
                        editId={dataEditId}
                        action={true}
                      />
                    ) : MenuName === "Send Communication Email" ? (
                      <CreateData title="Send Communication Email" />
                    ) : MenuName === "Evaluation Report" ? (
                      <EvaluationReport />
                    ) : (
                      ""
                    )}
                  {MenuList !== undefined || MenuList?.length > 0 ? (
                    <>
                      {MenuName === "" ? (
                        <div className="text-center p_20_text mt-3">
                          Select menu to get started
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div className="text-center p_20_text mt-3">
                      You do not have access
                    </div>
                  )}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default React.memo(Setting);
