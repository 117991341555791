import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import PlayVideo from "../../../components/PlayVideo";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import CoursesCard from "../../../components/CoursesCard";
import MostPopularCard from "../../../components/MostPopularCard";
import meditation from "../../../images/meditation.png";
import { PiSpeakerHighFill, PiSpeakerSlashFill } from "react-icons/pi";
import watchAgainImg from "../../../images/watchAgain.png";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import backimg13 from "../../../images/group_card_back13.png";
import userimg13 from "../../../images/user13.png";
import Swal from "sweetalert2";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../Utils/Config";
import pro_img from "../../../images/default_profile.jpg";
import active_img from "../../../images/Icon/active_green.svg";
import like from "../../../images/Like.png";
import like_ic from "../../../images/Liked_ic.png";
import comment from "../../../images/Comment.png";
import { AcademicYearandSchoolDistrictSelection } from "../../../reducers/Common";
import completed from "../../../images/statusTag/completedTag.png";
import expired from "../../../images/statusTag/expiredTag.png";
import inProgress from "../../../images/statusTag/inProgressTag.png";
import scheduled from "../../../images/statusTag/scheduledTag.png";
import { fetchData, postData } from "../../../Action/NewAction";
import no_image from "../../../images/no_image1.png";
import { SwalMessage, SwalSuccess } from "../../../components/SwalToast";
import { CiEdit } from "react-icons/ci";
import { DashboardButtonAccess } from "../../../reducers/setting";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
const renderLoader = () => <p></p>;
function NewDashboard() {
  let nav = useNavigate();
  let dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const LogUserId = useSelector((state) => state.login.LoginDetails);

  const [openPop, setOpenPop] = useState(false);
  // const [acdYList, setAcdYList] = useState([]);
  const [schList, setSchList] = useState([]);
  // const [acdYS, setAcdYS] = useState();
  const [schoolId, setSchoolId] = useState(LogUserId?.selectedDistrictId);
  const [topCarosoul, setTopCarosoul] = useState([]);
  const [myScheduledCourses, setMyScheduledCourses] = useState([]);
  const [mostPopularCEUCourses, setMostPopularCEUCourses] = useState([]);
  const [mostPopularFigLifestyle, setMostPopularFigLifestyle] = useState([]);
  const [ceuCourses, setCeuCourses] = useState([]);
  const [figLifestyle, setFigLifestyle] = useState([]);
  const [fIGFamily, setFIGFamily] = useState([]);
  const [preferredPartners, setPreferredPartners] = useState([]);
  const [recentlyAdded, setRecentlyAdded] = useState([]);
  const [watchAgain, setWatchAgain] = useState([]);
  const [mostPopular, setMostPopular] = useState([]);
  const [communityGroups, setCommunityGroups] = useState([]);
  const [communityPosts, setCommunityPosts] = useState([]);
  const [operationCourses, setOperationCourses] = useState([]);
  const [administration, setAdministration] = useState([]);
  const [communicationsMarketing, setCommunicationsMarketing] = useState([]);
  const [nutrition, setNutrition] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [mute, setMute] = useState(false);
  const [schoolOne, setSchoolOne] = useState(false);

  const handleSlideChange = (swiper) => {
    setActiveIndex(isNaN(swiper.realIndex) ? 0 : swiper.realIndex);
  };
  const DashBtnList = useSelector((state) => state?.setting?.DashBtnList);

  const dashboardPopup = useSelector((state) => state.Common?.DashSelection);
  const dontShow = sessionStorage.getItem("dontShow");

  useEffect(() => {
    if (dashboardPopup?.schoolDistrictId) {
      setOpenPop(false);
      onSubmit(dashboardPopup);
    }
  }, [dashboardPopup]);
  
  useEffect(() => {
    if (schoolOne) {
      onSubmit();
      setSchoolOne(false);
    }
  }, [schoolOne]);
  useEffect(() => {
    GetSchoolDistrictListct(LogUserId.userId, LogUserId?.currentAcademicYearId);
  }, [])

  useEffect(() => {
    getDashbtnAcc();
    // eslint-disable-next-line
  }, []);
  const getDashbtnAcc = async () => {
    if (DashBtnList === undefined || DashBtnList?.length === 0) {
      const { data, error } = await fetchData(`/api/AccessLevel/GetAccessLevelByRoleId?roleId=${LogUserId.roleId}`)
      if (data) {
        let btnData = data.payload;
        dispatch(DashboardButtonAccess(btnData));
      }

    }
  };
  const handleClose = () => {
    setOpenPop(false);
  };

  // const getAcademicYearList = async () => {
  //   let seData = {
  //     url: `${Config.API_HOST_URL_live}/api/SchoolAcademicYear/GetSchoolAcademicYearList`,
  //   };
  //   const response = await GetCallApi(seData);
  //   if (response?.status === 200) {
  //     if (response.data.success) {
  //       if (response.data.payload?.length > 0) {
  //         const user = response?.data.payload?.map((item, i) => {
  //           return {
  //             academicYearId: item?.academicYearId ? item?.academicYearId : "",
  //             academicYear: item?.academicYear ? item?.academicYear : "",
  //             isCurrentYear: item?.isCurrentYear ? "True" : "False",
  //           };
  //         });
  //         setAcdYList(user);
  //         let curtY = user?.find((x) => x.isCurrentYear === "True");
  //         setAcdYS(curtY?.academicYearId);
  //         return curtY
  //       } else {
  //         setAcdYList();
  //       }
  //     } else {
  //       setAcdYList([]);
  //     }
  //   } else {
  //     setAcdYList([]);
  //   }
  // };

  const GetSchoolDistrictListct = async (userId) => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${userId} `,
    };
    const response = await GetCallApi(seData);
    if (response?.status === 200) {
      if (response.data.success) {
        const data = response?.data.payload?.map((item) => {
          return {
            schId: item.schoolDistrictId,
            schName: item.districtName,
          };
        });
        setSchList(data);
        if (!dashboardPopup?.schoolDistrictId) {
          if (data?.length === 1) {
            setSchoolId(data[0].schId)
            setSchoolOne(true)
          } else if (data?.length > 0) {
            setOpenPop(true);
            setSchoolId(LogUserId.schoolDistrictId)
          }
        }
      } else {
        setSchList([]);
      }
    } else {
      setSchList([]);
    }
  };

  const onSubmit = async (e) => {
    if (e) {
      callAllApi(e);
    } else {
      if (schoolId) {
        // let ayD = ayId?.academicYearId;
        let data = {
          pageNumber: 1,
          pageSize: 20,
          userId: LogUserId.userId,
          // academicYearId: ayD ?? Number(acdYS),
          // academicYearName: ayId?.academicYear ?? acdYList?.find((x) => x?.academicYearId === Number(acdYS))
          //   ?.academicYear,
          academicYearId: LogUserId?.currentAcademicYearId,
          academicYearName: LogUserId?.currentAcademicYear,
          schoolDistrictId: schoolId,
          schoolName: schList?.length > 1 ? schList?.find((x) => x?.schId === schoolId)?.schName : LogUserId?.schooldistrictName,
        };
        let res = await postData(`/api/InterestingDashboard/SaveUserSchoolDistrict`,
          {
            userId: LogUserId.userId,
            selectedSchoolDistrictId: schoolId
          }
        )
        if (res?.data) {
          dispatch(AcademicYearandSchoolDistrictSelection(data));
          setOpenPop(false);
          callAllApi(data);
        }
      } else {
        SwalMessage("Please select school district ")
      }
    }
  };

  const callAllApi = async (data) => {
    TopCarosoulMostPopular(data);
    MostPopular({ ...data, pageSize: 10 });
    MyScheduledCourses(data);
    RecentlyAdded(data);
    MostPopularCEUCourses(data);
    MostPopularFigLifestyle(data);
    CeuCourses(data);
    FigLifestyle(data);
    PreferredPartners(data);
    FIGFamily(data);
    OperationCourses(data);
    Administration(data);
    CommunicationsMarketing(data);
    Nutrition(data);
    WatchAgain(data);
    CommunityGroups(data);
    CommunityPosts(data);
  };

  const ApiFunc = async (url, send) => {
    setOpen(true);
    const { data, error } = await postData(url, send);
    let res = data.data;
    if (res.success && res.payload?.length > 0) {
      setOpen(false);
      return { res: res.payload, error: null };
    } else {
      setOpen(false);

      return { res: null, error: res?.message ? res?.message : error };
    }
  };

  const TopCarosoulMostPopular = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetTopPopularCourseList`,
      data
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl:
          item?.courseType === "1"
            ? `/ceu-courses/${item?.courseId}`
            : `/fig-lifestyle/${item?.courseId}`,
      }));
      setTopCarosoul(data);
    }
  };
  const MostPopular = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetMostPopularCourseList`,
      data
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl:
          item?.courseType === "1"
            ? `/ceu-courses/${item?.courseId}`
            : `/fig-lifestyle/${item?.courseId}`,
      }));
      setMostPopular(data);
    }
  };
  const MyScheduledCourses = async (sendData) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetMyScheduledCourse`,
      sendData
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl: `/ceu-courses/${item?.courseId}`,
      }));
      setMyScheduledCourses(data);
    }
  };
  const MostPopularCEUCourses = async (data) => {
    let {
      res,
    } = await ApiFunc(
      `/api/InterestingDashboard/GetMostPopularCourseListByCourseType`,
      { ...data, courseType: "1" }
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl: `/ceu-courses/${item?.courseId}`,
        courseType: "1",
      }));
      setMostPopularCEUCourses(data);
    }
  };
  const MostPopularFigLifestyle = async (data) => {
    let {
      res,
    } = await ApiFunc(
      `/api/InterestingDashboard/GetMostPopularCourseListByCourseType`,
      { ...data, courseType: "2" }
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl: `/fig-lifestyle/${item?.courseId}`,
        courseType: "2",
      }));
      setMostPopularFigLifestyle(data);
    }
  };
  const CeuCourses = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetCourseListByCourseType`,
      {
        ...data,
        courseType: "1",
      }
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl: `/ceu-courses/${item?.courseId}`,
        courseType: "1",
      }));
      setCeuCourses(data);
    }
  };
  const FigLifestyle = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetCourseListByCourseType`,
      {
        ...data,
        courseType: "2",
      }
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl: `/fig-lifestyle/${item?.courseId}`,
        courseType: "2",
      }));
      setFigLifestyle(data);
    }
  };
  const PreferredPartners = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetPreferredPartnerList`,
      data
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl: `/preferred-partners/${item?.schoolDistrictId}`,
      }));
      setPreferredPartners(data);
    }
  };
  const FIGFamily = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetFigFamilyList`,
      data
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl: `/fig-family/${item?.schoolDistrictId}`,
      }));
      setFIGFamily(data);
    }
  };
  const RecentlyAdded = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetRecentlyAddedCourseList`,
      data
    );

    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl:
          item?.courseType === "1"
            ? `/ceu-courses/${item?.courseId}`
            : `/fig-lifestyle/${item?.courseId}`,
      }));
      setRecentlyAdded(data);
      setOpen(false);
    }
  };
  const WatchAgain = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetWatchAgainCourseList`,
      data
    );
    if (res) {
      setWatchAgain(res);
    }
  };
  const CommunityGroups = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetCommunityGroupsList`,
      data
    );
    if (res) {
      setCommunityGroups(res);
    }
  };
  const CommunityPosts = async (data) => {
    let { res } = await ApiFunc(
      `/api/InterestingDashboard/GetCommunityPostsData`,
      data
    );
    if (res) {
      setCommunityPosts(res);
    }
  };
  const OperationCourses = async (data) => {
    let {
      res,
    } = await ApiFunc(
      `/api/InterestingDashboard/GetCourseListByCourseCategory`,
      { ...data, courseCategoryName: "Operations" }
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl:
          item?.courseType === "1"
            ? `/ceu-courses/${item?.courseId}`
            : `/fig-lifestyle/${item?.courseId}`,
      }));
      setOperationCourses(data);
    }
  };
  const Administration = async (data) => {
    let {
      res,
    } = await ApiFunc(
      `/api/InterestingDashboard/GetCourseListByCourseCategory`,
      { ...data, courseCategoryName: "Administration" }
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl:
          item?.courseType === "1"
            ? `/ceu-courses/${item?.courseId}`
            : `/fig-lifestyle/${item?.courseId}`,
      }));
      setAdministration(data);
    }
  };
  const CommunicationsMarketing = async (data) => {
    let {
      res,
    } = await ApiFunc(
      `/api/InterestingDashboard/GetCourseListByCourseCategory`,
      { ...data, courseCategoryName: "Communications and Marketing" }
    );
    if (res) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl:
          item?.courseType === "1"
            ? `/ceu-courses/${item?.courseId}`
            : `/fig-lifestyle/${item?.courseId}`,
      }));
      setCommunicationsMarketing(data);
    }
  };
  const Nutrition = async (data) => {
    let {
      res,
    } = await ApiFunc(
      `/api/InterestingDashboard/GetCourseListByCourseCategory`,
      { ...data, courseCategoryName: "Nutrition" }
    );
    if (res?.length > 0) {
      const data = res?.map((item) => ({
        ...item,
        dataUrl:
          item?.courseType === "1"
            ? `/ceu-courses/${item?.courseId}`
            : `/fig-lifestyle/${item?.courseId}`,
      }));
      setNutrition(data);
    }
  };

  // const handleAcademicYear = (e) => {
  //   setAcdYS(e);
  // };
  const handleSchoolDist = (e) => {
    setSchoolId(e);
  };

  const sections = [
    {
      title: "My Scheduled Courses",
      dataKey: "myScheduledCourses",
      // data: myScheduledCourses,
      data: myScheduledCourses?.length > 0 ? [] : myScheduledCourses,
      exploreAllLink: "/dashboard/course-schedule",
    },
    {
      title: "Most Popular",
      dataKey: "mostPopular",
      data: mostPopular,
      exploreAllLink: "/ceu-courses",
    },
    {
      title: "Recently Added",
      dataKey: "recentlyAdded",
      data: recentlyAdded,
      exploreAllLink: "/ceu-courses",
    },
    {
      title: "Most Popular CEU Courses",
      dataKey: "mostPopularCEUCourses",
      data: mostPopularCEUCourses,
      exploreAllLink: "/ceu-courses",
    },
    {
      title: "CEU Courses",
      dataKey: "ceuCourses",
      data: ceuCourses,
      exploreAllLink: "/ceu-courses",
    },
    {
      title: "Most Popular Fig Lifestyle",
      dataKey: "mostPopularFigLifestyle",
      data: mostPopularFigLifestyle,
      exploreAllLink: "/fig-lifestyle",
    },
    {
      title: "Fig Lifestyle",
      dataKey: "figLifestyle",
      data: figLifestyle,
      exploreAllLink: "/fig-lifestyle",
    },
    {
      title: "Nutrition Courses",
      dataKey: "nutritionCourses",
      data: nutrition,
      exploreAllLink: "/ceu-courses?search=Nutrition",
    },
    {
      title: "Operation Courses",
      dataKey: "operationCourses",
      data: operationCourses,
      exploreAllLink: "/ceu-courses?search=Operations",
    },
    {
      title: "Administration Courses",
      dataKey: "administrationCourses",
      data: administration,
      exploreAllLink: "/ceu-courses?search=Administration",
    },
    {
      title: "Communication & Marketing Courses",
      dataKey: "communicationMarketingCourses",
      data: communicationsMarketing,
      exploreAllLink: "/ceu-courses?search=Communications and Marketing",
    },
    {
      title: "Preferred Partners",
      dataKey: "preferredPartners",
      data: preferredPartners,
      exploreAllLink: "/preferred-partners",
    },
    {
      title: "FIG Family",
      dataKey: "fIGFamily",
      data: fIGFamily,
      exploreAllLink: "/fig-family",
    },
  ];

  const handleRede = (id) => {
    nav(`/community-groups/${id}`, { replace: true });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const differenceInSeconds = Math.floor((now - date) / 1000);

    if (differenceInSeconds < 60) {
      return "Just Now";
    }

    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} min ago`;
    }

    const differenceInHours = Math.floor(differenceInMinutes / 60);
    if (differenceInHours < 24) {
      return `${differenceInHours} hour${differenceInHours > 1 ? "s" : ""} ago`;
    }

    const differenceInDays = Math.floor(differenceInHours / 24);
    if (differenceInDays < 7) {
      return `${differenceInDays} day${differenceInDays > 1 ? "s" : ""} ago`;
    }

    const differenceInWeeks = Math.floor(differenceInDays / 7);
    if (differenceInWeeks < 4) {
      return `${differenceInWeeks} week${differenceInWeeks > 1 ? "s" : ""} ago`;
    }

    const differenceInMonths = Math.floor(differenceInDays / 30);
    if (differenceInMonths < 12) {
      return `${differenceInMonths} month${differenceInMonths > 1 ? "s" : ""
        } ago`;
    }

    const differenceInYears = Math.floor(differenceInDays / 365);
    return `${differenceInYears} year${differenceInYears > 1 ? "s" : ""} ago`;
  };

  const handleDontShow = () => {
    setOpen(true);
    setTimeout(() => {
      sessionStorage.setItem("dontShow", true);
      setOpen(false);
    }, 500);
  };

  const startCourse = async (id, type) => {
    let stCou = {
      courseId: id,
      userId: LogUserId?.userId,
      status: "In Progress",
    };
    setOpen(true);
    let response = await PostCallApi({
      url: `/api/UserCourse/StartUserCourse`,
      body: stCou,
    });
    setOpen(false);
    if (response.data.success) {
      setOpen(false);
      SwalSuccess(response?.data?.message);

      if (type === "1" || type === 1) {
        nav(`/ceu-courses/start-course/${id}`, { replace: true });
      } else if (type === "2" || type === 2) {
        nav(`/fig-lifestyle/start-course/${id}`, {
          replace: true,
        });
      }
    } else {
      setOpen(false);
    }
  };
  const handlePopup = async () => {
    setOpenPop(true);
    setSchoolId(dashboardPopup?.schoolDistrictId);
    // setAcdYS(dashboardPopup?.academicYearId);
  };

  const swiperResponsive = {
    640: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1440: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1920: {
      slidesPerView: 6,
      spaceBetween: 30,
    }
  };
  const watchAgainSwiperResponsive = {
    640: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1440: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1920: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  };

  const firstCarasoul = myScheduledCourses?.length > 0 ? myScheduledCourses : topCarosoul;

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="topSection">
          <div className="showData">
            <p>
              <b>{dashboardPopup?.schoolName}</b>{" "}
              {`(${LogUserId?.currentAcademicYear})`}
            </p>
            {schList?.length > 1 ?
              <CiEdit className="icon" onClick={() => handlePopup()} /> : ""}
          </div>
          {!dontShow && (
            <div className="dashboardSlider">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop
                modules={[Pagination]}
                onSlideChange={handleSlideChange}
                pagination={{ clickable: true }}
              >
                {firstCarasoul.map((data, i) => (
                  <SwiperSlide key={i}>
                    <div className="row">
                      <div className="col-lg-4 col-md-5">
                        <div className="leftside">
                          {/* <h1>Most Popular</h1> */}
                          <h1>{myScheduledCourses?.length > 0 ? "My Scheduled Courses" : "Most Popular"}</h1>
                          <div className="imgbox">
                            <img
                              className="mostpopularImg"
                              src={
                                data?.courseImage
                                  ? Config?.API_HOST_URL_live +
                                  data?.courseImage
                                  : meditation
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = meditation;
                              }}
                              alt=""
                            />
                            {data?.status && (
                              <div className="statusTag">
                                <img
                                  src={
                                    data?.status?.toLowerCase() === "completed"
                                      ? completed
                                      : data?.status === "Expired"
                                        ? expired
                                        : data?.status === "In Progress"
                                          ? inProgress
                                          : data?.status === "Scheduled"
                                            ? scheduled
                                            : ""
                                  }
                                  alt="Status Tag"
                                />
                              </div>
                            )}
                            {data?.instructorName && (
                              <div className="offeredBy">
                                Offered By: {data?.instructorName}
                              </div>
                            )}
                          </div>
                          <h3>{data?.courseName}</h3>
                          <p>{data?.courseDescription}</p>
                          <div className="type">
                            <span>
                              <b>{data?.totalLessons}</b> Lessons
                            </span>
                            <span className="dot"></span>
                            <span>
                              CEUs: <b>{data?.courseCEU}</b>
                            </span>
                          </div>
                          {data?.status === "Scheduled" ?
                            <button
                              className="theme_btn"
                              onClick={() =>
                                startCourse(data?.courseId, data?.courseType)
                              }
                            >
                              Start Course
                            </button> :
                            <Link
                              className="orange_btn"
                              to={
                                data?.courseType === "1"
                                  ? `/ceu-courses/${data?.courseId}`
                                  : `/fig-lifestyle/${data?.courseId}`
                              }
                            >
                              View Course
                            </Link>}

                          {/* {data?.status === "Completed" ? (
                            <Link
                              className="orange_btn"
                              to={
                                data?.courseType === "1"
                                  ? `/ceu-courses/complete-course/${data?.courseId}`
                                  : `/fig-lifestyle/complete-course/${data?.courseId}`
                              }
                            >
                              Start Again
                            </Link>
                          ) : (
                            <button
                              className="theme_btn"
                              onClick={() =>
                                startCourse(data?.courseId, data?.courseType)
                              }
                            >
                              Start Course
                            </button>
                          )} */}
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-7">
                        <div className="rightSide">
                          {/* {data?.courseImageorVideo ? (
                            activeIndex === i ? (
                              <PlayVideo
                                video={
                                  data?.courseImageorVideo
                                    ? data?.courseImageorVideo
                                    : data?.courseLessionImage
                                      ? Config?.API_HOST_URL_live +
                                      data?.courseLessionImage
                                      : ""
                                }
                                controls={false}
                                playing={false}
                                // playing={activeIndex === i}
                                mute={mute}
                              />
                            ) : null
                          ) : ( */}
                          <img
                            className="mostpopularImg"
                            src={
                              data?.courseCoverImage
                                ? Config?.API_HOST_URL_live +
                                  data?.courseCoverImage
                               : data?.courseImage
                                ? Config?.API_HOST_URL_live +
                                data?.courseImage
                                : meditation
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = meditation;
                            }}
                            alt=""
                          />
                          {/* )} */}
                          <div className="controls">
                            {/* <button className="iconBtn" onClick={() => setMute(!mute)}>
                              {mute ? <PiSpeakerSlashFill /> : <PiSpeakerHighFill />}
                            </button> */}
                            <button
                              className="theme_btn"
                              onClick={handleDontShow}
                            >
                              Don't Show Again
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
        {sections.map(
          (data, i) =>
            data?.data?.length > 0 && (
              <div className={`line ${data?.dataKey}`} key={i}>
                <div className="title">
                  <h3>{data?.title}</h3>
                  {data?.dataKey !== "recentlyAdded" && (
                    <Link className="text_linear" to={data?.exploreAllLink}>
                      Explore All <FaAngleRight />
                    </Link>
                  )}
                </div>
                <div className="cardSlide">
                  <Swiper spaceBetween={20} breakpoints={swiperResponsive}>
                    {(data?.dataKey === "mostPopular"
                      ? (data?.data || []).slice(0, 10)
                      : data?.data || []
                    ).map((item, i) =>
                      data?.dataKey === "mostPopular" ? (
                        <SwiperSlide key={i}>
                          <MostPopularCard number={i + 1} data={item} />
                        </SwiperSlide>
                      ) : (
                        <SwiperSlide key={i}>
                          <CoursesCard number={i} data={item} nameKey={data?.dataKey} />
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>

                </div>
              </div>
            )
        )}
        {watchAgain?.length > 0 && (
          <div className="watchAgain">
            <div className="title">
              <h3>Watch Again</h3>
              <Link className="text_linear" to={"/dashboard/completed-course-roster"}>
                Explore All <FaAngleRight />
              </Link>
            </div>
            <div className="watchAgainCard">
              <Swiper
                spaceBetween={30}
                breakpoints={watchAgainSwiperResponsive}
              >
                {watchAgain.map((item, i) => (
                  <SwiperSlide key={i}>
                    <div className="watchCoursesCard">
                      <div className="cardImage">
                        <img
                          className="watchcardimg"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = no_image;
                          }}
                          src={
                            item?.courseImage
                              ? Config?.API_HOST_URL_live + item?.courseImage
                              : watchAgainImg
                          }
                          alt=""
                        />
                        {item?.status && (
                          <div className="statusTag">
                            <img
                              src={
                                item?.status === "Completed"
                                  ? completed
                                  : item?.status === "Expired"
                                    ? expired
                                    : item?.status === "In Progress"
                                      ? inProgress
                                      : item?.status === "Scheduled"
                                        ? scheduled
                                        : ""
                              }
                              alt="Status Tag"
                            />
                          </div>
                        )}
                      </div>
                      <div className="contain">
                        <h3>{item?.courseName}</h3>
                        <h6
                          className="text_linear"
                          style={{ width: "max-content", fontWeight: "600" }}
                        >
                          {item?.instructorName}
                        </h6>
                        <p>
                          CEUs : <b>{item?.courseTotalCEU}</b>
                        </p>
                        <p>{item?.totalLesson} Lessons</p>
                        <Link
                          className="theme_btn"
                          to={`/dashboard/completed-course-roster/${item?.courseId}/${item?.status}`}
                        >
                          View Completed Employees
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
        {communityPosts?.length > 0 && (
          <div className="communityPosts">
            <div className="title">
              <h3>Recents Posts</h3>
              <Link className="text_linear" to={"/community"}>
                Explore All <FaAngleRight />
              </Link>
            </div>
            <div className="communityPostsCard">
              <Swiper spaceBetween={20} breakpoints={swiperResponsive}>
                {communityPosts.map((data, i) => (
                  <SwiperSlide key={i}>
                    <div
                      className="postcard"
                      key={data?.key ? data?.key : data?.postId}
                    >
                      <div className="d-flex comment_name_palte">
                        <div className="img_box">
                          <img
                            src={
                              data.thumbnailProfileUrl !== ""
                                ? Config?.API_HOST_URL_live +
                                data.thumbnailProfileUrl
                                : pro_img
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = pro_img;
                            }}
                            className="img-fluid post_u_img"
                            alt=""
                          />
                          <img
                            src={
                              data?.userStatus === "Online" ? active_img : ""
                            }
                            alt=""
                            className="img-fluid dot_ac_in"
                          />
                        </div>
                        <div className="last_comment w-100">
                          <div className="comment_title d-flex">
                            <p>{data?.firstName + " " + data?.lastName}</p>
                          </div>
                          <span className="text-muted">
                            {data?.postTime}
                            {/* {formatDate(data?.postTime)} */}
                          </span>
                        </div>
                      </div>
                      {data.attachments?.length > 0 && (
                        <>
                          <div className="row mx-0 px-3">
                            {data.attachments?.map((item, i) => {
                              if (i <= 4 && item.postId === data?.postId) {
                                return (
                                  <div className={`img_h_full`} key={i}>
                                    {data?.postTypes === "Image" ||
                                      data?.postTypes === "Goal" ? (
                                      <img
                                        src={
                                          item?.attachmentUrl === "" ||
                                            item?.attachmentUrl === null
                                            ? pro_img
                                            : `${Config.API_HOST_URL_live}${item.attachmentUrl}`
                                        }
                                        alt=""
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = pro_img;
                                        }}
                                        className={`img-fluid pst_img`}
                                      />
                                    ) : (
                                      <video width="100%" height="280" controls>
                                        <source
                                          src={
                                            item?.attachmentUrl === "" ||
                                              item?.attachmentUrl === null
                                              ? ""
                                              : `${Config.API_HOST_URL_live}${item.attachmentUrl}`
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                    )}
                                  </div>
                                );
                              }
                              return "";
                            })}
                          </div>
                        </>
                      )}
                      {/* {data?.postTypes === "Goal" && (
                        <div className="post_des px-3">
                          <b>{data.goalName}</b>
                        </div>
                      )} */}
                      {data?.description && (
                        <div className="post_des px-3">
                          <p>{data.description}</p>
                        </div>
                      )}

                      <div className="d-flex gap-4 react_action_button">
                        <div className="d-flex gap-2 align-items-center liks">
                          <label>
                            <IconButton className="p-1">
                              <img
                                src={data?.isLiked ? like_ic : like}
                                className="img-fluid"
                                alt=""
                              />
                            </IconButton>
                          </label>
                          <span>
                            {data?.totalLike === 0 ? "" : data?.totalLike}
                          </span>
                        </div>
                        <label>
                          <IconButton className="p-1">
                            <img src={comment} className="img-fluid" alt="" />
                          </IconButton>
                          {data?.totalComment === 0 ? "" : data?.totalComment}
                        </label>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
        {communityGroups?.length > 0 && (
          <div className="communityGroups">
            <div className="title">
              <h3>Community Groups</h3>
              <Link className="text_linear" to={'/community-groups'}>
                Explore All <FaAngleRight />
              </Link>
            </div>
            <div className="communityGroupsCard">
              <Swiper spaceBetween={30} breakpoints={swiperResponsive}>
                {communityGroups.map((item, i) => (
                  <SwiperSlide key={i}>
                    <div className="grid_groups_card text-center" key={i}>
                      <img
                        src={
                          item?.communityGroupCoverImage
                            ? `${Config.API_HOST_URL_live}${item?.communityGroupCoverImage}`
                            : backimg13
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = backimg13;
                        }}
                        alt="backimg"
                        className="img-fluid banner_img"
                      />
                      <div className="groups_card_text">
                        <img
                          src={
                            item?.communityGroupImage
                              ? `${Config.API_HOST_URL_live}${item?.communityGroupImage}`
                              : userimg13
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = userimg13;
                          }}
                          alt="profileimg"
                          className="img-fluid group_img mb-3"
                        />
                        <h6>{item?.communityGroupName}</h6>
                        <p>{item?.communityGroupTypeName}</p>
                      </div>

                      <div className="mt-2 groups_card_btn pb-3 border-0">
                        <button
                          className="theme_btn"
                          onClick={() => handleRede(item?.communityGroupId)}
                        >
                          View Group
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}

        {openPop && (
          <>
            <Modal
              open={openPop}
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  handleClose();
                }
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
              sx={{ zIndex: 9 }}
            >
              <Box sx={style} className="delete_data_box">
                <div className="popup_delete_detail_box">
                  <div>
                    <h6>School District</h6>
                    {/* <h6>Academic Year and School District</h6> */}
                  </div>
                  <div className="popup_delete_detail">
                    <form className="row">
                      {/* <div className={`col-md-${schList?.length > 1 ? 6 : 12} mb-3`}>
                        <div className="form-group">
                          <label className="form-label">Academic Year</label>
                          <select
                            name=""
                            className="form-select"
                            value={acdYS}
                            onChange={(e) => handleAcademicYear(e.target.value)}
                          >
                            <option value="">Select Academic Year</option>
                            {acdYList &&
                              acdYList?.map((acy, i) => (
                                <option value={acy.academicYearId} key={i}>
                                  {acy.academicYear}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div> */}
                      {/* {schList?.length > 1 ? */}
                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <label className="form-label">School District</label>
                          <select
                            name=""
                            className="form-select"
                            value={schoolId}
                            onChange={(e) => handleSchoolDist(e.target.value)}
                          >
                            <option value=""> Select School District </option>
                            {schList &&
                              schList?.map((data, i) => (
                                <option key={i} value={data.schId}>
                                  {data.schName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* : ""} */}
                    </form>
                  </div>
                  <div className="modal-footer p-3 border-top d-flex gap-3">
                    <button className="theme_btn" onClick={() => onSubmit()}>
                      Submit
                    </button>
                    {dashboardPopup?.schoolDistrictId &&
                      <button className="Can_cel_btn" onClick={() => handleClose()}>
                        Close
                      </button>}
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default React.memo(NewDashboard);
