import React, { useEffect, useState } from "react";
import "./index.scss";
import { IoCalendarClearOutline } from "react-icons/io5";
import completed from "../../images/statusTag/completedTag.png";
import expired from "../../images/statusTag/expiredTag.png";
import inProgress from "../../images/statusTag/inProgressTag.png";
import scheduled from "../../images/statusTag/scheduledTag.png";
import no_image from "../../images/no_image1.png";
import Config from "../../Utils/Config";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CeuCoursePopup from "../../Modules/Reuseable_Components/CeuCoursePopup/CeuCoursePopup";
import { SwalSuccess } from "../SwalToast";
import { PostCallApi } from "../../Action/Action";
import { Button } from "@mui/material";

const CoursesCard = ({ data, nameKey }) => {
  const [formattedDate, setFormattedDate] = useState("");
  const DashBtnList = useSelector((state) => state?.setting?.DashBtnList);
  const LogUserId = useSelector((state) => state.login.LoginDetails);

  const [open, setOpen] = useState(false);
  let nav = useNavigate();
  useEffect(() => {
    if (data?.courseDate) {
      const formatedDate = formatDate(data?.courseDate);
      setFormattedDate(formatedDate);
    }
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const startCourse = async (data) => {
    let stCou = {
      courseId: data?.courseId,
      userId: LogUserId?.userId,
      status: "In Progress",
    };
    setOpen(true);
    let seData = {
      url: `${Config.API_HOST_URL_live}/api/UserCourse/StartUserCourse`,
      body: stCou,
    };
    const response = await PostCallApi(seData);
    // let response = await axios.post(
    //   `${Config.API_HOST_URL_live}/api/UserCourse/StartUserCourse`,
    //   stCou,
    //   { headers }
    // );
    setOpen(false);
    if (response.data.success) {
      setOpen(false);
      let msg = await titleCase(response.data?.message);
      SwalSuccess(msg);

      if (data?.courseType === "1" || data?.courseType === 1) {
        nav(`/ceu-courses/start-course/${data?.courseId}`, { replace: true });
      } else if (data?.courseType === "2" || data?.courseType === 2) {
        nav(`/fig-lifestyle/start-course/${data?.courseId}`, {
          replace: true,
        });
      }
      // if (pageSize) {
      // } else {
      //   nav(`/${location.pathname?.split("/")[1]}/start-course/${id}`, {
      //     replace: true,
      //   });
      // }
    } else {
      setOpen(false);
    }
  };

  const completeCourse = (data) => {
    if (data?.courseType === "1" || data?.courseType === 1) {
      nav(`/ceu-courses/complete-course/${data?.courseId}`, { replace: true });
    } else if (data?.courseType === "2" || data?.courseType === 2) {
      nav(`/fig-lifestyle/complete-course/${data?.courseId}`, { replace: true });
    }
  };

  return (
    <div className="CoursesCard">
      <Link to={data?.dataUrl}>
        <div className="cardImage">
          <img
            className="mainImg"
            src={
              data?.courseImage
                ? Config?.API_HOST_URL_live + data?.courseImage
                : no_image
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = no_image;
            }}
            alt="Course"
          />
          {data?.status && (
            <div className="statusTag">
              <img
                src={
                  data?.status?.toLowerCase() === "completed"
                    ? completed
                    : data?.status === "Expired"
                    ? expired
                    : data?.status === "In Progress"
                    ? inProgress
                    : data?.status === "Scheduled"
                    ? scheduled
                    : ""
                }
                alt="Status Tag"
              />
            </div>
          )}
          {data?.instructorName && (
            <div className="offeredBy">Offered By: {data?.instructorName}</div>
          )}
        </div>
        <div className="contain">
          {data?.courseName && <h3>{data?.courseName}</h3>}
          {data?.schoolDistrictName && <h3>{data?.schoolDistrictName}</h3>}
          {data?.districtName && <h3>{data?.districtName}</h3>}
          {data?.desc && <p>{data?.desc}</p>}
          {data?.schoolAboutUs && <p>{data?.schoolAboutUs}</p>}
          {data?.aboutUs && <p>{data?.aboutUs}</p>}
          {data?.totalLesson || data?.userCEU || data?.CourseTotalCEU ? (
            <div className="type">
              <span>
                <b>{data?.totalLesson}</b> Lessons
              </span>
              <div className="dot"></div>
              {nameKey === "mostPopularCEUCourses" ||
              nameKey === "mostPopularFigLifestyle" ? (
                <span>
                  CEUs:<b> {data?.courseTotalCEU}</b>
                </span>
              ) : (
                <span>
                  CEUs:<b> {data?.userCEU}</b>
                </span>
              )}
            </div>
          ) : (
            ""
          )}
          {data?.dueDate && (
            <span>
              <IoCalendarClearOutline className="icon" />
              {data?.dueDate}
            </span>
          )}
          {data?.courseDate && (
            <span>
              <IoCalendarClearOutline className="icon" />
              {formattedDate}
            </span>
          )}
        </div>
      </Link>
      {data?.status === "In Progress" ||
      data?.status === "Scheduled" ||
      data?.status === "" ||
      data?.status === null ? (
        <div className="btnGroup">
          <Button className="st_btn" onClick={() => startCourse(data)}>
            Start Course
          </Button>
          {DashBtnList?.map((check, i) => {
            if (
              check?.menuName === "Assign Course Button On Dashboard" &&
              check?.isAssigned === true
            ) {
              return (
                <CeuCoursePopup
                  btnName="Assign Course"
                  courseId={data?.courseId}
                  notFullwidth={true}
                />
              );
            }
            return "";
          })}
        </div>
      ) : data?.status?.toLowerCase() === "completed" ? (
        <div className="btnGroup">
          <Button className="st_btn ora" onClick={() => completeCourse(data)}>
            Start Again
          </Button>
          {DashBtnList?.map((check, i) => {
            if (
              check?.menuName === "Assign Course Button On Dashboard" &&
              check?.isAssigned === true
            ) {
              return (
                <CeuCoursePopup
                  btnName="Assign Course"
                  courseId={data?.courseId}
                  notFullwidth={true}
                />
              );
            }
            return "";
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CoursesCard;
