// import { SwalError } from '../components/SwalToast';
import Axios from '../Utils/AxiosConfig';

// GET request function
export const fetchData = async (url) => {
    try {
        const response = await Axios.get(url);
        return { data: response.data, error: null };
    } catch (error) {
        console.error('Error fetching data: ', error);
        // SwalError(error.response ? error.response.data : error.message);
        return { data: null, error: error.response ? error.response.data : error.message };
    }
};

// POST request function
export const postData = async (url, payload) => {
    try {
        const response = await Axios.post(url, payload);
        return { data: response.data, error: null };
    } catch (error) {
        console.error('Error posting data: ', error);
        // SwalError(error.response ? error.response.data : error.message);
        return { data: null, error: error.response ? error.response.data : error.message };
    }
};

// DELETE request function
export const deleteData = async (url) => {
    try {
        const response = await Axios.delete(url);
        return { data: response.data, error: null };
    } catch (error) {
        console.error('Error deleting data: ', error);
        // SwalError(error.response ? error.response.data : error.message);
        return { data: null, error: error.response ? error.response.data : error.message };
    }
};
