const vw_EmployeeEvaluationDetailedReport_data = [
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4AF99E63-259B-4E95-977E-7DA20C9865F7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Innovation: How often does the employee suggest new ideas or methods to improve the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Excellent work daily",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2ABD23F-BA22-486B-8B1A-CB47163E182F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Overall Performance: How would you rate the employee\u2019s overall performance in their role within the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Great",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "202B6B59-CA79-48FB-B0D1-96A9E23810B7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attention to Detail: How thorough is the employee in ensuring accuracy in meal preparation and serving sizes?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "fair -",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2407B09-B942-48B7-9F94-A76781B9F7C1",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Training and Development: How actively does the employee engage in training opportunities and apply learned skills on the job?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "She is active",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "565ABCC7-8CE1-4CA5-A38F-59DCA3950F52",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Job Knowledge: How well does the employee understand their role and responsibilities in the school nutrition program?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "597A3243-77F4-4E7E-8765-C0D400A02FE5",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Food Safety Compliance: How effectively does the employee adhere to food safety and sanitation protocols?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "30BB4F13-A810-4843-90C4-E1F43B71E12F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Problem-Solving: How well does the employee handle unexpected challenges or issues that arise during meal service?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "C35637A3-DCA8-40A7-A2E7-66468F07C473",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attendance and Punctuality: How reliable is the employee in terms of attendance and arriving on time for shifts?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "12383504-AA67-4C4D-BD00-67E01DA6836D",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Compliance with Policies: How well does the employee adhere to school district policies and procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A137C91C-E1FE-4263-BA98-91DDDB723505",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Efficiency in Serving: How effectively does the employee manage time during meal service to ensure timely food delivery?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7CF56414-779B-4E44-9300-3AF7839D3E7E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Inventory Management: How well does the employee handle food inventory, including proper storage and rotation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "77EC5AC0-3FC3-47A3-B49E-17D4D66DA738",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Adaptability: How quickly and effectively does the employee adapt to changes in menus, schedules, or procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "57CBD739-14C3-4F4B-B024-F818D7A354F6",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Professionalism: How well does the employee maintain a professional demeanor and appearance?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4214F3A8-EA56-46A2-AB2B-F586BC1C4432",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Health and Safety: How well does the employee follow health and safety guidelines, including the use of PPE and safe equipment operation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7B8C6F3D-0310-465D-B5E8-560FAAB8F4F0",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Quality of Work: How consistently does the employee maintain the quality of food preparation and presentation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "2FD81F98-393E-4886-8ADC-DDC9E016658E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Productivity: How efficiently does the employee manage their time and complete tasks within scheduled timelines?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "35BF01BE-912F-4FDA-AC07-24A4604E06F4",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Customer Service: How well does the employee interact with students, staff, and other customers?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A5F74DCC-C61B-4DA3-8282-E9A6BAB44C99",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Teamwork: How effectively does the employee collaborate and communicate with other team members?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "80B78B37-7B55-4DA7-8612-B5755A22EADC",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Initiative: Does the employee show initiative in taking on new tasks or improving processes?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "D37371CA-3A88-497F-AF69-1E4F2FA8E575",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Ann Teak",
        "EmployeeEmail": "Student9@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A477DAD1-D624-421F-8381-B114421D9B20",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Communication Skills: How effectively does the employee communicate with colleagues, supervisors, and students?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4AF99E63-259B-4E95-977E-7DA20C9865F7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Innovation: How often does the employee suggest new ideas or methods to improve the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "1",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2ABD23F-BA22-486B-8B1A-CB47163E182F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Overall Performance: How would you rate the employee\u2019s overall performance in their role within the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "2",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "202B6B59-CA79-48FB-B0D1-96A9E23810B7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attention to Detail: How thorough is the employee in ensuring accuracy in meal preparation and serving sizes?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "3",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2407B09-B942-48B7-9F94-A76781B9F7C1",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Training and Development: How actively does the employee engage in training opportunities and apply learned skills on the job?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "4",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "565ABCC7-8CE1-4CA5-A38F-59DCA3950F52",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Job Knowledge: How well does the employee understand their role and responsibilities in the school nutrition program?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "597A3243-77F4-4E7E-8765-C0D400A02FE5",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Food Safety Compliance: How effectively does the employee adhere to food safety and sanitation protocols?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "30BB4F13-A810-4843-90C4-E1F43B71E12F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Problem-Solving: How well does the employee handle unexpected challenges or issues that arise during meal service?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "C35637A3-DCA8-40A7-A2E7-66468F07C473",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attendance and Punctuality: How reliable is the employee in terms of attendance and arriving on time for shifts?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "12383504-AA67-4C4D-BD00-67E01DA6836D",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Compliance with Policies: How well does the employee adhere to school district policies and procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A137C91C-E1FE-4263-BA98-91DDDB723505",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Efficiency in Serving: How effectively does the employee manage time during meal service to ensure timely food delivery?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7CF56414-779B-4E44-9300-3AF7839D3E7E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Inventory Management: How well does the employee handle food inventory, including proper storage and rotation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "77EC5AC0-3FC3-47A3-B49E-17D4D66DA738",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Adaptability: How quickly and effectively does the employee adapt to changes in menus, schedules, or procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "57CBD739-14C3-4F4B-B024-F818D7A354F6",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Professionalism: How well does the employee maintain a professional demeanor and appearance?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4214F3A8-EA56-46A2-AB2B-F586BC1C4432",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Health and Safety: How well does the employee follow health and safety guidelines, including the use of PPE and safe equipment operation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7B8C6F3D-0310-465D-B5E8-560FAAB8F4F0",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Quality of Work: How consistently does the employee maintain the quality of food preparation and presentation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "2FD81F98-393E-4886-8ADC-DDC9E016658E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Productivity: How efficiently does the employee manage their time and complete tasks within scheduled timelines?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "35BF01BE-912F-4FDA-AC07-24A4604E06F4",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Customer Service: How well does the employee interact with students, staff, and other customers?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A5F74DCC-C61B-4DA3-8282-E9A6BAB44C99",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Teamwork: How effectively does the employee collaborate and communicate with other team members?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "80B78B37-7B55-4DA7-8612-B5755A22EADC",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Initiative: Does the employee show initiative in taking on new tasks or improving processes?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F9C1DDC1-DC22-40DF-8192-39CA46F43860",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Bill Fold",
        "EmployeeEmail": "Student8@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A477DAD1-D624-421F-8381-B114421D9B20",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Communication Skills: How effectively does the employee communicate with colleagues, supervisors, and students?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.75,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4AF99E63-259B-4E95-977E-7DA20C9865F7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Innovation: How often does the employee suggest new ideas or methods to improve the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Always",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2ABD23F-BA22-486B-8B1A-CB47163E182F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Overall Performance: How would you rate the employee\u2019s overall performance in their role within the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "great overall",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "202B6B59-CA79-48FB-B0D1-96A9E23810B7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attention to Detail: How thorough is the employee in ensuring accuracy in meal preparation and serving sizes?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "not very",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2407B09-B942-48B7-9F94-A76781B9F7C1",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Training and Development: How actively does the employee engage in training opportunities and apply learned skills on the job?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "somewhat",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "565ABCC7-8CE1-4CA5-A38F-59DCA3950F52",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Job Knowledge: How well does the employee understand their role and responsibilities in the school nutrition program?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 2.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "597A3243-77F4-4E7E-8765-C0D400A02FE5",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Food Safety Compliance: How effectively does the employee adhere to food safety and sanitation protocols?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "30BB4F13-A810-4843-90C4-E1F43B71E12F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Problem-Solving: How well does the employee handle unexpected challenges or issues that arise during meal service?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "C35637A3-DCA8-40A7-A2E7-66468F07C473",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attendance and Punctuality: How reliable is the employee in terms of attendance and arriving on time for shifts?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "12383504-AA67-4C4D-BD00-67E01DA6836D",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Compliance with Policies: How well does the employee adhere to school district policies and procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A137C91C-E1FE-4263-BA98-91DDDB723505",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Efficiency in Serving: How effectively does the employee manage time during meal service to ensure timely food delivery?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7CF56414-779B-4E44-9300-3AF7839D3E7E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Inventory Management: How well does the employee handle food inventory, including proper storage and rotation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "77EC5AC0-3FC3-47A3-B49E-17D4D66DA738",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Adaptability: How quickly and effectively does the employee adapt to changes in menus, schedules, or procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "57CBD739-14C3-4F4B-B024-F818D7A354F6",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Professionalism: How well does the employee maintain a professional demeanor and appearance?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4214F3A8-EA56-46A2-AB2B-F586BC1C4432",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Health and Safety: How well does the employee follow health and safety guidelines, including the use of PPE and safe equipment operation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7B8C6F3D-0310-465D-B5E8-560FAAB8F4F0",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Quality of Work: How consistently does the employee maintain the quality of food preparation and presentation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "2FD81F98-393E-4886-8ADC-DDC9E016658E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Productivity: How efficiently does the employee manage their time and complete tasks within scheduled timelines?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "35BF01BE-912F-4FDA-AC07-24A4604E06F4",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Customer Service: How well does the employee interact with students, staff, and other customers?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 2.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A5F74DCC-C61B-4DA3-8282-E9A6BAB44C99",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Teamwork: How effectively does the employee collaborate and communicate with other team members?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "80B78B37-7B55-4DA7-8612-B5755A22EADC",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Initiative: Does the employee show initiative in taking on new tasks or improving processes?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "F3894D0D-C6C1-4370-91A7-46C9B8085E3D",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Hemali Patel",
        "EmployeeEmail": "hemali.vidhaninfotech+12@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A477DAD1-D624-421F-8381-B114421D9B20",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Communication Skills: How effectively does the employee communicate with colleagues, supervisors, and students?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.69,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4AF99E63-259B-4E95-977E-7DA20C9865F7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Innovation: How often does the employee suggest new ideas or methods to improve the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Daily",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2ABD23F-BA22-486B-8B1A-CB47163E182F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Overall Performance: How would you rate the employee\u2019s overall performance in their role within the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Excellent",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "202B6B59-CA79-48FB-B0D1-96A9E23810B7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attention to Detail: How thorough is the employee in ensuring accuracy in meal preparation and serving sizes?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "good",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2407B09-B942-48B7-9F94-A76781B9F7C1",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Training and Development: How actively does the employee engage in training opportunities and apply learned skills on the job?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Daily",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "565ABCC7-8CE1-4CA5-A38F-59DCA3950F52",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Job Knowledge: How well does the employee understand their role and responsibilities in the school nutrition program?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "597A3243-77F4-4E7E-8765-C0D400A02FE5",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Food Safety Compliance: How effectively does the employee adhere to food safety and sanitation protocols?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "30BB4F13-A810-4843-90C4-E1F43B71E12F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Problem-Solving: How well does the employee handle unexpected challenges or issues that arise during meal service?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "C35637A3-DCA8-40A7-A2E7-66468F07C473",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attendance and Punctuality: How reliable is the employee in terms of attendance and arriving on time for shifts?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "12383504-AA67-4C4D-BD00-67E01DA6836D",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Compliance with Policies: How well does the employee adhere to school district policies and procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A137C91C-E1FE-4263-BA98-91DDDB723505",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Efficiency in Serving: How effectively does the employee manage time during meal service to ensure timely food delivery?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7CF56414-779B-4E44-9300-3AF7839D3E7E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Inventory Management: How well does the employee handle food inventory, including proper storage and rotation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "77EC5AC0-3FC3-47A3-B49E-17D4D66DA738",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Adaptability: How quickly and effectively does the employee adapt to changes in menus, schedules, or procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "57CBD739-14C3-4F4B-B024-F818D7A354F6",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Professionalism: How well does the employee maintain a professional demeanor and appearance?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4214F3A8-EA56-46A2-AB2B-F586BC1C4432",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Health and Safety: How well does the employee follow health and safety guidelines, including the use of PPE and safe equipment operation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7B8C6F3D-0310-465D-B5E8-560FAAB8F4F0",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Quality of Work: How consistently does the employee maintain the quality of food preparation and presentation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "2FD81F98-393E-4886-8ADC-DDC9E016658E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Productivity: How efficiently does the employee manage their time and complete tasks within scheduled timelines?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "35BF01BE-912F-4FDA-AC07-24A4604E06F4",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Customer Service: How well does the employee interact with students, staff, and other customers?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A5F74DCC-C61B-4DA3-8282-E9A6BAB44C99",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Teamwork: How effectively does the employee collaborate and communicate with other team members?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "80B78B37-7B55-4DA7-8612-B5755A22EADC",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Initiative: Does the employee show initiative in taking on new tasks or improving processes?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "85CED2FD-5A1C-4023-99F5-61177BFCFC3B",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Sue Flay",
        "EmployeeEmail": "thefigapp@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A477DAD1-D624-421F-8381-B114421D9B20",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Communication Skills: How effectively does the employee communicate with colleagues, supervisors, and students?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.63,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4214F3A8-EA56-46A2-AB2B-F586BC1C4432",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Health and Safety: How well does the employee follow health and safety guidelines, including the use of PPE and safe equipment operation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "30BB4F13-A810-4843-90C4-E1F43B71E12F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Problem-Solving: How well does the employee handle unexpected challenges or issues that arise during meal service?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "C35637A3-DCA8-40A7-A2E7-66468F07C473",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attendance and Punctuality: How reliable is the employee in terms of attendance and arriving on time for shifts?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4AF99E63-259B-4E95-977E-7DA20C9865F7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Innovation: How often does the employee suggest new ideas or methods to improve the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Daily",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2ABD23F-BA22-486B-8B1A-CB47163E182F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Overall Performance: How would you rate the employee\u2019s overall performance in their role within the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Great progress",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "202B6B59-CA79-48FB-B0D1-96A9E23810B7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attention to Detail: How thorough is the employee in ensuring accuracy in meal preparation and serving sizes?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "daily",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "77EC5AC0-3FC3-47A3-B49E-17D4D66DA738",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Adaptability: How quickly and effectively does the employee adapt to changes in menus, schedules, or procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "57CBD739-14C3-4F4B-B024-F818D7A354F6",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Professionalism: How well does the employee maintain a professional demeanor and appearance?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A5F74DCC-C61B-4DA3-8282-E9A6BAB44C99",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Teamwork: How effectively does the employee collaborate and communicate with other team members?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "80B78B37-7B55-4DA7-8612-B5755A22EADC",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Initiative: Does the employee show initiative in taking on new tasks or improving processes?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A477DAD1-D624-421F-8381-B114421D9B20",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Communication Skills: How effectively does the employee communicate with colleagues, supervisors, and students?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "12383504-AA67-4C4D-BD00-67E01DA6836D",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Compliance with Policies: How well does the employee adhere to school district policies and procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A137C91C-E1FE-4263-BA98-91DDDB723505",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Efficiency in Serving: How effectively does the employee manage time during meal service to ensure timely food delivery?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7CF56414-779B-4E44-9300-3AF7839D3E7E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Inventory Management: How well does the employee handle food inventory, including proper storage and rotation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2407B09-B942-48B7-9F94-A76781B9F7C1",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Training and Development: How actively does the employee engage in training opportunities and apply learned skills on the job?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "daily",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "565ABCC7-8CE1-4CA5-A38F-59DCA3950F52",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Job Knowledge: How well does the employee understand their role and responsibilities in the school nutrition program?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "597A3243-77F4-4E7E-8765-C0D400A02FE5",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Food Safety Compliance: How effectively does the employee adhere to food safety and sanitation protocols?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7B8C6F3D-0310-465D-B5E8-560FAAB8F4F0",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Quality of Work: How consistently does the employee maintain the quality of food preparation and presentation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "2FD81F98-393E-4886-8ADC-DDC9E016658E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Productivity: How efficiently does the employee manage their time and complete tasks within scheduled timelines?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "05A4DEB1-EDD4-4DEC-85D0-DD76F2DFF3A0",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "7BAFFCC4-7437-45E6-B1F2-0BF37FD25642",
        "EmployeeName": "Amanda O'Dell",
        "EmployeeEmail": "Amanda@figk12.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Alexender High School",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "35BF01BE-912F-4FDA-AC07-24A4604E06F4",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Customer Service: How well does the employee interact with students, staff, and other customers?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.81,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4AF99E63-259B-4E95-977E-7DA20C9865F7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Innovation: How often does the employee suggest new ideas or methods to improve the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Often",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2ABD23F-BA22-486B-8B1A-CB47163E182F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Overall Performance: How would you rate the employee\u2019s overall performance in their role within the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Excellent",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "202B6B59-CA79-48FB-B0D1-96A9E23810B7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attention to Detail: How thorough is the employee in ensuring accuracy in meal preparation and serving sizes?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Excellent",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2407B09-B942-48B7-9F94-A76781B9F7C1",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Training and Development: How actively does the employee engage in training opportunities and apply learned skills on the job?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "No",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "565ABCC7-8CE1-4CA5-A38F-59DCA3950F52",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Job Knowledge: How well does the employee understand their role and responsibilities in the school nutrition program?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 1.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "597A3243-77F4-4E7E-8765-C0D400A02FE5",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Food Safety Compliance: How effectively does the employee adhere to food safety and sanitation protocols?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 2.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "30BB4F13-A810-4843-90C4-E1F43B71E12F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Problem-Solving: How well does the employee handle unexpected challenges or issues that arise during meal service?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "C35637A3-DCA8-40A7-A2E7-66468F07C473",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attendance and Punctuality: How reliable is the employee in terms of attendance and arriving on time for shifts?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "12383504-AA67-4C4D-BD00-67E01DA6836D",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Compliance with Policies: How well does the employee adhere to school district policies and procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 2.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A137C91C-E1FE-4263-BA98-91DDDB723505",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Efficiency in Serving: How effectively does the employee manage time during meal service to ensure timely food delivery?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 1.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7CF56414-779B-4E44-9300-3AF7839D3E7E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Inventory Management: How well does the employee handle food inventory, including proper storage and rotation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 2.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "77EC5AC0-3FC3-47A3-B49E-17D4D66DA738",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Adaptability: How quickly and effectively does the employee adapt to changes in menus, schedules, or procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "57CBD739-14C3-4F4B-B024-F818D7A354F6",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Professionalism: How well does the employee maintain a professional demeanor and appearance?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4214F3A8-EA56-46A2-AB2B-F586BC1C4432",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Health and Safety: How well does the employee follow health and safety guidelines, including the use of PPE and safe equipment operation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7B8C6F3D-0310-465D-B5E8-560FAAB8F4F0",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Quality of Work: How consistently does the employee maintain the quality of food preparation and presentation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "2FD81F98-393E-4886-8ADC-DDC9E016658E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Productivity: How efficiently does the employee manage their time and complete tasks within scheduled timelines?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "35BF01BE-912F-4FDA-AC07-24A4604E06F4",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Customer Service: How well does the employee interact with students, staff, and other customers?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A5F74DCC-C61B-4DA3-8282-E9A6BAB44C99",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Teamwork: How effectively does the employee collaborate and communicate with other team members?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "80B78B37-7B55-4DA7-8612-B5755A22EADC",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Initiative: Does the employee show initiative in taking on new tasks or improving processes?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "3787DB4A-6011-4E70-9337-E18F673DD451",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "FFC3B7BC-2F00-4FB4-BFB6-74DA2E255B75",
        "EmployeeName": "Patty Melt",
        "EmployeeEmail": "student14@thefigapp.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Swan Elementary",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A477DAD1-D624-421F-8381-B114421D9B20",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Communication Skills: How effectively does the employee communicate with colleagues, supervisors, and students?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 3.25,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4AF99E63-259B-4E95-977E-7DA20C9865F7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Innovation: How often does the employee suggest new ideas or methods to improve the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "every day!",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2ABD23F-BA22-486B-8B1A-CB47163E182F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Overall Performance: How would you rate the employee\u2019s overall performance in their role within the school nutrition program?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Excellent",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "202B6B59-CA79-48FB-B0D1-96A9E23810B7",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attention to Detail: How thorough is the employee in ensuring accuracy in meal preparation and serving sizes?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "little",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "E2407B09-B942-48B7-9F94-A76781B9F7C1",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Training and Development: How actively does the employee engage in training opportunities and apply learned skills on the job?",
        "QuestionType": "Text",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": "Daily",
        "EmployeeTrueFalse": null,
        "EmployeeRating": null,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "565ABCC7-8CE1-4CA5-A38F-59DCA3950F52",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Job Knowledge: How well does the employee understand their role and responsibilities in the school nutrition program?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 3.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "597A3243-77F4-4E7E-8765-C0D400A02FE5",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Food Safety Compliance: How effectively does the employee adhere to food safety and sanitation protocols?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "30BB4F13-A810-4843-90C4-E1F43B71E12F",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Problem-Solving: How well does the employee handle unexpected challenges or issues that arise during meal service?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "C35637A3-DCA8-40A7-A2E7-66468F07C473",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Attendance and Punctuality: How reliable is the employee in terms of attendance and arriving on time for shifts?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "12383504-AA67-4C4D-BD00-67E01DA6836D",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Compliance with Policies: How well does the employee adhere to school district policies and procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A137C91C-E1FE-4263-BA98-91DDDB723505",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Efficiency in Serving: How effectively does the employee manage time during meal service to ensure timely food delivery?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7CF56414-779B-4E44-9300-3AF7839D3E7E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Inventory Management: How well does the employee handle food inventory, including proper storage and rotation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "77EC5AC0-3FC3-47A3-B49E-17D4D66DA738",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Adaptability: How quickly and effectively does the employee adapt to changes in menus, schedules, or procedures?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "57CBD739-14C3-4F4B-B024-F818D7A354F6",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Professionalism: How well does the employee maintain a professional demeanor and appearance?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "4214F3A8-EA56-46A2-AB2B-F586BC1C4432",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Health and Safety: How well does the employee follow health and safety guidelines, including the use of PPE and safe equipment operation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 1.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "7B8C6F3D-0310-465D-B5E8-560FAAB8F4F0",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Quality of Work: How consistently does the employee maintain the quality of food preparation and presentation?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "2FD81F98-393E-4886-8ADC-DDC9E016658E",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Productivity: How efficiently does the employee manage their time and complete tasks within scheduled timelines?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "35BF01BE-912F-4FDA-AC07-24A4604E06F4",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Customer Service: How well does the employee interact with students, staff, and other customers?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 4.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A5F74DCC-C61B-4DA3-8282-E9A6BAB44C99",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Teamwork: How effectively does the employee collaborate and communicate with other team members?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "80B78B37-7B55-4DA7-8612-B5755A22EADC",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Initiative: Does the employee show initiative in taking on new tasks or improving processes?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    },
    {
        "UserId": "EAA07B80-452A-46F4-844D-FE46C6ABF463",
        "DistrictId": "E0C7E202-B9F0-4751-AAC2-FEB58A832754",
        "SchoolId": "C7FBB711-828F-4969-8CFF-5914F1329F9C",
        "EmployeeName": "Ida Boss",
        "EmployeeEmail": "beckiswan425@gmail.com",
        "DistrictName": "Swan Schools",
        "SiteName": "Central Office",
        "EvalutionSetupId": "B97AC486-334E-4F7C-84C8-10ACA633575C",
        "EvalutionSetupTransactionId": "A477DAD1-D624-421F-8381-B114421D9B20",
        "DateOfReview": "01/01/2025",
        "QuestionTitle": "Communication Skills: How effectively does the employee communicate with colleagues, supervisors, and students?",
        "QuestionType": "Rating",
        "EmployeeAnswer": null,
        "EmployeeTextAnswer": null,
        "EmployeeTrueFalse": null,
        "EmployeeRating": 5.0,
        "EmployeeScore": null,
        "AverageRatingForEval": 4.31,
        "AverageScoreForEval": null
    }
]; export default vw_EmployeeEvaluationDetailedReport_data;
