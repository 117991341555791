import React, { Suspense, lazy, useState, useEffect } from "react";
import "./index.scss";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import userimg2 from "../../../images/user.png";
import cardimg1 from "../../../images/fig_CROP.png";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import Config from "../../../Utils/Config";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Tab, Box } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { PostCallApi } from "../../../Action/Action";
import Swal from "sweetalert2";
import NewListTable from "../../Reuseable_Components/NewListTable";
import ImgPopup from "../Settings/Img_Popup";
import star_full from "../../../images/star_full.png";
import star_blank from "../../../images/star_blank.png";
import Rating from "react-rating";
import active_list from "../../../images/active_list.png";
import inactive_list from "../../../images/inactive_list.png";
import active_grid from "../../../images/active_grid.png";
import inactive_grid from "../../../images/inactive_grid.png";
import { FaEye } from "react-icons/fa";
import CeuCoursePopup from "../../Reuseable_Components/CeuCoursePopup/CeuCoursePopup";
import { fetchData } from "../../../Action/NewAction";

const CEUCard = lazy(() => import("./Card"));
const renderLoader = () => <p></p>;
const headCellsCourse = [
  {
    id: "no",
    label: "#",
  },
  {
    id: "courseImg",
    label: "Course Image",
  },
  {
    id: "cardTitle",
    label: "Course Name",
  },
  {
    id: "courseCeu",
    label: "CEU",
  },
  {
    id: "userName",
    label: "Offered By",
  },
  {
    id: "startCompleted",
    label: "Status",
  },
  {
    id: "cate",
    label: "Key Area",
    widthFix: 600,
  },
  {
    id: "totalLesson",
    label: "Total Lesson",
  },
  {
    id: "perCompleted",
    label: "Complete Percentage",
  },
  {
    id: "rateTable",
    label: "Ratings",
  },
  {
    id: "des",
    label: "Description",
    widthFix: 600,
  },
];
function CEU_Cources() {
  const urlParams = new URLSearchParams(window.location.search);
  const searchParam = urlParams.get('search');
  const decodedSearch = decodeURIComponent(searchParam);

  const [open, setOpen] = useState(false);
  const [ceu_select, setCeu_select] = useState();
  const [ceu_Lear, setCeu_Lear] = useState();
  const [ceu_Trai, setCeu_Trai] = useState();
  const [ceu_Instructor, setCeu_Instructor] = useState();
  const [ceuVal, setCeuVal] = useState();
  const [searchFieldCou, setSearchFieldCou] = useState("");
  const LoginDetails = useSelector((state) => state.login?.LoginDetails);
  const [courseCodeList, setCourseCodeList] = useState([]);
  const [courseLearList, setCourseLearList] = useState([]);
  const [courseTraiList, setCourseTraiList] = useState([]);
  const [insList, setInsList] = useState([]);
  const [ceuCardData, setCeuCardData] = useState([]);
  const [grid, setGrid] = useState(true);

  const [schList, setSchList] = useState([]);
  const [schSelect, setSchSelect] = useState();
  const [values, setValues] = React.useState("1");
  
  const handleChangeTab = (event, newValue) => {
    setValues(newValue);
  };
  let nav = useNavigate();
  const HandleShowCouceCard = (e) => {
    nav(`/ceu-courses/${e}`, { replace: true });
  };
  const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const DashBtnList = useSelector((state) => state?.setting?.DashBtnList);
  const LogUserd = useSelector((state) => state.login?.LoginDetails);
  const headers = {
    Authorization: `Bearer ${LoginDetails?.accessToken}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const { register } = useForm();
  useEffect(() => {
    getSchoolList();
    GetInstructore();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (decodedSearch) {

      getCategory(decodedSearch);
    } else {
      getCategory();

    }

  }, [decodedSearch])
  const getCourseLists = async (
    keyAr,
    lear,
    trai,
    inst,
    ceu,
    couLabel,
    schVal
  ) => {
    let CourseDetails = {
      keyArea: keyAr?.value ? keyAr?.value : null,
      learningObjective: lear?.value ? lear?.value : null,
      trainingTopics: trai?.value ? trai?.value : null,
      courseCeu: ceu ? parseFloat(ceu) : null,
      instructorId: inst?.value ? inst?.value : null,
      courseTypes: "1",
      LogUserId: LogUserId,
      courseLable:
        couLabel === "1"
          ? "FigCEUCourses"
          : couLabel === "2"
            ? "PartnerContent"
            : couLabel === "4"
              ? "AdminReview"
              : couLabel === "5"
                ? "GovernmentContent"
                : couLabel === "6"
                  ? "FIGFaves"
                  : "DistrictContent",
      schoolDistrictId:
        couLabel === "1" ||
          couLabel === "2" ||
          couLabel === "4" ||
          couLabel === "5" ||
          couLabel === "6"
          ? "00000000-0000-0000-0000-000000000000"
          : schVal?.value === "True"
            ? "00000000-0000-0000-0000-000000000000"
            : schVal?.value,
      showAllDistricts: schVal?.value === "True" ? true : false,
    };
    setOpen(true);
    // axios
    //   .post(
    //     `${Config.API_HOST_URL_live}/api/Course/GetCoursesByFilters`,
    //     // `${Config.API_HOST_URL_live}/api/AppCourse/GetCeuCourses?userId=${LogUserId}`,
    //     CourseDetails,
    //     { headers }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {
    //       setOpen(false);

    let seData = {
      url: `/api/Course/GetCoursesByFilters`,
      body: CourseDetails,
      // headers: headers,
    };
    setCeuCardData([]);

    let response = await PostCallApi(seData);
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        let data = response.data.payload?.map((item, i) => {
          return {
            ...item,
            no: i + 1,
            id: item?.courseId,
            cardTitle: item?.courseTitle,
            courseCeu:
              item?.courseCeu === null || item?.courseCeu === undefined
                ? ""
                : item?.courseCeu,
            des: item?.description,
            cate: item?.categories,
            courseImg: (
              <ImgPopup
                img={
                  item?.thumbnailCourseUrl
                    ? `${Config.API_HOST_URL_live}${item?.thumbnailCourseUrl}`
                    : cardimg1
                }
              />
            ),
            rateTable: (
              <Rating
                emptySymbol={<img src={star_blank} alt="rating_blank" />}
                fullSymbol={<img src={star_full} alt="rating-full" />}
                initialRating={item.ratings ? item.ratings : "0"}
                readonly
              />
            ),
            cardimg:
              item?.imageOrVideoUrl === "" || item?.imageOrVideoUrl === null
                ? cardimg1
                : `${Config.API_HOST_URL_live}${item?.imageOrVideoUrl}`,

            startCompleted: item?.status === null ? "" : item?.status,
            lesson:
              item?.courseLessionData?.length > 0
                ? item?.courseLessionData?.length + " Lessons"
                : "0 Lessons",
            totalLesson:
              item?.courseLessionData?.length > 0
                ? item?.courseLessionData?.length
                : 0,
            perCompleted: item?.percentageComplete
              ? item?.percentageComplete
              : "0",
            rating: item?.ratings ? item.ratings : "0",
            userimg:
              item?.instructor === null ||
                item?.instructor?.thumbnailProfileUrl === "" ||
                item?.instructor?.thumbnailProfileUrl === null
                ? userimg2
                : `${Config.API_HOST_URL_live}${item.instructor?.thumbnailProfileUrl}`,
            userName:
              item?.instructor === null ||
                item?.instructor?.fistName === "" ||
                item?.instructor?.fistName === null
                ? ""
                : item?.instructor?.firstName +
                " " +
                item?.instructor?.lastName,
            stepActive:
              item?.instructor === null ||
                item?.instructor?.siteLocation === null ||
                item?.instructor?.siteLocation === ""
                ? "-"
                : item?.instructor?.siteLocation,
            Action: (
              <>
                {item?.status === "In Progress" ||
                  item?.status === "Scheduled" ||
                  item?.status === "" ||
                  item?.status === null ? (
                  <div className="d-flex mt-auto">
                    <Button
                      className="theme_btn"
                      onClick={() => startCourse(item?.courseId)}
                      style={{ marginRight: "10px" }}
                    >
                      Start Course
                    </Button>
                    {LoginDetails?.roleId === 2 && values === "4"
                      ? ""
                      : DashBtnList?.map((check, i) => {
                        if (
                          check?.menuName ===
                          "Assign Course Button On Dashboard" &&
                          check?.isAssigned === true
                        ) {
                          return (
                            <div
                              className="grid_top_button_group theme_btn ml-2"
                              key={i}
                            >
                              <CeuCoursePopup
                                btnName="Assign Course"
                                courseId={item?.courseId}
                              />
                            </div>
                          );
                        }
                        return "";
                      })}
                  </div>
                ) : item?.status === "Completed" ? (
                  <div className="d-flex mt-auto">
                    <Link
                      className="orange_btn"
                      to={`/ceu-courses/complete-course/${item?.courseId}`}
                      style={{
                        marginRight: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Start Again
                    </Link>
                    {LoginDetails?.roleId === 2 && values === "4"
                      ? ""
                      : DashBtnList?.map((check, i) => {
                        if (
                          check?.menuName ===
                          "Assign Course Button On Dashboard" &&
                          check?.isAssigned === true
                        ) {
                          return (
                            <div
                              className="grid_top_button_group theme_btn ml-2"
                              key={i}
                            >
                              <CeuCoursePopup
                                btnName="Assign Course"
                                courseId={item?.courseId}
                              />
                            </div>
                          );
                        }
                        return "";
                      })}
                  </div>
                ) : (
                  ""
                )}
                <Link
                  className="theme_btn ms-2  px-2"
                  to={`/ceu-courses/${item?.courseId}`}
                >
                  <FaEye />
                </Link>
              </>
            ),
          };
        });
        setCeuCardData(data);
      } else {
        setCeuCardData([]);
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }

    // } else {
    //   setCeuCardData([]);
    //   setOpen(false);
    // }
    // })
    // .catch((e) => {
    //   setOpen(false);
    //   console.log(e);
    // });
  };

  useEffect(() => {
    getCourseLists(
      ceu_select,
      ceu_Lear,
      ceu_Trai,
      ceu_Instructor,
      ceuVal,
      values,
      schSelect
    );
    // eslint-disable-next-line
  }, [ceu_select, ceu_Lear, ceu_Trai, ceu_Instructor, ceuVal, values]);
  useEffect(() => {
    if (values === "3") {
      getCourseLists(
        ceu_select,
        ceu_Lear,
        ceu_Trai,
        ceu_Instructor,
        ceuVal,
        values,
        schSelect
      );
    }
    // eslint-disable-next-line
  }, [schSelect]);

  const getCategory = async (search) => {

    let { data, error } = await fetchData(`/api/CourseCategory/GetCategoryList?createdBy=${LogUserId}`);
    if (data?.success) {
      const listdata = data;
      const main = listdata.mainCategory?.map((item) => {
        return {
          ...item,
          value: item?.courseCategoryId,
          label:
            item?.courseCategoryCode + " - " + item?.courseCategoryName,
        };
      });
      const lear = listdata.learningObjective?.map((item) => {
        return {
          value: item?.courseCategoryId,
          label:
            item?.courseCategoryCode + " - " + item?.courseCategoryName,
        };
      });
      const trai = listdata.trainingTopic?.map((item) => {
        return {
          value: item?.courseCategoryId,
          label:
            item?.courseCategoryCode + " - " + item?.courseCategoryName,
        };
      });
      setCourseCodeList(main);
      if (search) {
        let findName = main.find(x => x?.courseCategoryName?.toLowerCase() === search?.toLowerCase())
        if (findName) {
          setTimeout(() => {
            setCeu_select(findName)
          }, 200);
        }
      }
      setCourseLearList(lear);
      setCourseTraiList(trai);
    }

  };
  
  const GetInstructore = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/Course/GetInstructore?createdBy=${LogUserId}`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          const listdata = response.data?.payload;
          const main = listdata?.map((item) => {
            return {
              value: item?.userId,
              label: item?.firstName + " " + item?.lastName,
            };
          });
          setInsList(main);
        }
      });
  };
  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const dataOtherDistrict = response?.data.payload?.map((item) => {
            return {
              value: item?.schoolDistrictId,
              label: item?.districtName,
            };
          });
          if (dataOtherDistrict?.length > 1) {
            let distList = [
              { value: "True", label: "All District" },
              ...dataOtherDistrict,
            ];
            setSchList(distList);
          } else {
            setSchList(dataOtherDistrict);
          }
          let schs = dataOtherDistrict?.filter(
            (x) => x.value === LogUserd?.schoolDistrictId
          )[0];
          setSchSelect(schs);
        }
      })
      .catch((error) => console.log(error));
  };

  const startCourse = async (id) => {
    let stCou = {
      courseId: id,
      userId: LogUserId,
      status: "In Progress",
    };
    setOpen(true);
    let response = await PostCallApi({
      url: `/api/UserCourse/StartUserCourse`,
      body: stCou,
      headers: headers,
    });
    setOpen(false);
    if (response.data.success) {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response.data?.message,
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });

      nav(`/ceu-courses/start-course/${id}`, { replace: true });

      // nav(`/fig-lifestyle/start-course/${id}`, {
      //   replace: true,
      // });
    }
  };

  const [inputSearchParam] = useState(["cardTitle", "userName", "numericCode"]);
  const filterCourse = ceuCardData?.filter((user) => {
    if (searchFieldCou) {
      return inputSearchParam?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchFieldCou.toLowerCase()) > -1
        );
      });
    } else return user;
  });
  const { key } = useParams();
  useEffect(() => {
    if (key) {
      setCeu_select(key);
    }
    // eslint-disable-next-line
  }, [key]);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="ceu_cource_page">
          <div className="container-fluid">
            <div className="main_wrapper">
              <TabContext value={values}>
                <div className="d-flex justify-content-between main_title_and_tab mb-3 align-items-center">
                  <div className="ceu_couce_title">
                    Professional Development
                  </div>
                  <Box className="tab_group ">
                    <TabList
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                    >
                      {LogUserd?.roleId === 1 || LogUserd?.roleId === 2 ? (
                        <Tab label="Admin Only" value="4" className="tab_1" />
                      ) : (
                        ""
                      )}
                      <Tab
                        label="FIG Content"
                        value="1"
                        className={`${LogUserd?.roleId === 1 || LogUserd?.roleId === 2
                          ? "tab_3"
                          : "tab_1"
                          }`}
                      />
                      <Tab
                        label="Partner Content"
                        value="2"
                        className="tab_3"
                      />
                      <Tab
                        label="District Content"
                        value="3"
                        className="tab_2"
                      />{" "}
                      <Tab
                        label="Government Content"
                        value="5"
                        className="tab_2"
                      />
                      <Tab label="FIG Faves" value="6" className="tab_5" />
                    </TabList>
                  </Box>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="ceu_wrapper">
                      <div className="ceu_select_dropdown ">
                        <div className="title d-flex">
                          <span>Search Courses</span>
                          {values === "3" ? (
                            <div className="search_control ms-auto w-25">
                              <Autocomplete
                                disablePortal
                                options={schList}
                                className="me-3 search"
                                sx={{ width: 200 }}
                                value={schSelect?.label}
                                onChange={(e, newValue) => {
                                  if (newValue) {
                                    setSchSelect(newValue);
                                  } else {
                                    setSchSelect(schList[0]);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="District"
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="search_box ms-auto me-3">
                            <input
                              type="text"
                              className="form-control search_text"
                              placeholder="Search By Name"
                              onChange={(e) =>
                                setSearchFieldCou(e.target.value)
                              }
                            />
                            <img src={search_icon} alt="" />
                            {/* <PersonSearchIcon className="icon" /> */}
                          </div>
                          <div className="rightside_action">
                            <Button
                              className="grid_btn"
                              onClick={() => setGrid(true)}
                            >
                              <img
                                src={
                                  grid === true ? active_grid : inactive_grid
                                }
                                className="img-fluid"
                                alt="grid"
                              />
                            </Button>
                            <Button
                              className="list_btn"
                              onClick={() => setGrid(false)}
                            >
                              <img
                                src={
                                  grid === false ? active_list : inactive_list
                                }
                                className="img-fluid"
                                alt="grid"
                              />
                            </Button>
                          </div>
                        </div>
                        <div className="search_control mt-3">

                          <Autocomplete
                            disablePortal
                            options={courseCodeList}
                            className="me-3 search"
                            sx={{ width: 200 }}
                            // defaultValue={courseCodeList?.find((x)=> x?.courseCategoryName===search)?.label}
                            value={ceu_select ? ceu_select.label:""}
                            onChange={(e, newValue) => {
                              setCeu_select(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Key Area" />
                            )}
                          />
                          <Autocomplete
                            disablePortal
                            options={courseLearList}
                            className="me-3 search"
                            sx={{ width: 200 }}
                            value={ceu_Lear?.label}
                            onChange={(e, newValue) => {
                              setCeu_Lear(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Learning Objective"
                              />
                            )}
                          />
                          <Autocomplete
                            disablePortal
                            options={courseTraiList}
                            className="me-3 search"
                            sx={{ width: 200 }}
                            value={ceu_Trai?.label}
                            onChange={(e, newValue) => {
                              setCeu_Trai(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Training Topic"
                              />
                            )}
                          />
                          <Autocomplete
                            disablePortal
                            options={insList}
                            className="me-3 search"
                            sx={{ width: 200 }}
                            value={ceu_Instructor?.label}
                            onChange={(e, newValue) => {
                              setCeu_Instructor(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Instructor" />
                            )}
                          />
                          <TextField
                            className="search w-50"
                            sx={{ width: 70 }}
                            placeholder="Ceu"
                            {...register("requiredCeu", {
                              pattern: {
                                // value: /^\d+(\.\d{1,2})?$/,
                                value: /^[0-9+-]+$/,
                              },
                              onChange: (e) => setCeuVal(e.target.value),
                            })}
                          />
                        </div>
                      </div>
                      <div className="new_courcetab">
                        <div className="row ceu_card_main_box mt-4">
                          {grid ? (
                            <CEUCard
                              grid={true}
                              data={filterCourse}
                              onClick={HandleShowCouceCard}
                              tabValue={values}
                            />
                          ) : (
                            <NewListTable
                              headCells={headCellsCourse}
                              mainfirstShow={false}
                              ListData={filterCourse}
                              action={true}
                              actionFirst={true}
                              showUserListInTable={true}
                            />
                          )}
                        </div>
                      </div>
                      {/* <TabPanel value="2" className="new_courcetab">
                        <div className="row ceu_card_main_box mt-4">
                          <CEUCard
                            grid={true}
                            data={filterCourse}
                            onClick={HandleShowCouceCard}
                          />
                        </div>
                      </TabPanel> */}
                    </div>
                  </div>
                </div>
              </TabContext>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(CEU_Cources);
