import Swal from "sweetalert2";

export const SwalSuccess = (mes) => {
  return Swal.fire({
    position: "top-end",
    width: 400,
    // icon: "success",
    text: mes ? mes : "Something went wrong please try again later.",
    showConfirmButton: false,
    timer: 1500,
    color: "#ffffff",
    background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutRight",
    },
  });
};

export const SwalError = (mes) => {
  return Swal.fire({
    position: "top-end",
    width: 400,
    // icon: "success",
    text: mes ? mes : "Something went wrong please try again later.",
    showConfirmButton: false,
    timer: 1500,
    color: "#ffffff",
    background: "red",
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutRight",
    },
  });
};

export const SwalMessage = (mes, timer) => {
  let timerInterval;
  if (timer) {
    return Swal.fire({
      position: "center",
      width: 400,
      // titleText: `<small>${mes}</small>`,
      html: `${mes} <br /> You will be redirected to main page in <b></b> seconds.`,
      confirmButtonColor: "#808080",
      showConfirmButton: true,
      confirmButtonText: "OK",
      allowEscapeKey: false,
      allowOutsideClick: false,
      // timer: 0,
      timer: timer,
      didOpen: () => {
        // Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          timer.textContent = `${Math.ceil(Swal.getTimerLeft() / 1000)}`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
  }
  return Swal.fire({
    position: "center",
    width: 400,
    text: mes,
    confirmButtonColor: "#808080",
    showConfirmButton: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
};
