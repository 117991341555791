import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import Swal from "sweetalert2";
import Config from "../../../../Utils/Config";
import { Backdrop, CircularProgress } from "@mui/material";
import "./main.scss";
import PropTypes from "prop-types";
import {
  Box,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { GetCallApi } from "../../../../Action/Action";
import active_img from "../../../../images/Icon/active_green.svg";
import pro_img from "../../../../images/default_profile.jpg";
import { useSignalR } from "../../../../Utils/SignalRContext";

function UpdateUserSite(props) {
  const [open, setOpen] = useState(false);
  const [derror, setDerror] = useState();
  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const [schList, setSchList] = useState();
  const [selectUser, setSelectUser] = useState([]);
  const [siteList, setSiteList] = useState([]);

  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  // const LogUserde = useSelector((state) => state.login.LoginDetails);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = (data) => {
    const user = selectUser?.filter((item) => {
      if (item.add === true) {
        return item.userId;
      }
      return 0;
    });
    const acc = {
      userId: user?.map((item) => item.userId),
      schoolDistrictId: data?.schoolDistrictId,
      schoolId: data?.schoolId,
      loginUserId: LogUserId,
    };
    setOpen(true);
    let url = `${Config.API_HOST_URL_live}/api/User/BulkUpdateSchools`;
    axios.post(url, acc, { headers }).then((response) => {
      if (response.data.success) {
        setOpen(false);
        setSelectUser([]);
        reset();
        function titleCase(str) {
          var splitStr = str?.toLowerCase().split(" ");
          for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] =
              splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
          }
          return splitStr.join(" ");
        }
        setCheckBoxVal(false);
        reset();
        setDerror();
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(response.data.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        setDerror({ err_msg: response.data.message });
        setOpen(false);
      }
    });
  };
  useEffect(() => {
    getSchoolList();
    // eslint-disable-next-line
  }, []);
  const { connection } = useSignalR();
  useEffect(() => {
    if (connection) {
      connection?.on("UserStatusChanged", (userid, status) => {
        setSelectUser((old) => {
          return old?.map((x) => {
            if (x?.userId === userid) {
              return {
                ...x,
                userStatus: status ? "Online" : "Offline",
              };
            }
            return x;
          });
        });
      });
    }
  }, [connection?.state]);
  const GetUserList = async (sch) => {
    if (sch) {
      setOpen(true);
      // let url = `${Config.API_HOST_URL_live}/api/User/GetUserBySchoolDistrictId?schoolDistrictId=${sch}`;
      // axios.get(url, { headers }).then((response) => {
      let seData = {
        url: `/api/User/GetUserBySchoolDistrictId?schoolDistrictId=${sch}&userId=${LogUserId}`,
        headers: headers,
      };
      const response = await GetCallApi(seData);
      setOpen(false);
      if (response?.status === 200) {
        if (response.data.success) {
          setCheckBoxVal(false);
          const data = response?.data.payload?.map((item, i) => {
            return {
              no: i,
              add: false,
              userId: item?.userId,
              col0: item?.firstName + " " + item?.lastName,
              col1: item?.roleName,
              col2: item?.jobTitleName,
              col3: item?.siteLocation,
              col4: item?.manager?.map((x, n) => {
                return (
                  <>
                    {x.managerName}
                    {item?.manager?.length - 1 === n ? "" : ", "}
                  </>
                );
              }),
              userStatus: item?.userStatus,
              profileUrl: item?.profileUrl,
            };
          });
          setSelectUser(data);
        } else {
          setOpen(false);
          setSelectUser([]);
        }
      } else {
        setOpen(false);
        setSelectUser([]);
      }
      // });
    } else {
      setCheckBoxVal(false);
      setSelectUser([]);
    }
  };
  const getSL = (id) => {
    if (id) {
      let url = `${Config.API_HOST_URL_live}/api/SiteLocation/GetSiteLocationBySchoolDistrictId?schoolDistrictId=${id}`;
      axios
        .get(`${url}`, { headers })
        .then((response) => {
          if (response.data.success) {
            const data = response?.data.payload?.map((item) => {
              return {
                schoolId: item?.schoolId,
                schoolName: item?.schoolName,
              };
            });
            setSiteList(data);
          }
        })
        .catch((error) => console.log(error));
    } else {
      setSiteList();
    }
  };
  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              schId: item?.schoolDistrictId,
              schName: item?.districtName,
            };
          });
          setSchList(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleAll = (val) => {
    const s = selectUser?.map((item) => {
      return {
        no: item.no,
        add: val,
        userId: item?.userId,
        col0: item?.col0,
        col1: item?.col1,
        col2: item?.col2,
        col3: item?.col3,
        col4: item?.col4,
      };
    });
    setSelectUser(s);
  };
  const handleMainCheck = (e, index, item) => {
    let allMainCh = true;

    const s = selectUser?.filter((se, i) => se.no !== index);

    let select = {
      no: index,
      add: e,
      userId: item?.userId,
      col0: item?.col0,
      col1: item?.col1,
      col2: item?.col2,
      col3: item?.col3,
      col4: item?.col4,
    };

    let arr = s;
    arr.splice(index, 0, select);
    setSelectUser(arr);
    if (e) {
      arr?.map((as, i) => {
        if (as.add === false) {
          allMainCh = false;
        }
        return 0;
      });

      if (allMainCh) {
        setCheckBoxVal(true);
      } else {
        setCheckBoxVal(false);
      }
    } else {
      setCheckBoxVal(false);
    }
  };
  const columns = [
    { field: "col0", headerName: "User Name" },
    { field: "col1", headerName: "Role Name" },
    { field: "col2", headerName: "Job Title" },
    { field: "col3", headerName: "Site/Location" },
    { field: "col4", headerName: "Reporting Manager" },
  ];
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F1F6FC",
    },
    fontFamily: `"Poppins", sans-serif`,
  }));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("col0");
  function descendingComparator(a, b, orderBy) {
    if (
      b[orderBy]?.toString()?.toLowerCase() <
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString()?.toLowerCase() >
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);

    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell>
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                disabled={selectUser?.length === 0 ? true : false}
                checked={checkBoxVal}
                onChange={(e) => {
                  handleAll(e.target.checked);
                  setCheckBoxVal(e.target.checked);
                }}
              />
              <span className="checkmark"></span>
            </label>
            Add
          </StyledTableCell>
          {columns?.map((headCell) => {
            // if (headCell.field === "col") {
            //   return (
            //   );
            // } else {
            return (
              <StyledTableCell
                key={headCell?.field}
                align="left"
                sortDirection={orderBy === headCell?.field ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell?.field}
                  direction={orderBy === headCell?.field ? order : "asc"}
                  onClick={createSortHandler(headCell?.field)}
                >
                  {headCell.headerName}
                  {orderBy === headCell?.field ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            );
            // }
          })}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  const handleSch = (e) => {
    GetUserList(e);
    getSL(e);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="create_hed">
        <h3>Assign Site/Location</h3>
      </div>
      <hr />
      <form className="create_school_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group access_role_select_box ">
              <label className="form-label">School District Name</label>
              <select
                className={`form-select `}
                aria-label="Default select example"
                {...register("schoolDistrictId", {
                  required: "This field is required",
                  onChange: (e) => handleSch(e.target.value),
                })}
              >
                <option value=""> Select School District Name</option>
                {schList &&
                  schList?.map((data, i) => (
                    <option value={data?.schId} key={i}>
                      {data?.schName}
                    </option>
                  ))}
              </select>
              {errors?.schoolDistrictId && (
                <span role="alert" className="error_text">
                  {errors?.schoolDistrictId.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group access_role_select_box ">
              <label className="form-label">Site/Location</label>
              <select
                className={`form-select `}
                aria-label="Default select example"
                {...register("schoolId", {
                  required: "This field is required",
                })}
              >
                <option value=""> Select Site/Location</option>
                {siteList &&
                  siteList?.map((data, i) => (
                    <option value={data?.schoolId} key={i}>
                      {data?.schoolName}
                    </option>
                  ))}
              </select>
              {errors?.schoolId && (
                <span role="alert" className="error_text">
                  {errors?.schoolId.message}
                </span>
              )}
            </div>
          </div>
          <div className="table-reponsive table-box">
            <Table className="table-striped">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {stableSort(selectUser, getComparator(order, orderBy))?.map(
                  (item, index) => {
                    return (
                      <tr key={item?.no}>
                        <StyledTableCell className="column_data">
                          <label className="personal_detail_label">
                            <input
                              type="checkbox"
                              className="login_check"
                              checked={item.add}
                              onChange={(e) => {
                                handleMainCheck(
                                  e.target.checked,
                                  item.no,
                                  item
                                );
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </StyledTableCell>
                        {columns?.map((column) => {
                          const value = item[column?.field];

                          return (
                            <StyledTableCell
                              className="column_data"
                              key={column?.field}
                            >
                              {/* {column?.field === "col0" ? (
                                <div className="d-flex  align-items-center gap-2">
                                  <div className="img_boxs">
                                    <div className="user_img">
                                      <img
                                        src={`${
                                          item?.profileUrl
                                            ? Config?.API_HOST_URL_live +
                                              item?.profileUrl
                                            : pro_img
                                        }`}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    <img
                                      src={
                                        item?.userStatus === "Online"
                                          ? active_img
                                          : ""
                                      }
                                      className="img-fluid dot_ac_in"
                                    />
                                  </div>
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                                </div>
                              ) : column?.format &&
                                typeof value === "number" ? (
                                column?.format(value)
                              ) : (
                                value
                              )} */}
                              {column?.format && typeof value === "number"
                                ? column?.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                      </tr>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>
          {derror?.err_msg && (
            <span role="alert" className="error_text">
              {derror.err_msg}
            </span>
          )}
          <div className="form-group d-flex border-0 px-3">
            <Button
              className="cource_reschedule_button btn_space me-3"
              type="submit"
            >
              Submit
            </Button>
            <Button
              className="cancel_btn cource_reschedule_button btn_space"
              onClick={() => {
                setCheckBoxVal(false);
                reset();
                setDerror();
                setSelectUser([]);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default React.memo(UpdateUserSite);
