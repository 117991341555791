import axios from "axios";
import Config from "./Config";
import store, { persistor } from "./../store/index";

// const token = store.getState().login.LoginDetails.accessToken; // Retrieve token from storage

const Axios = axios.create({
  baseURL: `${Config.API_HOST_URL_live}`,
  // headers: {
  //   Authorization: `Bearer ${token}`,
  // },
});
// axios.defaults.headers.common["Authorization"] = `bearer ${access_token}`;
//             delete axios.defaults.headers.common["Authorization"];

Axios.interceptors.request.use(
  (config) => {
    const token = store.getState().login.LoginDetails.accessToken; // Retrieve token from storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // config.headers["Content-Type"] = `application/json`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  response => response,
  error => {

    if (error.response && error.response.status === 401) {
      // Dispatch reset action to clear Redux store
      store.dispatch({ type: "LOGOUT" });
      persistor.purge();
      // Optionally remove the token from headers (not necessary for your case)
      // delete api.defaults.headers.common['Authorization'];

      // Redirect to the login page
      // window.location.href = '/login'; // Adjust the path to your login route
    }
    return Promise.reject(error);
  }
);

export default Axios;
