import React, { useEffect } from "react";
import ReuseTable from "../../Reuseable_Components/ReuseTable/ReuseTable";
import { GetCallApi } from "../../../Action/Action";
import { useSelector } from "react-redux";
import { SwalError } from "../../../components/SwalToast";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import EmployeeReview from "./index";

const HeadEmpReview = [
  { id: "userName", label: "User Name" },
  { id: "evalutionGroupName", label: "Evalution Group Name" },
  { id: "setupTitle", label: "Setup Title" },
  { id: "status", label: "Status" },
  { id: "setupCreatedDate", label: "Completion Date" },
  { id: "action", label: "Action", pdfDownload: false },
];

function EmployeeReviewList() {
  const [reviewList, setReviewList] = React.useState([]);
  const [selectEvalution, setSelectEvalution] = React.useState();
  const LogDetails = useSelector((state) => state.login?.LoginDetails);
  useEffect(() => {
    getReviewList();
  }, []);
  const getReviewList = async () => {
    setReviewList([]);
    try {
      const res = await GetCallApi({
        url: `/api/EvalutionEmployee/GetEvalutionEmployeeMyData?LoginUserId=${LogDetails.userId}`,
      });
      // const res = await GetCallApi({
      //   url: `/api/EvalutionEmployee/GetEvalutionEmployeeMyData?LoginUserId=76bad691-8b58-4339-a8aa-b4a1a49ca9fc`,
      // });
      if (res?.status === 200 && res.data.success) {
        const data = res.data.payload?.map((x) => ({
          ...x,
          action: (
            <>
              <Button
                className="theme_btn"
                style={{ textDecoration: "none" }}
                onClick={()=> setSelectEvalution(x)}
              >
                View
              </Button>
            </>
          ),
        }));
        setReviewList(data);
      } else {
        SwalError(res?.data?.message ?? "");
      }
    } catch (error) {
      SwalError(error?.message ?? "");
    }
  };
  return (
    <>
    {selectEvalution?
    <EmployeeReview selectEvalution={selectEvalution} />:
      <ReuseTable
        title="Employee Review List"
        headCells={HeadEmpReview}
        ListData={reviewList}
      />}
    </>
  );
}

export default EmployeeReviewList;
