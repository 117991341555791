import React, { Suspense, lazy } from "react";
import "./index.scss";
import UpdateUserSite from "../Create_forms/UpdateUserSite";
import Demonstration from "../Create_forms/Demonstration";
import Webinar from "../Create_forms/Webinar";
import Proposal from "../Create_forms/Proposal";

const HomeManage = lazy(() => import("../Create_forms/HomeManage"));
const SendMail = lazy(() => import("../Create_forms/SendMail"));
const CreateDraftManualCeu = lazy(() =>
  import("../Create_forms/CreateDraftManualCeu")
);
const CreateAcademicYear = lazy(() =>
  import("../Create_forms/CreateAcademicYear")
);
const DeleteCEUCourse = lazy(() => import("../Create_forms/DeleteCEUCourse"));
const EditManualCEU = lazy(() => import("../Create_forms/EditManualCEU"));
const CreateSiteLocation = lazy(() =>
  import("../Create_forms/CreateSiteLocation")
);
const CreateManualCourseComplete = lazy(() =>
  import("../Create_forms/CreateManualCourseComplete")
);
const CreateMenualAssignCourseUser = lazy(() =>
  import("../Create_forms/CreateMenualAssignCourseUser")
);
const CreateHierarchiaclUserList = lazy(() =>
  import("../Create_forms/CreateHierarchiaclUserList")
);
const CreateSchool = lazy(() => import("../Create_forms/CreateSchool"));
const CreateRole = lazy(() => import("../Create_forms/CreateRole"));
const CreateMenu = lazy(() => import("../Create_forms/CreateMenu"));
const CreateJobTitle = lazy(() => import("../Create_forms/CreateJobTitle"));
const CreateUser = lazy(() => import("../Create_forms/CreateUser"));
const CreateGroup = lazy(() => import("../Create_forms/CreateGroup"));
const CreateCourse = lazy(() => import("../Create_forms/CreateCourse"));
const CreateCourseCategory = lazy(() =>
  import("../Create_forms/CreateCourseCategory")
);
const CreateCourseQuiz = lazy(() => import("../Create_forms/CreateCourseQuiz"));
const CreateCertificate = lazy(() =>
  import("../Create_forms/CreateCertificate")
);
const CreateEventPromotion = lazy(() =>
  import("../Create_forms/CreateEventPromotion")
);
const HierarchicalChartShowsByRole = lazy(() =>
  import("../Create_forms/HierarchicalChartShowsByRole")
);
const CreateRecipeCategory = lazy(() =>
  import("../Create_forms/CreateRecipeCategory")
);
const CreateRecipeSubCategory = lazy(() =>
  import("../Create_forms/CreateRecipeSubCategory")
);
const CreateRecipeManagement = lazy(() =>
  import("../Create_forms/CreateRecipeManagement")
);
const CreateAppVersion = lazy(() => import("../Create_forms/CreateAppVersion"));
const AutomatedEmail = lazy(() => import("../Create_forms/AutomatedEmail"));
const WhatWeDoBoxes = lazy(() => import("../Create_forms/WhatWeDoBoxes"));
// const CEUCoursePage = lazy(() => import("../Create_forms/CEUCoursePage"));
// const FigLifestylePage = lazy(() => import("../Create_forms/FigLifestylePage"));
// const CoachesPage = lazy(() => import("../Create_forms/CoachesPage"));
const AboutUsPage = lazy(() => import("../Create_forms/AboutUsPage"));
const CoreValuesPage = lazy(() => import("../Create_forms/CoreValuesPage"));
const ContactUsPage = lazy(() => import("../Create_forms/ContactUsPage"));
const FooterPage = lazy(() => import("../Create_forms/FooterPage"));
const CreateWebinar = lazy(() => import("../Create_forms/CreateWebinar"));
const CreateFreeSources = lazy(() =>
  import("../Create_forms/CreateFreeSources")
);
const CreateEmailTemplate = lazy(() =>
  import("../Create_forms/CreateEmailTemplate")
);
const CreateCommunityAccess = lazy(() =>
  import("../Create_forms/CreateCommunityAccess")
);
const CreateDistrictMap = lazy(() =>
  import("../Create_forms/CreateDistrictMap")
);
const CreateGoalCategory = lazy(() =>
  import("../Create_forms/CreateGoalCategory")
);
const CreateQuestion = lazy(() =>
  import("../Create_forms/CreateQuestion"))

const CreateEvalGroup = lazy(() =>
  import("../Create_forms/CreateEvalGroup"))

const CreateEvalGroupCategory = lazy(() =>
  import("../Create_forms/CreateEvalGroupCategory.jsx"))

const CreateEvalSetup = lazy(() =>
  import("../Create_forms/CreateEvalSetup.jsx"))

const CreateEmployee = lazy(() =>
  import("../Create_forms/CreateEmployee.jsx"))

const CreateTemplate = lazy(() =>
  import("../Create_forms/CreateTemplate.jsx"))

const CreateCampaign = lazy(() =>
  import("../Create_forms/CreateCampaign.jsx"))

const CreateSmtp = lazy(() =>
  import("../Create_forms/CreateSmtp.jsx"))

const SendCommunicationEmail = lazy(() =>
  import("../Create_forms/SendCommunicationEmail.jsx"))

const renderLoader = () => <p className="text-center m-auto">Loading...</p>;
function Create(props) {
  // const { title } = props;
  return (
    <>
      <div className="create_school_distict p-0">
        {/* <h3>{props?.title}</h3> */}
        <Suspense fallback={renderLoader()}>
          {props?.title === "create district" && <CreateSchool />}
          {props?.title === "edit district" && (
            <CreateSchool editId={props?.editId} />
          )}
          {props?.title === "Create Role" && <CreateRole />}
          {props?.title === "Edit Role" && (
            <CreateRole editId={props?.editId} />
          )}
          {props?.title === "Create Menu" && <CreateMenu />}
          {props?.title === "Edit Menu" && (
            <CreateMenu editId={props?.editId} />
          )}
          {props?.title === "Create Job Title" && <CreateJobTitle />}
          {props?.title === "Edit Job Title" && (
            <CreateJobTitle editId={props?.editId} />
          )}
          {props?.title === "Create User" && <CreateUser />}
          {props?.title === "Edit User" && (
            <CreateUser editId={props?.editId} />
          )}
          {props?.title === "Create Group" && <CreateGroup />}
          {props?.title === "Edit Group" && (
            <CreateGroup editId={props?.editId} />
          )}
          {props?.title === "Create Course" && <CreateCourse />}
          {props?.title === "Edit Course" && (
            <CreateCourse editId={props?.editId} />
          )}
          {props?.title === "Create Course Category" && (
            <CreateCourseCategory />
          )}
          {props?.title === "Edit Course Category" && (
            <CreateCourseCategory editId={props?.editId} />
          )}
          {props?.title === "Create Quiz" && <CreateCourseQuiz single="1" />}
          {props?.title === "Edit Quiz" && (
            <CreateCourseQuiz editId={props?.editId} single="1" />
          )}
          {props?.title === "Create Certificate" && <CreateCertificate />}
          {props?.title === "Edit Certificate" && (
            <CreateCertificate editId={props?.editId} />
          )}
          {props?.title === "Create Event Promotion" && (
            <CreateEventPromotion />
          )}
          {props?.title === "Edit Event Promotion" && (
            <CreateEventPromotion editId={props?.editId} />
          )}
          {props?.title === "Create Site/Location" && <CreateSiteLocation />}
          {props?.title === "Edit Site/Location" && (
            <CreateSiteLocation editId={props?.editId} />
          )}{" "}
          {props?.title === "Manual Course Completed" && (
            <CreateManualCourseComplete />
          )}
          {props?.title === "Manual Course Scheduled" && (
            <CreateMenualAssignCourseUser />
          )}
          {props?.title === "Reporting Manager" && (
            <CreateHierarchiaclUserList />
          )}
          {props?.title === "Hierarchical Chart" && (
            <HierarchicalChartShowsByRole />
          )}
          {props?.title === "Edit Manual CEU" && (
            <EditManualCEU editId={props?.editId} />
          )}
          {props?.title === "Delete CEU" && <DeleteCEUCourse />}
          {props?.title === "Create Recipe" && <CreateRecipeManagement />}
          {props?.title === "Edit Recipe" && (
            <CreateRecipeManagement editId={props?.editId} />
          )}
          {props?.title === "Create Recipe Category" && (
            <CreateRecipeCategory />
          )}
          {props?.title === "Edit Recipe Category" && (
            <CreateRecipeCategory editId={props?.editId} />
          )}{" "}
          {props?.title === "Create Recipe Sub Category" && (
            <CreateRecipeSubCategory />
          )}
          {props?.title === "Edit Recipe Sub Category" && (
            <CreateRecipeSubCategory editId={props?.editId} />
          )}{" "}
          {props?.title === "Create Academic Year" && <CreateAcademicYear />}
          {props?.title === "Edit Academic Year" && (
            <CreateAcademicYear editId={props?.editId} />
          )}
          {props?.title === "App Version Update" && (
            <CreateAppVersion editId={props?.editId} />
          )}
          {props?.title === "Create Draft Manual Ceu" && (
            <CreateDraftManualCeu editId={props?.editId} />
          )}
          {props?.title === "Assign Site/Location" && <UpdateUserSite />}
          {props?.title === "Send Mail" && <SendMail />}
          {props?.title === "Create Automated Email" && <AutomatedEmail />}
          {props?.title === "Edit Automated Email" && (
            <AutomatedEmail editId={props?.editId} />
          )}
          {props?.title === "Create Email Template" && <CreateEmailTemplate />}
          {props?.title === "Edit Email Template" && (
            <CreateEmailTemplate editId={props?.editId} />
          )}
          {props?.title === "Create What We Do (Boxes)" && <WhatWeDoBoxes />}
          {props?.title === "Edit What We Do (Boxes)" && (
            <WhatWeDoBoxes editId={props?.editId} />
          )}
          {props?.title === "Create Core Values (Boxes)" && <CoreValuesPage />}
          {props?.title === "Edit Core Values (Boxes)" && (
            <CoreValuesPage editId={props?.editId} />
          )}
          {props?.title === "Home Page" && <HomeManage />}
          {/* {props?.title === "CEU Course Page" && <CEUCoursePage />}
          {props?.title === "Fig Lifestyle Page" && <FigLifestylePage />}
          {props?.title === "Coaches Page" && <CoachesPage />} */}
          {props?.title === "About Us Page" && <AboutUsPage />}
          {props?.title === "Contact Us Page" && <ContactUsPage />}
          {props?.title === "Footer Page" && <FooterPage />}
          {props?.title === "View Demonstration" && (
            <Demonstration editId={props?.editId} />
          )}
          {props?.title === "View Webinar" && (
            <Webinar editId={props?.editId} />
          )}
          {props?.title === "View Proposal" && (
            <Proposal editId={props?.editId} />
          )}
          {props?.title === "Create Webinar" && <CreateWebinar />}
          {props?.title === "Edit Webinar" && (
            <CreateWebinar editId={props?.editId} />
          )}
          {props?.title === "Community Access" && <CreateCommunityAccess />}
          {props?.title === "Create Free Sources" && <CreateFreeSources />}
          {props?.title === "Edit Free Sources" && (
            <CreateFreeSources editId={props?.editId} />
          )}{" "}
          {props?.title === "Create District Map" && <CreateDistrictMap />}
          {props?.title === "Edit District Map" && (
            <CreateDistrictMap editId={props?.editId} />
          )}{" "}
          {props?.title === "Create Goal Category" && <CreateGoalCategory />}
          {props?.title === "Edit Goal Category" && (
            <CreateGoalCategory editId={props?.editId} />
          )}
          {props?.title === "Create Question" && <CreateQuestion />}
          {props?.title === "Edit Question" && (
            <CreateQuestion editId={props?.editId} />
          )}
          {props?.title === "Create Evaluation Group" && <CreateEvalGroup />}
          {props?.title === "Edit Evaluation Group" && (
            <CreateEvalGroup editId={props?.editId} />
          )}
          {props?.title === "Create Group Category" && (
            <CreateEvalGroupCategory />
          )}
          {props?.title === "Edit Group Category" && (
            <CreateEvalGroupCategory editId={props?.editId} />
          )}
          {props?.title === "Create Setup" && <CreateEvalSetup />}
          {props?.title === "Edit Setup" && (
            <CreateEvalSetup editId={props?.editId} />
          )}
          {props?.title === "Create Evaluation" && <CreateEmployee />}
          {props?.title === "Edit Employee" && (
            <CreateEmployee editId={props?.editId} />
          )}
          {props?.title === "Create Template" && <CreateTemplate />}
          {props?.title === "Edit Template" && (
            <CreateTemplate editId={props?.editId} />
          )}
          {props?.title === "Create Campaign" && <CreateCampaign />}
          {props?.title === "Edit Campaign" && (
            <CreateCampaign editId={props?.editId} />
          )}
          {props?.title === "Create Smtp" && <CreateSmtp />}
          {props?.title === "Edit Smtp" && (
            <CreateSmtp editId={props?.editId} />
          )}
          {props?.title === "Send Communication Email" && (
            <SendCommunicationEmail />
          )}
        </Suspense>
      </div>
    </>
  );
}

export default React.memo(Create);
