import React from "react";
import WebMenu from "../Pages/Shared/WebMenu";
import Footer from "../Pages/Shared/Footer";
import { Outlet } from "react-router-dom";

function PublicRoute() {
  return (
    <>
      <WebMenu />
      <Outlet />
      <Footer />
      <button
        className="indicator"
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
      ></button>
    </>
  );
}

export default PublicRoute;
