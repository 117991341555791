import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import Config from "../../../../Utils/Config";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DashboardRe } from "../../../../reducers/user";
import Select from "react-select";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";

function UserCourse(props) {
  const { handleClose, courseData } = props;
  const [courseList, setCourseList] = useState();
  const [schList, setSchList] = useState();
  const [userList, setUserList] = useState([]);
  const [dateCompletion2, setDateCompletion2] = useState();
  const [derrors, setDerrors] = useState({});
  const LogUserId = useSelector((state) => state.login.LoginDetails);
  let changeDash = useSelector((state) => state.user.Drefresh);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  let dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [courseAdd, setCourseAdd] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const pathname = window?.location?.pathname;

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = (data) => {
    let is_success = true;

    if (dateCompletion2) {
      setDerrors({ D: "" });
      function convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
      if (users?.length > 0) {
        setDerrors({ userId: "" });
      } else {
        is_success = false;
        setDerrors({ userId: "Please select User" });
      }
      if (courseAdd.length > 0) {
        setDerrors({ course_code: "" });
      } else {
        setDerrors({ course_code: "Please select Course Category" });
        is_success = false;
      }
      let fullList = [];
      let userCourseList = users?.map((x) =>
        courseAdd?.map((cours) => {
          let d = {
            userId: x.value,
            courseId: cours,
          };
          return fullList?.push(d);
        })
      );

      let userdata = {
        ...data,
        userCourseList: fullList,
        expirationDate: convert(dateCompletion2),
        createdBy: LogUserId.userId,
      };
      function titleCase(str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
      }
      if (is_success) {
        setOpenCircle(true);
        axios
          .post(
            `${Config.API_HOST_URL_live}/api/UserCourse/AssignCourseToUser`,
            userdata,
            { headers }
          )
          .then((response) => {
            if (response.data.success) {
              setOpenCircle(false);

              setDerrors({ ermsg: "" });
              if (changeDash) {
                dispatch(DashboardRe(false));
              } else {
                dispatch(DashboardRe(true));
              }
              handleClose();
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: titleCase(response.data.message),
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
            } else {
              setOpenCircle(false);
              setDerrors({ ermsg: response.data.message });
            }
          })
          .catch((error) => console.log(error));
      }
    } else {
      setDerrors({ D: "Select Date" });
    }
  };
  useEffect(() => {
    getSchoolList(LogUserId.userId);
    if (courseData !== null) {
      setTimeout(() => {
        setValue("schoolDistrictId", courseData?.districtId);
        getUserList(courseData?.districtId);
         if (courseData?.courseLable === "AdminReview") {
           if (LogUserId?.roleId === 1) {
             setValue("courseLable", courseData?.courseLable);
           }
         } else {
           setValue("courseLable", courseData?.courseLable);
         }
        getCourseList(courseData?.districtId, courseData?.courseLable, courseData);
        setTimeout(() => {
          setCourseAdd([courseData?.courseId]);
        }, 1000);
      }, 1000);
    }
    // getCourseList("00000000-0000-0000-0000-000000000000");

    // eslint-disable-next-line
  }, [courseData]);
  const getCourseList = async (schId, cl, addCourstIfnotthere) => {
    setCourseAdd([]);
    setCourseList([]);
    if (cl) {
      let seData = {
        url: `/api/Course/GetAssignCoursesDropDown?userId=${
          LogUserId.userId
        }&courseTypes=${
          pathname === "/ceu-courses"
            ? "1"
            : pathname === "/fig-lifestyle"
            ? "2"
            : "1"
        }&districtId=${schId}&courseLable=${cl}`,
        headers: headers,
      };
      // let url = `${Config.API_HOST_URL_live}/api/Course/GetCoursesDropDown?userId=${LogUserId.userId}&courseTypes=1`;
      // axios
      //   .get(`${url}`, { headers })
      //   .then((response) => {
      let response = await GetCallApi(seData);
      if (response?.status === 200) {
        if (response.data.success) {
          const data = response?.data?.payload?.map((item) => {
            // let ct = 
            //   item?.courseLable === "FigCEUCourses" ? "FIG Content" : item?.courseLable === "PartnerContent" ? "Partner Content" : item?.courseLable === "AdminReview" ? "Admin Only" : item?.courseLable === "GovernmentContent" ? "Government Content" : item?.courseLable === "FIGFaves" ? "FIG Faves" : item?.courseLable === "DistrictContent" ? "District Content" : "";
            return {
              value: item.courseId,
              label: item.courseTitle
              // + " - " + ct,
            };
          });
          if (addCourstIfnotthere) {
            let findAddCourse = data.find((x) => x?.value === addCourstIfnotthere?.courseId)
            if (!findAddCourse) {
              setCourseList([{ value: addCourstIfnotthere?.courseId, label: addCourstIfnotthere?.courseTitle }, ...data]);
            } else {
              setCourseList(data);
            }
          } else {
            setCourseList(data);
          }
        }
      }
    }
    // })
    // .catch((error) => console.log(error));
  };
  const getSchoolList = (userId) => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${userId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              schId: item.schoolDistrictId,
              schName: item.districtName,
            };
          });
          setSchList(data);
        }
      })
      .catch((error) => console.log(error));
  };
  const getUserList = async (id) => {
    if (id) {
      // let url = `${Config.API_HOST_URL_live}/api/User/GetUserBySchoolDistrictId?schoolDistrictId=${id}`;
      // axios.get(`${url}`, { headers }).then((response) => {
      let seData = {
        // url: `/api/User/GetUserBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogUserId?.userId}`,
        url: `/api/User/GetUserListSchoolDistrictIdByRoleWise?schoolDistrictId=${id}&userId=${LogUserId?.userId}`,
        headers: headers
      }
      const response = await GetCallApi(seData)
      if (response?.status === 200) {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              value:
                item?.userId === "" || item?.userId === null ? "" : item.userId,
              label:
                item?.firstName === "" ||
                  item?.firstName === null ||
                  item?.lastName === "" ||
                  item?.lastName === null
                  ? ""
                  : item.firstName + " " + item.lastName,
            };
          });
          let uslist = [{ value: "all", label: "Select All" }, ...data];
          setUserList(uslist);
        } else {
          setUserList([]);
        }
      } else {
        setUserList([]);
      }
      // });
    } else {
      setUserList([]);
    }
  };
  const handleChangeUsersMulti = (e) => {
    setUsers(e);
  };
  const handleChangeCourceMulti = (e) => {
    setCourseAdd(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleSchDrop = (e, cl) => {
    getUserList(e);
    getCourseList(e ? e : "00000000-0000-0000-0000-000000000000", cl, courseData);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="tab_panel_input_mt">
        <select
          className="form-select dropdown_group"
          aria-label="Default select example"
          {...register("schoolDistrictId", {
            required: {
              value: true,
              message: "This field is required",
            },
            onChange: (e) => handleSchDrop(e.target.value, getValues("courseLable") ? getValues("courseLable") : ""),
          })}
        >
          <option value=""> Select School District </option>
          {schList &&
            schList?.map((data, i) => (
              <option key={i} value={data.schId}>
                {data.schName}
              </option>
            ))}
        </select>
        {errors.schoolDistrictId && (
          <span role="alert" className="error_text">
            {errors.schoolDistrictId.message}
          </span>
        )}
      </div>

      <div className="tab_panel_input_mt">
        <Select
          isMulti
          name="colors"
          options={userList}
          className="course-caretory-multi-select p_14_text"
          classNamePrefix="select"
          placeholder="Select Users"
          value={users ? users : null}
          // onChange={handleChangeUsersMulti}
          onChange={(selected) => {
            true &&
              selected.length &&
              selected.find((option) => option.value === "all")
              ? handleChangeUsersMulti(userList.slice(1))
              : !true
                ? handleChangeUsersMulti((selected && selected.value) || null)
                : handleChangeUsersMulti(selected);
          }}
        />
        {derrors.userId && (
          <span role="alert" className="error_text">
            {derrors.userId}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <select
          className="form-select dropdown_group"
          {...register("courseLable", {
            required: "This field is required",
            onChange: (e) => getCourseList(getValues('schoolDistrictId') ? getValues('schoolDistrictId') : "00000000-0000-0000-0000-000000000000", e?.target?.value, courseData)
          })}
        >
          <option value="">Select Course Label</option>
          {LogUserId?.roleId === 1 &&
            <option value="AdminReview">Admin Only</option>
          }
          <option value="DistrictContent">District Content</option>
          <option value="FigCEUCourses">Fig CEU Courses</option>
          <option value="PartnerContent">Partner Content</option>
          <option value="GovernmentContent">Government Content</option>
          <option value="FIGFaves">FIG Faves</option>
        </select>
        {errors.courseLable && (
          <span role="alert" className="error_text">
            {errors.courseLable.message}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <Select
          isMulti
          name="colors"
          options={courseList}
          className="course-caretory-multi-select p_14_text"
          classNamePrefix="select"
          placeholder="Select Multiple Course"
          value={courseList?.filter((obj) => courseAdd?.includes(obj.value))}
          onChange={handleChangeCourceMulti}
        />
        {derrors?.course_code && (
          <span role="alert" className="error_text">
            {derrors?.course_code}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={dateCompletion2}
          onChange={setDateCompletion2}
          placeholderText="MM/DD/YYYY"
          className="Date_picker"
        />
        {derrors?.D && (
          <span role="alert" className="error_text">
            {derrors.D}
          </span>
        )}
        {derrors?.ermsg && (
          <span role="alert" className="error_text">
            {derrors.ermsg}
          </span>
        )}
      </div>
      <div className="tab_panel_input_mt">
        <button
          type="button"
          className="popup_btn update"
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </button>
        <button
          type="button"
          className="popup_btn cancel"
          onClick={handleClose}
        >
          cancel
        </button>
      </div>
    </>
  );
}

export default React.memo(UserCourse);
