import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import Login from './Modules/Login/Login';
import ResetPassword from './Modules/Login/ResetPassword';
import CEUCources from './Modules/User/CEU_Cources';
import AuthorProfile from './Modules/User/CEU_Cources/AuthorProfile';
import CourseCardDetail from './Modules/User/CEU_Cources/Course_Card_Detail';
import StartCource from './Modules/User/CEU_Cources/StartCource';
import Community from './Modules/User/Community';
import CommunityGroups from './Modules/User/CommunityGroups';
import ComGroupDetail from './Modules/User/CommunityGroups/ComGroupDetail';
import AllCertificateDash from './Modules/User/Dashboard/AllCertificate';
import Dashboard from './Modules/User/Dashboard/Index';
import FigLifeStyle from './Modules/User/FigLifeStyle';
import Messages from './Modules/User/Messages';
import Profile from './Modules/User/Profile';
import Recipe from './Modules/User/Recipe';
import RecipeDeatail from './Modules/User/Recipe/RecipeDetail';
import Reports from './Modules/User/Reports';
import Setting from './Modules/User/Settings/Setting';
import PrivateRoute from './Routes/PrivateRoute';
import Certificate from './Modules/User/Certificate/User_Report';
import CertificateUserNew from './Modules/User/Certificate_New/index';
import SepratePageTableData from './Modules/User/Dashboard/SepratePage_TableData';
import ResetPasswordLogged from './Modules/User/ResetPasswordLogged';
import CompleteCource from './Modules/User/CEU_Cources/completeCourse';
import CleverLogin from './Modules/Login/CleverLogin';
import EmailPreference from './Modules/User/Email_Preference';
import ViewProfile from './Modules/User/ViewProfile';
import Privacy from './Modules/User/Privacy';
import PrivacyPolicy from './Modules/PrivacyPolicy';
import SSOLogin from './Modules/Login/SSOLogin';
import FigFamily from './Modules/User/FigFamily';
import ViewQuizSubmitted from './Modules/User/Reports/QuizSubmittedList/ViewQuizSubmitted';
import FigFamilyProfile from './Modules/User/FigFamily/FigFamilyProfile';
import Help from './Modules/User/Help';
// import Training from './Modules/User/Training';

import Home from './Pages/Home';
import About from './Pages/About';
import CEU from './Pages/CEU';
import FigLifestyle from './Pages/FigLifestyle';
import Coaches from './Pages/Coaches';
import ContactUs from './Pages/ContactUs';
import RequestCard from './Pages/ContactUs/RequestCard';
import WebinarCard from './Pages/ContactUs/WebinarCard';
import RequestSchoolCard from './Pages/ContactUs/RequestSchoolCard';
import MeetTeam from './Pages/MeetTeam';
import PreferredPartners from './Modules/User/Preferred_Partners';
import NewCommunity from './Modules/User/New_Community';
import PublicRoute from './Routes/PublicRoute';
import CourseDetail from './Pages/CEU/CourseDetail';
import SuccessCourse from './Modules/User/CEU_Cources/SuccessCourse';
import FreeSources from './Modules/User/FreeSources';
import FreeSourceDetail from './Modules/User/FreeSources/FreeSourceDetail';
import MainFreeSources from './Pages/MainFreeSources';
import MainFreeSourceDetail from './Pages/MainFreeSources/MainFreeSourceDetail';
import Goal from './Modules/User/Goal';
import PaymentSuccess from './Pages/PaymentSuccess';
import EmployeeReview from './Modules/User/EmployeeReview';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import AppVideo from './Pages/AppVideo';
import Logout from './components/Logout';
import EmployeeReviewList from './Modules/User/EmployeeReview/EmployeeReviewList';
import NewDashboard from './Modules/User/NewDashboard';
import EvalutionDashboard from './Modules/User/Dashboard/Evalution/EvalutionDashboard';

function App() {
  // const [mes, setMes] = useState()
  // const [open, setOpen] = useState(true)
  // useEffect(() => {
  //   getSite();
  // }, [])
  // const getSite = async () => {
  //   try {
  //     // let res = await axios.get(`http://figappapi.vidhaninfotech.com/api/Common/GetSiteOnOffStatus`)
  //     setOpen(false)
  //     // if (res?.status === 200) {
  //     //   if (!res?.data?.success) {
  //     //     setMes(res?.data?.message)
  //     //   }
  //     // }
  //   } catch (error) {
  //     console.log(error);
  //     setOpen(false)
  //   }
  // }

  return (
    <>
      {/* {open || mes ?
        open ?
          <div className="loader">
            <CircularProgress sx={{ color: "#86c65a" }} />
          </div> :
          <div
            style={{ height: '100vh', display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <h1>
              {mes}
            </h1>
          </div>
        : */}

      <Router>
        <Logout />

        <Routes>
          {/* Without Login pages start */}
          <Route element={<PublicRoute />}>

            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />

            <Route path='/about' element={<About />} />
            <Route path='/ceu' element={<CEU />} />
            <Route path='/ceu/:id' element={<CourseDetail />} />
            <Route path='/figlifestyle' element={<FigLifestyle />} />
            <Route path='/figlifestyle/:id' element={<CourseDetail />} />
            <Route path='/coaches' element={<Coaches />} />
            <Route path='/contactus' element={<ContactUs />} />
            <Route path='/contactus/requestcard' element={<RequestCard />} />
            <Route path='/contactus/webinarcard' element={<WebinarCard />} />
            <Route path='/contactus/requestschoolcard' element={<RequestSchoolCard />} />
            <Route path='/meet-team' element={<MeetTeam />} />
            <Route path='/free' element={<MainFreeSources />} />
            <Route path='/free/:id' element={<MainFreeSourceDetail />} />
          </Route>
          {/* Without Login pages end */}

          {/* <Route path='/' element={<Login />} /> */}
          <Route path='/app-video' element={<AppVideo />} />
          <Route path='/login' element={<Login />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          <Route path='/clever-login' element={<CleverLogin />} />
          <Route path='/sso-login' element={<SSOLogin />} />
          {/* }} element={<Navigate to="/sso-login/code" />} /> */}
          <Route path='/sso-login/code' element={<SSOLogin />} />
          <Route path='/resetpassword/:email' element={<ResetPassword />} />
          <Route element={<PrivateRoute />}>
            <Route path='/dashboard'  >
              <Route index element={<><NewDashboard /></>} />
              {/* <Route path='new' element={<PrivateRoute><NewDashboard /></PrivateRoute>} /> */}
              <Route path='old' element={<><Dashboard /></>} />
              <Route path='all-certificate' element={<><AllCertificateDash /></>} />
              <Route path='course-schedule' element={<><SepratePageTableData /></>} />
              <Route path='course-name' element={<><SepratePageTableData /></>} />
              <Route path='top-student' element={<><SepratePageTableData /></>} />
              <Route path='completed-course-roster/:id?/:status?' element={<><SepratePageTableData /></>} />
              <Route path='evalution' element={<><EvalutionDashboard /></>} />
              <Route path='employee-review' element={<><EmployeeReviewList /></>} />
              {/* <Route path='employee-review/:id' element={<PrivateRoute><EmployeeReview /></PrivateRoute>} /> */}
            </Route>
            <Route path='/settings' element={<><Setting /></>} />
            <Route path='/edit-profile' element={<><Profile /> </>} />
            <Route path='/edit-profile/:id' element={<><ComGroupDetail /> </>} />
            <Route path='/logged-reset-password' element={<><ResetPasswordLogged /> </>} />
            <Route path='/ceu-courses'>
              <Route index element={<><CEUCources /> </>} />
              {/* <Route path='/ceu-courses/:key?' element={<PrivateRoute><CEUCources /> </PrivateRoute>} /> */}
              <Route path='/ceu-courses/:id' element={<><CourseCardDetail /> </>} />
              <Route path='/ceu-courses/start-course/:id' element={<><StartCource /> </>} />
              <Route path='/ceu-courses/complete-course/:id' element={<><CompleteCource /> </>} />
              <Route path='/ceu-courses/success-course/:id' element={<><SuccessCourse /> </>} />
              <Route path='/ceu-courses/author/:id' element={<><AuthorProfile /> </>} />
            </Route>
            <Route path='/recipe' element={<><Recipe /> </>} />
            <Route path='/recipe/:id' element={<><RecipeDeatail /> </>} />
            <Route path='/fig-lifestyle'>
              <Route index element={<><FigLifeStyle /> </>} />
              <Route path='/fig-lifestyle/:id' element={<><CourseCardDetail /> </>} />
              <Route path='/fig-lifestyle/start-course/:id' element={<><StartCource /> </>} />
              <Route path='/fig-lifestyle/complete-course/:id' element={<><CompleteCource /> </>} />
              <Route path='/fig-lifestyle/success-course/:id' element={<><SuccessCourse /> </>} />
              <Route path='/fig-lifestyle/author/:id' element={<><AuthorProfile /> </>} />
            </Route>
            <Route path='/reports' element={<><Reports /> </>} />
            <Route path='/community-old' element={<><Community /> </>} />
            <Route path='/community' element={<><NewCommunity /> </>} />
            <Route path='/messages' element={<><Messages /> </>} />
            <Route path='/messages/:id' element={<><Messages /> </>} />
            <Route path='/community-groups' element={<><CommunityGroups /> </>} />
            <Route path='/community-groups/:id' element={<><ComGroupDetail /> </>} />
            <Route path='/certificate/report/:id' element={<><Certificate /> </>} />
            {/* <Route path='/certificate/:id' element={<PrivateRoute><CertificateUserNew /> </PrivateRoute>} /> */}
            <Route path='/certificate/:courseid/:userid/:ayid' element={<PrivateRoute><CertificateUserNew /> </PrivateRoute>} />
            <Route path='/view-profile/:id' element={<><ViewProfile /> </>} />
            <Route path='/email-preference' element={<><EmailPreference /> </>} />
            <Route path='/privacy' element={<><Privacy /> </>} />
            <Route path='/fig-family' element={<><FigFamily /> </>} />
            <Route path='/fig-family/:id' element={<><FigFamilyProfile /> </>} />
            <Route path='/view-quiz-submitted/:courseid/:userid/:ayid' element={<><ViewQuizSubmitted /> </>} />
            <Route path='/help' element={<><Help /> </>} />
            {/* <Route path='/training' element={<PrivateRoute><Training /> </PrivateRoute>} /> */}
            <Route path='/preferred-partners' element={<><PreferredPartners /></>} />
            <Route path='/preferred-partners/:id' element={<><FigFamilyProfile /></>} />
            <Route path='/preferred-partners/user/:id' element={<><ViewProfile /></>} />
            <Route path='/free-sources' element={<><FreeSources /> </>} />
            <Route path='/free-sources/:id' element={<><FreeSourceDetail /> </>} />
            <Route path='/goal' element={<><Goal /> </>} />
            <Route path='/complete-payment/:id/:amt' element={<><PaymentSuccess /></>} />

            <Route path='*' element={<><Dashboard /></>} />
          </Route>
        </Routes>
      </Router>

      {/* } */}
    </>
  );
}

export default React.memo(App);
