import React, { useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import "./AccessManagement.scss";
// import { Table } from "reactstrap";
import { useForm } from "react-hook-form";
import Config from "../../../Utils/Config";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import CheckboxTree from "react-checkbox-tree";
import { PostCallApi } from "../../../Action/Action";
// import {
//   MdCheckBox,
//   MdCheckBoxOutlineBlank,
//   MdChevronRight,
//   MdKeyboardArrowDown,
//   MdAddBox,
//   MdIndeterminateCheckBox,
//   MdFolder,
//   MdFolderOpen,
//   MdInsertDriveFile
// } from "react-icons/md";

function AccesssManagement(props) {
  // const [checkBoxVal, setCheckBoxVal] = useState(false);

  const [roleName, setRoleName] = useState("");
  const [roleList, setRoleList] = useState();
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const [open, setOpen] = useState(false);
  const [derror, setDerror] = useState();
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const [menuList, setMenulist] = useState([]);
  const [mlist, setMlist] = useState([]);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  // const { register, handleSubmit, control, setValue, getValues, reset } =
  //   useForm();
  const { handleSubmit, reset } = useForm();
  // const { fields } = useFieldArray({
  //   control,
  //   name: "check",
  // });

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleRole = (e) => {
    setRoleName(e);
    setDerror({ rol: "" });
    if (!e) {
      setMlist([]);
      setChecked([]);
    }
  };
  useEffect(() => {
    if (roleName) {
      // reset({ check: [] });
      GetMenuList(roleName);
    }
    // eslint-disable-next-line
  }, [roleName]);
  const onSubmit = async (data) => {
    if (roleName) {
      // const Menu = data?.check?.filter((item) => {
      //   if (item?.menuName === "Dashboard") {
      //     return item?.menuid;
      //   } else if (item?.isAssigned === true) {
      //     return item?.menuid;
      //   }
      //   return 0;
      // });
      // parent select
      // let pr = mlist
      //   ?.filter((x) => checked?.includes(x.value.toString()))
      //   ?.map((x) => x?.menuId);
      // let ch = mlist
      //   ?.filter((x) => x?.children?.length > 0)
      //   ?.map((x) => {
      //     if (
      //       x.children?.filter((c) => checked?.includes(c?.value?.toString()))
      //         ?.length > 0
      //     ) {
      //       return {
      //         ...x,
      //         children: x.children?.filter((c) =>
      //           checked?.includes(c?.value?.toString())
      //         ),
      //       };
      //     }
      //   })
      //   ?.filter((x) => x !== undefined)
      //   ?.map((x) => x?.menuId);

      // let addmn =  menuList?.filter((c) => checked?.includes(c?.menuId));

      // addmn = addmn?.filter((x) => x.parentMenuId === 0)?.map((x) => x.menuId);
      let checkIntar = checked
        ?.filter((y) => isNaN(parseInt(y)) === false)
        ?.map((x) => parseInt(x));
      let chld = await menuList
        ?.filter((c) => checkIntar?.includes(c?.menuId))
        ?.map((x) => x.parentMenuId);

      chld = chld?.filter((x) => x !== 0);

      let admn = await menuList
        ?.filter((c) => chld?.includes(c?.menuId))
        ?.map((x) => x.parentMenuId);
      admn = admn?.filter((x) => x !== 0);

      let meSeList = [
        ...chld,
        ...admn,
        // ...addmn,
        // ...ch,
        ...checked
          ?.filter((y) => isNaN(parseInt(y)) === false)
          ?.map((x) => parseInt(x)),
      ];
      meSeList = meSeList.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) === index;
      });
      if (meSeList?.length >= 1) {
        const acc = {
          menuId: meSeList,
          roleId: roleName,
          createdBy: LogUserId,
        };
        setOpen(true);
        // let url = `${Config.API_HOST_URL_live}/api/AccessLevel/AddAccessLevel`;
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "*/*",
        };
        let seData = {
          url: `/api/AccessLevel/AddAccessLevel`,
          body: acc,
          headers: headers,
        };
        let response = await PostCallApi(seData);
        // axios.post(url, acc, { headers }).then((response) => {
        setOpen(false);
        if (response?.status === 200) {
          if (response.data.success) {
            setOpen(false);
            // setCheckBoxVal(false);
            function titleCase(str) {
              var splitStr = str?.toLowerCase().split(" ");
              for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] =
                  splitStr[i].charAt(0).toUpperCase() +
                  splitStr[i].substring(1);
              }
              return splitStr.join(" ");
            }
            setRoleName("");
            reset();
            setMlist([]);
            setChecked([]);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: titleCase(response.data?.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          } else {
            setOpen(false);
            // setCheckBoxVal(false);
          }
        }
        // });
        setDerror();
      } else {
        setDerror({ err_msg: "Select minimun one menu" });
      }
    } else {
      setDerror({ rol: "Please Select Role" });
    }
  };
  useEffect(() => {
    getRoleList();
    // eslint-disable-next-line
  }, []);
  const getRoleList = () => {
    let url = `${Config.API_HOST_URL_live}/api/Role/GetRolesDropdown`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              RoleId: item?.roleId,
              RoleName: item?.roleName,
            };
          });
          setRoleList(data);
          // setCheckBoxVal(false);
        }
      })
      .catch((error) => console.log(error));
  };
  const GetMenuList = (e) => {
    setOpen(true);
    let url = `${Config.API_HOST_URL_live}/api/AccessLevel/GetAccessLevelByRoleId?roleId=${e}`;
    axios.get(`${url}`, { headers }).then((response) => {
      if (response.data.success) {
        // setCheckBoxVal(false);
        setOpen(false);
        setMenulist(response?.data.payload);
        // const data = response?.data.payload?.map((item, i) => {
        //   return {
        //     menuid: item?.menuId,
        //     menuName: item?.menuName,
        //     parentMenu: item?.parentMenuName,
        //     description: item?.description,
        //     isAssigned:
        //       item?.menuName === "Dashboard" ? true : item?.isAssigned,
        //   };
        // });
        // setValue("check", data);
        // let allMainCh = true;

        // data?.map((as, i) => {
        //   if (as?.isAssigned === false) {
        //     return (allMainCh = false);
        //   }
        //   return 0;
        // });

        // if (allMainCh) {
        //   setCheckBoxVal(true);
        // }

        let nr = response?.data.payload
          ?.filter((x) => x?.parentMenuId === 0)
          ?.map((me) => {
            let child = response?.data.payload
              ?.filter((d) => d?.parentMenuId === me?.menuId)
              ?.map((c) => {
                let child1 = response?.data.payload
                  ?.filter((d) => d?.parentMenuId === c?.menuId)
                  ?.map((c) => {
                    return {
                      ...c,
                      value: c?.menuId,
                      label: c?.menuName,
                    };
                  });
                if (child1?.length > 0) {
                  return {
                    ...me,
                    value: c?.menuId,
                    label: c?.menuName,
                    children: child1,
                  };
                }
                return {
                  ...c,
                  value: c?.menuId,
                  label: c?.menuName,
                };
              });
            if (child?.length > 0) {
              return {
                ...me,
                value: me?.menuId,
                label: me?.menuName,
                children: child,
              };
            }
            return {
              ...me,
              disabled: me?.menuName === "Dashboard" ? true : false,
              value: me?.menuId,
              label: me?.menuName,
            };
          });

        setMlist(nr);
        // let ch = nr
        //   ?.filter((x) => x?.children?.length > 0)
        //   ?.map((c) => c?.children?.filter((a) => a?.isAssigned === true));
        // ch = ch
        //   ?.filter((x) => x?.length > 0)
        //   ?.map((c) => c?.map((v) => v?.menuId));
        // ch = ch.toString()?.split(",");
        let pr = nr
          ?.filter((x) => x?.children === undefined)
          ?.filter((x) => {
            if (x.menuName === "Dashboard") {
              return x;
            }
            return x?.isAssigned === true;
          })
          ?.map((x) => x?.menuId);
        // pr = pr;
        // let sendArr;
        // if (ch?.length > 0) {
        //   sendArr = [...ch];
        // }
        // if (pr?.length > 0) {
        //   sendArr = [...sendArr, ...pr];
        // }
        // let sendArr = response?.data.payload
        //   ?.filter((x) => {
        //     if (x.menuName === "Dashboard") {
        //       return x;
        //     }
        //     return x?.isAssigned === true;
        //   })
        //   ?.map((y) => y.menuId);
        let saveId = [];
        let newCheckChild = nr
          ?.filter((x) => x?.children?.length > 0)
          ?.map((x) => x?.children?.filter((y) => y.children?.length > 0));
        newCheckChild = newCheckChild?.map((x) => {
          return x?.map((d) => {
            return d.children?.filter((y) => y?.isAssigned === true);
          });
        });
        newCheckChild = newCheckChild?.map((x) => {
          return x?.map((y) => {
            if (y?.length > 0) {
              return (saveId = [...saveId, ...y]);
            }
            return 0;
          });
          // return x.filter((y) => y.length > 0);
        });
        saveId = saveId?.map((x) => x.menuId);

        // debugger;
        setChecked([...pr, ...saveId]);
        // setChecked(sendArr);
      } else {
        setOpen(false);
      }
    });
  };
  const handlecancel = () => {
    setRoleName("");
    // setCheckBoxVal(false);
    // reset({ check: [] });
    setMlist([]);
    setChecked([]);
  };
  // const handleAll = (val) => {
  //   fields?.map((all, index) => {
  //     if (all?.menuName === "Dashboard") {
  //       return setValue(`check.${index}.isAssigned`, true);
  //     }
  //     return setValue(`check.${index}.isAssigned`, val);
  //   });
  // };
  // const handleMainCheck = (e, index) => {
  //   let allMainCh = true;
  //   if (e) {
  //     fields?.map((as, i) => {
  //       if (getValues(`check.${i}.isAssigned`) === false) {
  //         allMainCh = false;
  //       }
  //       return 0;
  //     });

  //     if (allMainCh) {
  //       setCheckBoxVal(true);
  //     } else {
  //       setCheckBoxVal(false);
  //     }
  //   } else {
  //     setCheckBoxVal(false);
  //   }
  // };
  // const getParentTitle = (key, tree) => {
  //   let parentTitle;
  //   for (let i = 0; i < tree.length; i++) {
  //     const node = tree[i];
  //     if (node.children) {
  //       if (node.children.some((item) => item.value === key)) {
  //         parentTitle = node.value;
  //       } else if (getParentTitle(key, node.children)) {
  //         parentTitle = getParentTitle(key, node.children);
  //       }
  //     }
  //   }
  //   return parentTitle;
  // };
  const onSelect = (selectedKeys, info) => {
    setChecked(selectedKeys);
    // console.log("selected", selectedKeys, info);
    // console.log(getParentTitle(info.value, mlist));
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="create_school_distict">
        <div className="create_hed">
          <h3>{props?.title}</h3>
        </div>
        <hr />
        <div className=" access_pannel mt-3">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group col-lg-10 col-md-12 access_role_select_box">
                <label className="form-label">Select Role Name</label>
                <select
                  className={`form-select ${roleName ? "" : "add"}`}
                  aria-label="Default select example"
                  value={roleName}
                  onChange={(e) => handleRole(e.target.value)}
                >
                  <option value=""> Select Role Name</option>
                  {roleList &&
                    roleList?.map((data, i) => (
                      <option key={i} value={data?.RoleId}>
                        {data?.RoleName}
                      </option>
                    ))}
                </select>
                {derror?.rol && (
                  <span role="alert" className="error_text">
                    {derror?.rol}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="add_family">
                <CheckboxTree
                  nodes={mlist}
                  checked={checked}
                  expanded={expanded}
                  onCheck={onSelect}
                  // onCheck={(checked) => setChecked(checked)}
                  onExpand={(expanded) => setExpanded(expanded)}
                //   icons={{
                //     check: <MdCheckBox style={{width:'16px'}} className="rct-icon-check" />,
                //     uncheck: <MdCheckBoxOutlineBlank style={{width:'16px'}} className=" rct-icon-uncheck" />,
                //     halfCheck: <MdIndeterminateCheckBox style={{width:'16px'}} className=" rct-icon-half-check" />,
                //     expandClose:<MdChevronRight style={{width:'16px'}} className=" rct-icon-expand-close" />,
                //     expandOpen:<MdKeyboardArrowDown style={{width:'16px'}} className=" rct-icon-expand-open" />,
                //     expandAll: <MdAddBox style={{width:'16px'}} className=" rct-icon-expand-all" />,
                //     collapseAll: <MdIndeterminateCheckBox style={{width:'16px'}} className=" rct-icon-collapse-all" />,
                //     parentClose: <MdFolder style={{width:'16px'}} className=" rct-icon-parent-close" />,
                //     parentOpen: <MdFolderOpen style={{width:'16px'}} className=" rct-icon-parent-open" />,
                //     leaf: <MdInsertDriveFile style={{width:'16px'}} className=" rct-icon-leaf-close" />
                // }}
                />
              </div>
              {/* <div className="table-reponsive table-box">
                <Table className="table-striped">
                  <thead>
                    <tr>
                      <th>Menu Id</th>
                      <th>Menu Name</th>
                      <th>Parent Menu Name</th>
                      <th>Description</th>
                      <th>
                        <label className="personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            disabled={fields?.length === 0 ? true : false}
                            checked={checkBoxVal}
                            onChange={(e) => {
                              handleAll(e.target.checked);
                              setCheckBoxVal(e.target.checked);
                            }}
                          />
                          <span className="checkmark"></span>{" "}
                        </label>{" "}
                        Access
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${roleName ? "" : "d-none"}`}
                        >
                          <td>{item?.menuid}</td>
                          <td>{item?.menuName}</td>
                          <td>{item?.parentMenu}</td>
                          <td>{item?.description}</td>
                          <td>
                            <label className="personal_detail_label">
                              <input
                                type="checkbox"
                                className="login_check"
                                disabled={
                                  item?.menuName === "Dashboard" ? true : false
                                }
                                {...register(`check.${index}.isAssigned`, {
                                  onChange: (e) =>
                                    handleMainCheck(e.target.checked, index),
                                })}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div> */}
            </div>
            {derror?.err_msg && (
              <span role="alert" className="error_text">
                {derror?.err_msg}
              </span>
            )}
            <div className="form-group col-md-12 d-flex mt-3 px-3">
              <Button
                className="cource_reschedule_button btn_space me-3"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
              <Button
                className="cancel_btn cource_reschedule_button btn_space"
                onClick={handlecancel}
              >
                cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(AccesssManagement);
