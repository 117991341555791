
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
// import storage from 'redux-persist/lib/storage/session'; // Use sessionStorage
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from "../reducers/user"
import loginReducer from "../reducers/login"
import ceu_cource from '../reducers/ceu_cource';
import SettingReducer from '../reducers/setting';
import CommonReducer from '../reducers/Common';
// import { encryptTransform } from 'redux-persist-transform-encrypt';

const reducers = combineReducers({
    user: userReducer,
    login: loginReducer,
    ceuCource: ceu_cource,
    setting: SettingReducer,
    Common: CommonReducer,

});

// const encryptor = encryptTransform({
//     secretKey: process.env.REACT_APP_SECRET,
//     onError: function (error) {
//         console.error(error);
//     },
// });

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined; // Reset state to clear everything
    }
    return reducers(state, action);
};


const persistConfig = {
    key: 'root',
    storage,
    // transforms: [encryptor], // Apply transformation
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

const persistor = persistStore(store);

export { persistor };
export default store;
