import React, { useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { GetCallApi } from "../../../../Action/Action";
import { useReactToPrint } from "react-to-print";

const figLogo = "/assets/fig_CROP.png"; // Update with the correct path to your logo

const PAGE_HEIGHT = 1056; // 11 inches in pixels (1 inch = 96px)
const darkBlueFont = "#003366"; // Define the dark blue font color

const EmployeeDetailsPage = ({ employee, onClose }) => {
  const [evaluations, setEvaluations] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const tableRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return tableRef.current;
  }, [tableRef.current]);
  let saveFileName = employee?.employeeName
    ? employee?.employeeName +
      " Evalution Report " +
      new Date().toISOString().split("T")[0]
    : "User Evaluation Report " + new Date().toISOString().split("T")[0];
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: saveFileName,
    removeAfterPrint: true,
  });
  useEffect(() => {
    if (employee?.userId) {
      const fetchData = async () => {
        try {
          const response = await GetCallApi({
            url: `/api/Report/GetEmployeeEvalutionQuestionList?userId=${employee?.userId}&evalutionSetupId=${employee?.evalutionSetupId}`,
          });
          const data = response.data.payload?.map((item, index) => ({
            ...item,
            no: index + 1,
            employeeTextAnswer:
              item.evalQuestionTypeName === "Text"
                ? item.textAnswer
                : item.evalQuestionTypeName === "Pass/Fail"
                ? item.trueFalse === true
                    ? "Pass"
                    : "Fail"
                : item.evalQuestionTypeName === "Score"
                ? item.score
                : null,
            employeeRating:
              item.evalQuestionTypeName === "Rating" ? item.rating : null,
          }));
          setEvaluations(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };
      setLoading(true);
      fetchData();
    }
  }, [employee]);
  // Split evaluations into pages based on approximate content size
  //   const itemsPerPage = 20; // Adjust this value to control the number of rows per page
  //   const paginatedEvaluations = [];
  //   for (let i = 0; i < evaluations?.length ?? 0; i += itemsPerPage) {
  //     paginatedEvaluations.push(evaluations.slice(i, i + itemsPerPage));
  //   }

  return (
    <Box
      sx={{
        // position: "fixed",
        // top: 0,
        // left: 0,
        // width: "100vw",
        // height: "100vh",
        backgroundColor: "#fdfdfd",
        // zIndex: 1300,
        overflowY: "auto",
        fontFamily: "'Poppins', sans-serif",
        color: darkBlueFont, // Set the font color
        minHeight: "auto !important;",
      }}
    >
      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "auto !important;",
        }}
      >
        <Button onClick={handlePrint} className="theme_btn">
          Print
        </Button>
        <Button
          onClick={onClose}
          className="Can_cel_btn"
          sx={{
            marginLeft: "1rem",
          }}
        >
          Close
        </Button>
        {/* <IconButton
          onClick={onClose}
          sx={{
            marginLeft: "1rem",
            // position: "absolute",
            // top: "10px",
            // right: "16px",
            //   zIndex: 1400,
            color: darkBlueFont,
          }}
        >
          <CloseIcon />
        </IconButton> */}
      </Box>
      {/* Close Button */}

      {/* Paginated Pages */}
      {/* {paginatedEvaluations.map((pageEvaluations, pageIndex) => ( */}
      <Box
        //   key={pageIndex}
        sx={{
          //   width: "816px", // 8.5 inches in pixels
          //   height: `${PAGE_HEIGHT}px`, // 11 inches in pixels
          margin: "1rem",
          //   margin: "16px auto",
          backgroundColor: "#fff",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          padding: "24px",
          pageBreakAfter: "always", // Ensures each box is a separate printable page
          boxSizing: "border-box",
          minHeight: "auto !important;",
        }}
        ref={tableRef}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
            minHeight: "auto !important;",
          }}
        >
          <img
            src={figLogo}
            alt="FIG Logo"
            style={{
              height: "60px",
              objectFit: "contain",
            }}
          />
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textAlign: "right",
              color: darkBlueFont,
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            Employee Evaluation Report
          </Typography>
        </Box>

        {/* Employee Details */}
        {/* {pageIndex === 0 && ( */}
        <Box
          sx={{
            marginBottom: "16px",
            padding: "16px",
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            border: "1px solid #ddd",
            minHeight: "auto !important;",
          }}
        >
          <Typography variant="body1">
            <strong>Name:</strong> {employee.userName}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> {employee.email}
          </Typography>
          <Typography variant="body1">
            <strong>District:</strong> {employee.districtName}
          </Typography>
          <Typography variant="body1">
            <strong>Site:</strong> {employee.schoolName}
          </Typography>{" "}
          <Typography variant="body1">
            <strong>Setup Title:</strong> {employee.setupTitle}
          </Typography>{" "}
          <Typography variant="body1">
            <strong>Evalution Group Name:</strong> {employee.evalutionGroupName}
          </Typography>
        </Box>
        {/* )} */}

        {/* Evaluation Table */}
        {evaluations?.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "#fff",
              border: "1px solid #ddd",
              borderRadius: "8px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f2f2f2" }}>
                  <TableCell sx={{ fontWeight: "bold", color: darkBlueFont }}>
                    Question Title
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: darkBlueFont }}>
                    Question Type
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: darkBlueFont }}>
                    Answer
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: darkBlueFont }}>
                    Rating
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {evaluations?.map((evaluation, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    }}
                  >
                    <TableCell sx={{ color: darkBlueFont }}>
                      {evaluation.evalQuestionTitle}
                    </TableCell>
                    <TableCell sx={{ color: darkBlueFont }}>
                      {evaluation.evalQuestionTypeName}
                    </TableCell>
                    <TableCell sx={{ color: darkBlueFont }}>
                      {evaluation.employeeTextAnswer || "N/A"}
                    </TableCell>
                    <TableCell sx={{ color: darkBlueFont }}>
                      {evaluation.employeeRating || "N/A"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Footer Section */}
        {/* {pageIndex === paginatedEvaluations.length - 1 && ( */}
        <Box
          sx={{
            marginTop: "16px",
            borderTop: "2px solid #ddd",
            paddingTop: "16px",
            minHeight: "auto !important;",
          }}
        >
          <Typography
            variant="body1"
            sx={{ marginBottom: "16px", color: darkBlueFont }}
          >
            <strong>Employee Signature:</strong>
          </Typography>
          <Box
            sx={{
              height: "40px",
              borderBottom: `1px solid ${darkBlueFont}`,
              marginBottom: "8px",
              width: "300px",
              minHeight: "auto !important;",
            }}
          >
            {employee.signatureImageUrl ? (
              <img
                src={employee.signatureImageUrl}
                alt="Employee Signature"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              ""
            )}
          </Box>
          <Typography variant="caption" sx={{ color: darkBlueFont }}>
            This document is a formal record of the employee's evaluation and
            must be signed and dated.
          </Typography>
        </Box>
        {/* )} */}
      </Box>
      {/* ))} */}

      {/* Print Button */}
    </Box>
  );
};

export default EmployeeDetailsPage;
