import axios from "axios";
import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import start_cou_img from "../../../../../images/Course_start_lesson_img_new.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostCallApi } from "../../../../../Action/Action";
import { SwalError, SwalSuccess } from "../../../../../components/SwalToast";

function Quiz_start(props) {
  const { mainTitle, quizList, quizId, setQuizId, CourseID } = props;
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  let nav = useNavigate();
  let location = useLocation();
  let { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const { fields } = useFieldArray({
    control,
    name: "quizQuestion",
  });
  useEffect(() => {
    let quMain = [];
    quizList?.map((item) => {
      if (item?.quizId === quizId) {
        if (item?.quizQuestion?.length > 0) {
          quMain = item?.quizQuestion?.map((item) => {
            if (item?.questionTypeId === 1) {
              return {
                questionId: item?.questionId,
                choise: item?.questionTypeId,
                question: item?.questionTitle,
                option1: item?.option1,
                option2: item?.option2,
                option3: item?.option3,
                option4: item?.option4,
                ans_1: "",
              };
            } else if (item?.questionTypeId === 2) {
              return {
                questionId: item?.questionId,
                choise: item?.questionTypeId,
                question: item?.questionTitle,
                ans_1: "",
              };
            } else if (item?.questionTypeId === 3) {
              return {
                questionId: item?.questionId,
                choise: item?.questionTypeId,
                question: item?.questionTitle,
                ans_1: "",
              };
            }
            return "";
          });
        }
      }
      return "";
    });
    setValue("quizQuestion", quMain);
    // eslint-disable-next-line
  }, [quizId]);

  const onSubmit = async (data) => {
    let ques = data?.quizQuestion?.map((item) => {
      return {
        questionId: item?.questionId,
        answer: item?.ans_1,
      };
    });
    let qData = {
      userId: LogUserId,
      courseId: CourseID,
      quizId: quizId,
      status: "Completed",
      userQuizQuestion: ques,
      createdBy: LogUserId,
      contentType: "Quiz",
    };
    let response = await PostCallApi({
      url: `/api/UserQuizQuestionProgress/CreateUserQuizQuestionProgress`,
      body: qData,
    })

    if (response?.status === 200 && response?.data.success) {
      let qstatus = quizList?.find((item) =>
        item?.quizId === quizId);
      let cqvId = qstatus?.id + 1 > quizList?.length ? "" : quizList[qstatus?.id];


      let today = new Date();
      let userCourseEndData = {
        // userCourseProgressId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        contentType: "Quiz",
        contentId: quizId,
        status: "Completed",
        endDate: convert1(today),
        courseId: CourseID,
        userId: LogUserId,
        createdBy: LogUserId,
      };
      let res2 = await PostCallApi({
        url:
          `/api/UserCourseProgress/CompleteCourseContentEntry`,
        body: userCourseEndData,
      })

      if (res2?.status === 200 && res2?.data.success) {
        let msg = await titleCase(res2.data?.message)
        SwalSuccess(msg)

      } else {
        SwalError(res2?.data?.message)
      }

      function convert1(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
      if (cqvId) {
        let userCourseData = {
          // userCourseProgressId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          contentType: "Quiz",
          contentId: cqvId?.quizId,
          status: "InProgress",
          startDate: convert1(today),
          courseId: CourseID,
          userId: LogUserId,
          createdBy: LogUserId,
        };
        await PostCallApi({
          url: `/api/UserCourseProgress/CompleteCourseContentEntry`,
          body: userCourseData,
        })
        setQuizId(cqvId?.quizId);
      } else {
        let res3 = await PostCallApi({
          url: `/api/Ceu/CompleteCourse?courseId=${CourseID}&userId=${LogUserId}`,
          body: {},
        })
        if (res3?.status === 200) {
          if (res3.data.success) {

            SwalSuccess(res3?.data?.message)
            nav(
              `/${location?.pathname?.split("/")[1]
              }/success-course/${id}`,
              {
                replace: true,
              }
            );
          }
        }
        setQuizId("");
      }
    }

    //
  };
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  return (
    <>
      <div className="CourseRightStart_page">
        <div className="course_details_right_main_box">
          <div className="start_quiz_course">
            {quizList?.map((item, i) => {
              if (item?.quizId === quizId) {
                return (
                  <React.Fragment key={i}>
                    <div className="course_right_heading_box">
                      <div className="head_left">
                        <span>{mainTitle}</span>
                      </div>
                      <div className="head_right">
                        Quiz {i + 1} of {quizList?.length}
                      </div>
                    </div>

                    <div className="head_text p_16_text">
                      Please complete this assessment to be awarded your CEU.
                    </div>
                    <div className="quiz_question_box">
                      {fields?.map((item, index) => {
                        return (
                          <>
                            <h6
                              className={`h6_text ${index === 1 ? "mt-3" : ""}`}
                            >
                              {item?.question}
                            </h6>
                            {item?.choise === 1 ? (
                              <>
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="A"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`,
                                        {
                                          required: "Please Select One Option",
                                        }
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>{item?.option1}</p>
                                </div>{" "}
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="B"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`,
                                        {
                                          required: "Please Select One Option",
                                        }
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>{item?.option2}</p>
                                </div>{" "}
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="C"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`,
                                        {
                                          required: "Please Select One Option",
                                        }
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>{item?.option3}</p>
                                </div>{" "}
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="D"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`,
                                        {
                                          required: "Please Select One Option",
                                        }
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>{item?.option4}</p>
                                </div>
                                {errors.quizQuestion?.[index]?.ans_1 && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors.quizQuestion?.[index]?.ans_1
                                        .message
                                    }
                                  </span>
                                )}
                              </>
                            ) : item?.choise === 2 ? (
                              <>
                                <div className="form-group col-md-12 ">
                                  <textarea
                                    type="text"
                                    rows={5}
                                    className="form-control"
                                    placeholder="Long Answer Text"
                                    {...register(
                                      `quizQuestion.${index}.ans_1`,
                                      {
                                        required: "This field is required",
                                      }
                                    )}
                                  />
                                  {errors.quizQuestion?.[index]?.ans_1 && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.quizQuestion?.[index]?.ans_1
                                          .message
                                      }
                                    </span>
                                  )}
                                </div>
                              </>
                            ) : item?.choise === 3 ? (
                              <>
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="true"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`,
                                        {
                                          required: "Please Select One Option",
                                        }
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>True</p>
                                </div>
                                <div className="quiz_ans_select_box">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="quiz_select"
                                      value="false"
                                      {...register(
                                        `quizQuestion.${index}.ans_1`,
                                        {
                                          required: "Please Select One Option",
                                        }
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  <p>False</p>
                                </div>{" "}
                                {errors.quizQuestion?.[index]?.ans_1 && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors.quizQuestion?.[index]?.ans_1
                                        .message
                                    }
                                  </span>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                      <div className="text-end">
                        <button
                          className="quiz_finish_btn p_16_text mt-2"
                          onClick={handleSubmit(onSubmit)}
                        >
                          {quizList?.length - 1 === i
                            ? "Finish Quiz"
                            : "Next Quiz"}
                        </button>
                      </div>
                    </div>
                    {/* <div className="quiz_ans_type">
                    <p className="question_for_u">
                      How could this presentation be improved?
                    </p>
                    <textarea
                      name=""
                      id=""
                      className="form-control"
                      rows="10"
                      placeholder="Type your response here"
                      {...register("quiz_res")}
                    ></textarea>
                    <p className="p_16_text">
                      This response will be reviewed and graded after
                      submission.
                    </p>
                    <div className="text-end">
                      <button className="quiz_finish_btn p_16_text">
                        finish Quiz
                      </button>
                    </div>
                  </div> */}
                  </React.Fragment>
                );
              }
              return "";
            })}
            {quizId === "" ? (
              <img src={start_cou_img} alt="" className="img-fluid" />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(Quiz_start);
