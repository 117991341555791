import React, { Suspense, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import "./index.scss";
import { Table, TableBody, TableHead, TableRow, styled } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { useNavigate, useParams } from "react-router-dom";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import Config from "../../../Utils/Config.jsx";
import { GetCallApi } from "../../../Action/Action.jsx";
import Swal from "sweetalert2";
import jsPDF from "jspdf";

const renderLoader = () => <p></p>;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  fontFamily: `"Poppins", sans-serif`,
  borderBottom: "0px !important",
  "&.pdf_download": {
    fontSize: "12px !important",
    padding: "3px !important",
    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  "&.odd": {
    backgroundColor: "#ffffff",
  },
  "&.even": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child StyledTableCell, &:last-child th": {
    border: 0,
  },
  "&.pdf_download": {
    fontSize: "12px !important",
    fontWeight: "300 !important",
    padding: "3px !important",
  },
}));
function User_Report() {
  const { id } = useParams();
  const [usRepo, setUsRepo] = useState({});
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  // const LogUserId = useSelector((state) => state.login?.LoginDetails.userId);
  const [acdYS, setAcdYS] = useState("");
  const [acdYList, setAcdYList] = useState("");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [downloadPdf, setDownloadpdf] = useState(false);
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const LogUserd = useSelector((state) => state.login.LoginDetails);

  let nav = useNavigate();
  const [courseList, setCourseListData] = useState([
    {
      courseName: "NA",
      ceu: "NA",
      dateComplete: "NA",
      category: "NA",
      status: "NA",
    },
  ]);
  const [mDocList, setMDocList] = useState([
    {
      courseName: "NA",
      ceumaterialUrl: "NA",
    },
  ]);

  const [CertificateList, setCertificateListData] = useState([
    {
      certificateName: "NA",
      courseName: "NA",
    },
  ]);
  useEffect(() => {
    getAcademicYearList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (id) {
      if (acdYList?.length > 0) {
        getReportData(id, acdYS);
      }
    }
    // eslint-disable-next-line
  }, [id, acdYList]);

  const getAcademicYearList = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/SchoolAcademicYear/GetSchoolAcademicYearList`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          const user = response?.data.payload?.map((item, i) => {
            return {
              academicYearId: item?.academicYearId ? item?.academicYearId : "",
              academicYear: item?.academicYear ? item?.academicYear : "",
              isCurrentYear: item?.isCurrentYear ? "True" : "False",
            };
          });
          setAcdYList(user);
          let curtY = user?.filter((x) => x.isCurrentYear === "True");
          setAcdYS(curtY[0]?.academicYearId);
        }
      });
  };
  const getReportData = async (id, ayId) => {
    setOpen(true);
    // axios
    //   .get(
    //     `${Config.API_HOST_URL_live}/api/Report/GetSubscriberReport?userId=${id}&academicYearId=${ayId}`,
    //     { headers }
    //   )
    //   .then((response) => {
    //     if (response.data.success) {
    const sendData = {
      url: `/api/Report/GetSubscriberReport?userId=${id}&academicYearId=${ayId}`,
      headers: headers,
    };

    let response = await GetCallApi(sendData);
    setOpen(false);
    if (response?.status === 200) {
      if (response.data.success) {
        const dashData = response.data?.payload;
        const userReport = {
          name:
            dashData?.userName === "" || dashData?.userName === null
              ? ""
              : dashData?.userName,
          phone:
            dashData?.phone === "" || dashData?.phone === null
              ? ""
              : dashData.phone,
          email:
            dashData?.email === "" || dashData?.email === null
              ? ""
              : dashData?.email,
          jobTitle:
            dashData?.jobTitle === "" || dashData?.jobTitle === null
              ? ""
              : dashData?.jobTitle,
          roleName:
            dashData?.roleName === "" || dashData?.roleName === null
              ? ""
              : dashData?.roleName,
          joiningDate:
            dashData?.joiningDate === "" || dashData?.joiningDate === null
              ? ""
              : convert(dashData?.joiningDate?.split("T")[0]),
          dateOfBirth:
            dashData?.dateOfBirth === "" || dashData?.dateOfBirth === null
              ? ""
              : convert(dashData?.dateOfBirth?.split("T")[0]),
          courseCom:
            dashData?.allocatedVsCompleted === "" ||
            dashData?.allocatedVsCompleted === null
              ? ""
              : dashData?.allocatedVsCompleted,
          address:
            dashData?.address === "" || dashData?.address === null
              ? ""
              : dashData?.address,
          ceuEarned:
            dashData?.ceuEarned === "" || dashData?.ceuEarned === null
              ? ""
              : dashData?.ceuEarned,
          ceuRequired:
            dashData?.ceuRequired === "" || dashData?.ceuRequired === null
              ? ""
              : dashData?.ceuRequired,
          ceuDifference:
            dashData?.ceuDifference === "" || dashData?.ceuDifference === null
              ? ""
              : dashData?.ceuDifference,
          totalUserVedioLength:
            dashData?.totalUserVedioLength === "" ||
            dashData?.totalUserVedioLength === null
              ? ""
              : dashData?.totalUserVedioLength,
          // courseName: dashData.courseList[0].courseName === "" || dashData.courseList[0].courseName === null ? "" : dashData.courseList[0].courseName,
        };
        setUsRepo(userReport);
        const courseList = dashData.courseList?.map((item, i) => {
          return {
            courseName:
              item?.courseName === "" || item?.courseName === null
                ? ""
                : item?.courseName,
            ceu: item?.ceu === "" || item?.ceu === null ? "" : item?.ceu,
            instructor:
              item?.instructorName === "" || item?.instructorName === null
                ? ""
                : item?.instructorName,
            trainingLength:
              item?.trainingLength === "" || item?.trainingLength === null
                ? ""
                : item?.trainingLength,
            dateComplete:
              item?.dateComplete === "" || item?.dateComplete === null
                ? ""
                : convert(item?.dateComplete?.split("T")[0]),
            categories: item?.categories ? item?.categories : "",
            status:
              item?.status === "" || item?.status === null ? "" : item?.status,
            ceumaterialUrl:
              item?.ceumaterialUrl === "" || item?.ceumaterialUrl === null
                ? ""
                : item?.ceumaterialUrl,
            userCourseDocument: item?.userCourseDocument,
          };
        });
        courseList.sort((a, b) => {
          const nameA = new Date(a.dateComplete);
          const nameB = new Date(b.dateComplete);
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setCourseListData(courseList);
        let manualDList = courseList?.filter((x) => x.ceumaterialUrl !== "");

        // console.log([
        //   ...courseList?.filter((x) => x?.userCourseDocument !== null),
        //   ...manualDList,
        // ]);
        let cs = courseList?.filter((x) => x?.userCourseDocument !== null);
        cs = cs.filter((x) => x?.userCourseDocument?.length > 0);
        setMDocList([...cs, ...manualDList]);
        const certificates = dashData.certificates?.map((item, i) => {
          return {
            courseId:
              item?.courseId === "" || item?.courseId === null
                ? ""
                : item?.courseId,
            courseName:
              item?.courseName === "" || item?.courseName === null
                ? ""
                : item?.courseName,
            certificateName:
              item?.certificateName === "" || item?.certificateName === null
                ? ""
                : item?.certificateName,
          };
        });
        setCertificateListData(certificates);
        // } else {
        //   setOpen(false);
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: response?.data?.message
            ? response?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: response?.data?.message
          ? response?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
    // });
  };

  const handleRedirectCer = (Couid) => {
    nav(`/certificate/${Couid}/${id}/${acdYS}`, {
      replace: true,
    });
  };
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const handleAcademicYear = (e) => {
    setAcdYS(e);
    if (e) {
      getReportData(id, e);
    } else {
      setUsRepo([]);
      setCourseListData([]);
      setMDocList([]);
      setCertificateListData([]);
    }
  };
  const tableRef = useRef(null);
  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);
  const handleDownloadPdf = async () => {
    // return false;
    setOpen(true);

    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`Individual Subscriber Report.pdf`);
        setOpen(false);

        setDownloadpdf(false);
      });
  };
  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };
  let Time = new Date();
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <section className="certificate_page main_backcolor">
          <div className="container-fluid">
            <div className="main_wrapper">
              <div className="col-md-12 m-auto">
                <div className="certificate_wrapper">
                  <div className="title text-center mb-5 d-flex w-100 justify-content-between">
                    <select
                      name=""
                      id=""
                      className="form-select download_drop w-25"
                      value={acdYS}
                      onChange={(e) => handleAcademicYear(e.target.value)}
                    >
                      <option value="">Select Academic Year</option>
                      {acdYList &&
                        acdYList?.map((acy, i) => (
                          <option value={acy.academicYearId} key={i}>
                            {acy.academicYear}
                          </option>
                        ))}
                    </select>
                    <h1 className="m-auto w-100">
                      Individual Subscriber Report
                    </h1>
                    <div className="w-25 text-end">
                      {/* <ReactToPrint
                        trigger={() => (
                          <Button className="print_btn">Print</Button>
                        )}
                        content={() => componentRef}
                      /> */}
                      <Button
                        className="print_btn"
                        onClick={() => setDownloadpdf(true)}
                      >
                        PDF
                      </Button>
                    </div>
                  </div>
                  <div
                    className="certificate_contect"
                    ref={tableRef}
                    style={back}
                  >
                    {downloadPdf && (
                      <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                        <img
                          src={require("../../../images/Icon/20211.png")}
                          alt=""
                        />
                        <div>
                          <label
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            Individual Subscriber Report
                          </label>

                          <div
                            className="names"
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            {" "}
                            {convertDate(
                              Time.toLocaleString("en-us", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
                            )}{" "}
                            {Time.toLocaleTimeString()}
                          </div>
                          <div
                            className="names"
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                            }}
                          >
                            Report Generated By:{" "}
                            {LogUserd?.firstName + " " + LogUserd?.lastName}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="table-responsive mb-5">
                      <Table className="data_table">
                        <TableBody>
                          <StyledTableRow className="table_pading">
                            <StyledTableCell className="emp_name">
                              Employee Name
                            </StyledTableCell>
                            <StyledTableCell className="name">
                              {usRepo?.name}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow className="table_pading">
                            <StyledTableCell>Hire Date</StyledTableCell>
                            <StyledTableCell className="name">
                              {usRepo?.joiningDate}
                            </StyledTableCell>
                            <StyledTableCell>Phone Number</StyledTableCell>
                            <StyledTableCell className="name">
                              {usRepo?.phone}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow className="table_pading">
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell className="name">
                              {usRepo?.email}
                            </StyledTableCell>
                            <StyledTableCell>Job Title</StyledTableCell>
                            <StyledTableCell className="name">
                              {usRepo?.jobTitle}
                            </StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow className="table_pading">
                            <StyledTableCell>Role</StyledTableCell>
                            <StyledTableCell className="name">
                              {usRepo?.roleName}
                            </StyledTableCell>
                            <StyledTableCell>Site/Location</StyledTableCell>
                            <StyledTableCell>{usRepo.address}</StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </div>
                    <div className="table-responsive mb-5">
                      <Table className="data_table table-striped">
                        <TableHead>
                          <TableRow className="table_pading pdf_download">
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              Course Name
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              CEU
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              Date Completed
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              Instructor Name
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              Training Length
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              Category
                            </StyledTableCell>
                            {/* <th>Document</th> */}
                            {/* <th>Status</th> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {courseList?.map((data, i) => (
                            <StyledTableRow
                              className="table_pading pdf_download"
                              key={i + 1}
                            >
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {data?.courseName}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {data?.ceu}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {data?.dateComplete}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {data?.instructor}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {data?.trainingLength}
                              </StyledTableCell>
                              <StyledTableCell className="line_break pdf_download">
                                {data?.categories}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                          <StyledTableRow className="table_pading pdf_download">
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                              colSpan={6}
                            >
                              Total Courses Length:{" "}
                              {usRepo?.totalUserVedioLength}
                            </StyledTableCell>
                            {/* <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell> */}
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </div>
                    <div className="table-responsive mb-5">
                      <Table className="data_table table-striped">
                        <TableHead>
                          <TableRow className="table_pading pdf_download">
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              CEU Earned
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              CEU Required
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              Difference
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow className="table_pading pdf_download">
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              {usRepo?.ceuEarned}
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              {usRepo?.ceuRequired}
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              {usRepo?.ceuDifference}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </div>

                    <div className="table-responsive mb-5">
                      <Table className="data_table table-striped">
                        <TableHead>
                          <TableRow className="table_pading">
                            <StyledTableCell
                              style={{ width: "80%" }}
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              Course Name
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ width: "20%" }}
                              className={`${downloadPdf && "pdf_download"}`}
                            >
                              Certificates
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {CertificateList?.map((data, i) => (
                            <StyledTableRow
                              className={`table_pading ${
                                downloadPdf && "pdf_download"
                              }`}
                              key={i + 1}
                            >
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {data?.courseName}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                <div
                                  onClick={() =>
                                    handleRedirectCer(data?.courseId)
                                  }
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  View Certificate
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    <div className="table-responsive">
                      <Table className="data_table table-striped">
                        <TableHead>
                          <TableRow
                            className={`table_pading ${
                              downloadPdf && "pdf_download"
                            }`}
                          >
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                              style={{ width: "80%" }}
                            >
                              Course Name
                            </StyledTableCell>
                            <StyledTableCell
                              className={`${downloadPdf && "pdf_download"}`}
                              style={{ width: "20%" }}
                            >
                              Document
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {mDocList?.map((data, i) => (
                            <StyledTableRow
                              className={`table_pading ${
                                downloadPdf && "pdf_download"
                              }`}
                              key={i + 1}
                            >
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {data?.courseName}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {data.ceumaterialUrl ? (
                                  <a
                                    href={`${Config.API_HOST_URL_live}${data.ceumaterialUrl}`}
                                    style={{
                                      color: "blue",
                                      textDecoration: "none",
                                    }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View Document
                                  </a>
                                ) : (
                                  <>
                                    {data?.userCourseDocument?.map((x, i) => (
                                      <div key={i}>
                                        <a
                                          href={`${Config.API_HOST_URL_live}${x}`}
                                          style={{
                                            color: "blue",
                                            textDecoration: "none",
                                          }}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          View Document
                                        </a>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}
export default React.memo(User_Report);
