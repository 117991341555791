import { createSlice } from "@reduxjs/toolkit";

const initialState = { ATRC: {}, DashSelection: {} };

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    AttendeeRegistrationConference(state, action) {
      state.ATRC = action.payload;
    },
    AcademicYearandSchoolDistrictSelection(state, action) {
      state.DashSelection = action.payload;
    },
    resetCommon: () => initialState,
  },
});

export const {
  AttendeeRegistrationConference,
  AcademicYearandSchoolDistrictSelection,
  resetCommon,
} = commonSlice.actions;

export default commonSlice.reducer;
