import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import axios from "axios";
import Config from "../../../../Utils/Config";
import { CreteDataFor, DashboardRe } from "../../../../reducers/user";
import Swal from "sweetalert2";
import Select from "react-select";
import { Tab, Box, CircularProgress, Backdrop, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import GroupCeu from "./GroupCeu";
import uploadimg from "../../../../images/Icon/file-upload.svg";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import header_logo from "../../../../images/Icon/20211.png";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { GetCallApi } from "../../../../Action/Action";

function AddCEUPopup({ handleClose }) {
  const [derrors, setDerrors] = useState({});
  const [dateCompletion, setDateCompletion] = useState();
  const [dateReminder, setDateReminder] = useState();
  const [dateExpiration, setDateExpiration] = useState();
  const [courseCode, setCourseCode] = useState([]);
  const [courseCodeList, setCourseCodeList] = useState([]);
  const [courseLear, setCourseLear] = useState([]);
  const [courseLearList, setCourseLearList] = useState([]);
  const [courseTrai, setCourseTrai] = useState([]);
  const [courseTraiList, setCourseTraiList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [schList, setSchList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [users, setUsers] = useState([]);
  const [values, setValues] = React.useState("1");
  const handleChangeTab = (event, newValue) => {
    setValues(newValue);
  };
  const tableRef = useRef(null);

  const [allDetail, setAllDetail] = useState();
  const [sendBoxVal, setSendBoxVal] = useState(false);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const [openCircle, setOpenCircle] = useState(false);
  const [isExpiringCertificate, setIsExpiringCertificate] = useState(false);

  const LogUserId = useSelector((state) => state.login.LoginDetails);
  let changeDash = useSelector((state) => state.user.Drefresh);
  let dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let Location = useLocation();
  // useEffect(() => {
  //   if (sendBoxVal) {
  //     handleDownloadPdf();
  //   }
  // }, [sendBoxVal]);
  console.log(errors)
  const onSubmit = (data) => {
    let is_success = true;
    if (users?.length > 0) {
      setDerrors({ userId: "" });
    } else {
      is_success = false;
      setDerrors({ userId: "Please select User" });
    }
    if (is_success) {
      if(isExpiringCertificate===true){
        if (dateCompletion) {
          setDerrors({ D: "" });
          if (dateReminder) {
            setDerrors({ re: "" });
            if (dateExpiration) {
              setDerrors({ Dex: "" });
            } else {
              setDerrors({ Dex: "Select Date of Expiration" });
              is_success = false;
            }
          } else {
            setDerrors({ re: "Select Date of Reminder" });
            is_success = false;
          }
        } else {
          setDerrors({ D: "Select Date Completion" });
          is_success = false;
        }
        if (dateCompletion >= dateExpiration) {
          is_success = false;
          setDerrors({
            Dex: "Completion Date can not greaterthan from  Date of Expiration",
          });
        } else if (dateReminder >= dateExpiration) {
          is_success = false;
          setDerrors({
            Dex: "Reminder Date can not greaterthan from  Date of Expiration",
          });
        }
      }

      let ceuTransaction = [];
      courseCode?.map((item) => {
        let cDE = {
          courseCategoryId: item,
          categoryTypeId: 1,
        };
        return ceuTransaction.push(cDE);
      });
      courseLear?.map((item) => {
        let cDE = {
          courseCategoryId: item,
          categoryTypeId: 2,
        };
        return ceuTransaction.push(cDE);
      });
      courseTrai?.map((item) => {
        let cDE = {
          courseCategoryId: item,
          categoryTypeId: 3,
        };
        return ceuTransaction.push(cDE);
      });
      let ayId = schList?.map((ac) => {
        if (ac.academicYear === data.schoolYear) {
          return ac.academicYearId;
        }
        return "";
      });
      ayId = ayId?.filter((ay) => ay !== "")[0];
      let ceuTransaction2 = [];
      courseCode?.map((item) => {
        let code = courseCodeList?.filter((x) => x.value === item)[0]?.code;
        let cDE = {
          courseCategoryCode: code,
          categoryTypeId: 1,
        };
        return ceuTransaction2.push(cDE);
      });

      courseLear?.map((item) => {
        let code = courseLearList?.filter((x) => x.value === item)[0]?.code;
        let cDE = {
          courseCategoryCode: code,
          categoryTypeId: 2,
        };
        return ceuTransaction2.push(cDE);
      });
      courseTrai?.map((item) => {
        let code = courseTraiList?.filter((x) => x.value === item)[0]?.code;
        let cDE = {
          courseCategoryCode: code,
          categoryTypeId: 3,
        };
        return ceuTransaction2.push(cDE);
      });
      let ceuData = {
        ...data,
        users: users?.map((x) => x.value),
        AcademicYearId: ayId,
        createdBy: LogUserId.userId,
        completionDate: dateCompletion ? convert(dateCompletion) : null,
        dateOfExpiration: dateExpiration ? convert(dateExpiration) : null,
        reminderOfExpiry: dateReminder ? convert(dateReminder) : null,
        ceuTransaction: ceuTransaction,
        ceuTransaction2: ceuTransaction2,
      };
      setAllDetail(ceuData);

      if(is_success===true){
        setOpenCircle(true);
      axios.post(`${Config.API_HOST_URL_live}/api/Ceu/CreateCEU`, ceuData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        })
        .then((response) => {
          if (response.data.success) {
            setOpenCircle(false);
            if (ceuData?.status === "Draft") {
              setSendBoxVal(true);
            } else {
              handleDownCLose();
            }
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: titleCase(response.data?.message),
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          } else {
            setOpenCircle(false);
          }
        });
      }  
    }
  };
  useEffect(() => {
    getCategory();
    getSchoolYear();
    // if (LogUserId.roleId === 1) {
    getSchoolList();
    // } else {
    // getUerList(LogUserId?.schoolDistrictId);
    // }
    // eslint-disable-next-line
  }, []);
  const getCategory = () => {
    axios
      .get(`${Config.API_HOST_URL_live}/api/CourseCategory/GetCategoryList?createdBy=${LogUserId.userId}`, {
        headers,
      })
      .then((response) => {
        if (response.data.success) {
          const listdata = response.data;
          const main = listdata.mainCategory?.map((item) => {
            return {
              value:
                item?.courseCategoryId === "" || item?.courseCategoryId === null
                  ? ""
                  : item?.courseCategoryId,
              label:
                item?.courseCategoryCode === "" ||
                  item?.courseCategoryCode === null ||
                  item?.courseCategoryName === "" ||
                  item?.courseCategoryName === null
                  ? ""
                  : item?.courseCategoryCode + " - " + item?.courseCategoryName,
              code: item?.courseCategoryCode,
            };
          });
          const lear = listdata.learningObjective?.map((item) => {
            return {
              value:
                item?.courseCategoryId === "" || item?.courseCategoryId === null
                  ? ""
                  : item.courseCategoryId,
              label:
                item?.courseCategoryCode === "" ||
                  item?.courseCategoryCode === null ||
                  item?.courseCategoryName === "" ||
                  item?.courseCategoryName === null
                  ? ""
                  : item.courseCategoryCode + " - " + item.courseCategoryName,
              code: item?.courseCategoryCode,
            };
          });
          const trai = listdata.trainingTopic?.map((item) => {
            return {
              value:
                item?.courseCategoryId === "" || item?.courseCategoryId === null
                  ? ""
                  : item.courseCategoryId,
              label:
                item?.courseCategoryCode === "" ||
                  item?.courseCategoryCode === null ||
                  item?.courseCategoryName === "" ||
                  item?.courseCategoryName === null
                  ? ""
                  : item.courseCategoryCode + " - " + item.courseCategoryName,
              code: item?.courseCategoryCode,
            };
          });
          setCourseCodeList(main);
          setCourseLearList(lear);
          setCourseTraiList(trai);
        }
      });
  };
  const getUerList = async (id) => {
    if (id) {
      setUsers([]);
      // let url = `${Config.API_HOST_URL_live}/api/User/GetUserBySchoolDistrictId?schoolDistrictId=${id}`;
      // axios
      //   .get(`${url}`, { headers })
      //   .then((response) => {
      let seData = {
        url: `/api/User/GetUserListSchoolDistrictIdByRoleWise?schoolDistrictId=${id}&userId=${LogUserId?.userId}`,
        // url: `/api/User/GetUserBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogUserId?.userId}`,
        headers: headers
      }
      const response = await GetCallApi(seData)
      if (response?.status === 200) {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              value:
                item?.userId === "" || item?.userId === null
                  ? ""
                  : item.userId,
              label:
                item?.firstName === "" ||
                  item?.firstName === null ||
                  item?.lastName === "" ||
                  item?.lastName === null
                  ? ""
                  : item.firstName + " " + item.lastName,
              col0: item.firstName + " " + item.lastName,
              col3: item?.siteLocation,
            };
          });

          let uslist = [{ value: "all", label: "Select All" }, ...data];
          setUserList(uslist);
        } else {
          setUserList([]);
        }
      } else {
        setUserList([]);
      }
      // })
      // .catch((error) => console.log(error));
    } else {
      setUserList([]);
    }
  };
  const getSchoolYear = () => {
    axios
      .get(
        `${Config.API_HOST_URL_live}/api/SchoolAcademicYear/GetSchoolAcademicYearList`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.success) {
          const user = response?.data.payload?.map((item, i) => {
            return {
              academicYearId: item?.academicYearId ? item?.academicYearId : "",
              academicYear: item?.academicYear ? item?.academicYear : "",
              isCurrentYear: item?.isCurrentYear ? "True" : "False",
            };
          });
          setSchList(user);
        }
      });

    // function convertYear(str) {
    //   var date = new Date(str);
    //   return date.getFullYear();
    // }
    // Date.prototype.addYears = function (n) {
    //   var now = new Date();
    //   return new Date(now?.setFullYear(now?.getFullYear() - n));
    // };
    // let schY = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]?.map((item, i) => {
    //   return {
    //     year:
    //       convertYear(new Date().addYears(12 - i)) +
    //       "-" +
    //       convertYear(new Date().addYears(12 - (i + 1))),
    //   };
    // });
    // setSchList(schY);
  };
  const handleChangeUsersMulti = (e) => {
    setUsers(e);
  };
  const handleChangeCourceMulti = (e) => {
    setCourseCode(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleChangeLearMulti = (e) => {
    setCourseLear(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleChangeTraiMulti = (e) => {
    setCourseTrai(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const getSchoolList = () => {
    let url = `${Config.API_HOST_URL_live}/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogUserId?.userId}`;
    axios
      .get(`${url}`, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              schId:
                item?.schoolDistrictId === "" || item?.schoolDistrictId === null
                  ? ""
                  : item.schoolDistrictId,
              schName:
                item?.districtName === "" || item?.districtName === null
                  ? ""
                  : item.districtName,
            };
          });
          setSchoolList(data);
          if (data?.length === 1) {
            setTimeout(() => {
              setValue("schoolDistrictId", data[0]?.schId);
              getUerList(data[0]?.schId);
            }, 1000);
          }
        }
      })
      .catch((error) => console.log(error));
  };
  const handleImage = async (e) => {
    var file = e.target?.files[0];
    if (file.size < 5253880) {
      setValue("MaterialsAttachment", file);
      setValue("imgUpload", file.name);
      setDerrors({ img_err: "" });
    } else {
      setDerrors({ img_err: "Please Upload Max 5MB File" });
    }
  };
  const settingClose = () => {
    if (Location.pathname === "/settings") {
      dispatch(CreteDataFor(false));
    } else {
      handleClose();
    }
  };
  const back = {
    maxWidth: "200mm",
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "auto",
  };
  let Time = new Date();
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const headCellsVal = [
    {
      id: "col0",
      label: "Printed Name",
    },
    {
      id: "col3",
      label: "Site Location",
    },
    {
      id: "sign",
      label: "Signature",
    },
  ];
  const StyledTableCellVal = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F1F6FC",
    },
    "&.pdf": {
      fontWeight: "400",
      fontSize: "12px",
      padding: "5px",
    },
  }));

  const StyledTableRowVal = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#ffffff",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F1F6FC",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const showIsexpiring = (e) => {
    if (e.target.checked) {
      setIsExpiringCertificate(true)
    }
    else {
      setIsExpiringCertificate(false)
    }
  }
  function EnhancedTableHeadVal(props) {
    return (
      <TableHead>
        <TableRow>
          {headCellsVal?.map((headCell) => (
            <StyledTableCellVal
              key={headCell?.id}
              align="left"
              style={{
                fontWeight: "400",
                fontSize: "12px",
                backgroundColor: "transparent",
                border: "2px solid #22beca",
              }}
              className="pdf"
            >
              {headCell.label}
            </StyledTableCellVal>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  // let pcode = `<div class="MuiTableContainer-root css-11eg4nd-MuiTableContainer-root">
  //     <div class="row m-0">
  //       <div class="details_box" style="display: flex; align-items: center;">
  //         <img
  //           src=""
  //           class="header_logo img-fluid"
  //           alt="header_logo"
  //         />
  //         <ul style="margin: 0px 0px 0px 2rem; padding: 0px; list-style: none; width: 100%;">
  //           <li style="display: flex; width: 100%; padding: 0.5rem 0px;">
  //             <label style="width: 250px; font-weight: 600;">
  //               District Name
  //             </label>
  //             <span style="width: 100%; display: block; border-bottom: 1px dashed darkgrey; padding-bottom: 0.3rem; font-weight: 400;">${
  //               schoolList?.filter(
  //                 (x) => x.schId === allDetail?.schoolDistrictId
  //               )[0]?.schName
  //             }</span>
  //           </li>
  //           <li style="display: flex; width: 100%; padding: 0.5rem 0px;">
  //             <label style="width: 250px; font-weight: 600;">
  //               Training Title
  //             </label>
  //             <span style="width: 100%; display: block; border-bottom: 1px dashed darkgrey; padding-bottom: 0.3rem; font-weight: 400;">${
  //               allDetail?.ceuTitle
  //             }</span>
  //           </li>{" "}
  //           <li style="display: flex; width: 100%; padding: 0.5rem 0px;">
  //             <label style="width: 250px; font-weight: 600;">
  //               Prof. Stnd. Codes
  //             </label>
  //             <span style="width: 100%; display: block; border-bottom: 1px dashed darkgrey; padding-bottom: 0.3rem; font-weight: 400;">${allDetail?.ceuTransaction2?.map(
  //               (x, i) => {
  //                 return (
  //                   <>
  //                     {x.courseCategoryCode}{" "}
  //                     {allDetail?.ceuTransaction2?.length - 1 === i ? "" : ", "}
  //                   </>
  //                 );
  //               }
  //             )}</span>
  //           </li>
  //           <li style="display: flex; width: 100%; padding: 0.5rem 0px;">
  //             <label style="width: 250px; font-weight: 600;">Instructor</label>
  //             <span style="width: 100%; display: block; border-bottom: 1px dashed darkgrey; padding-bottom: 0.3rem; font-weight: 400;">${
  //               allDetail?.trainingProviderName
  //             }
  //             </span>
  //           </li>
  //           <li style="display: flex; width: 100%; padding: 0.5rem 0px;">
  //             <label style="width: 250px; font-weight: 600;">Date</label>
  //             <span style="width: 100%; display: block; border-bottom: 1px dashed darkgrey; padding-bottom: 0.3rem; font-weight: 400;">
  //              ${allDetail?.completionDate}
  //             </span>
  //           </li>
  //           <li style="display: flex; width: 100%; padding: 0.5rem 0px;">
  //             <label style="width: 250px; font-weight: 600;">
  //               CEU Credit Hours
  //             </label>
  //             <span style="width: 100%; display: block; border-bottom: 1px dashed darkgrey; padding-bottom: 0.3rem; font-weight: 400;">
  //              ${allDetail?.trainingLengthInMinute}
  //             </span>
  //           </li>
  //         </ul>
  //       </div>
  //     </div>
  //     <table
  //       class="MuiTable-root css-rqglhn-MuiTable-root"
  //       style="border-collapse: collapse;"
  //     >
  //       <thead class="MuiTableHead-root css-15wwp11-MuiTableHead-root">
  //         <tr class="MuiTableRow-root MuiTableRow-head css-1q1u3t4-MuiTableRow-root">
  //           <th
  //             class="MuiTableCell-root MuiTableCell-head MuiTableCell-alignLeft MuiTableCell-sizeMedium css-embid6-MuiTableCell-root"
  //             scope="col"
  //             style="font-weight: 600; font-size: 16px; background-color: transparent; border: 2px solid rgb(34, 190, 202);"
  //           >
  //             Printed Name
  //           </th>
  //           <th
  //             class="MuiTableCell-root MuiTableCell-head MuiTableCell-alignLeft MuiTableCell-sizeMedium css-embid6-MuiTableCell-root"
  //             scope="col"
  //             style="font-weight: 600; font-size: 16px; background-color: transparent; border: 2px solid rgb(34, 190, 202);"
  //           >
  //             Site Location
  //           </th>
  //           <th
  //             class="MuiTableCell-root MuiTableCell-head MuiTableCell-alignLeft MuiTableCell-sizeMedium css-embid6-MuiTableCell-root"
  //             scope="col"
  //             style="font-weight: 600; font-size: 16px; background-color: transparent; border: 2px solid rgb(34, 190, 202);"
  //           >
  //             Signature
  //           </th>
  //         </tr>
  //       </thead>
  //       <tbody class="MuiTableBody-root css-apqrd9-MuiTableBody-root">
  //       ${users
  //         ?.filter((x) => allDetail?.users?.includes(x.value))
  //         ?.map(
  //           (row, index) =>
  //             `<tr class="MuiTableRow-root table_body css-1ca2wbq-MuiTableRow-root">
  //             <td
  //               class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium column_data css-iwevgc-MuiTableCell-root"
  //               style="border: 2px solid rgb(34, 190, 202); font-weight: 500; font-size: 15px;"
  //             >
  //               ${row.col0}
  //             </td>
  //             <td
  //               class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium column_data css-iwevgc-MuiTableCell-root"
  //               style="border: 2px solid rgb(34, 190, 202); font-weight: 500; font-size: 15px;"
  //             >
  //               ${row.col3}
  //             </td>
  //             <td
  //               class="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium column_data css-iwevgc-MuiTableCell-root"
  //               style="border: 2px solid rgb(34, 190, 202); font-weight: 500; font-size: 15px;"
  //             ></td>
  //           </tr>`
  //         )}
  //       </tbody>
  //     </table>
  //   </div>`;

  const handleDownloadPdf = async () => {
    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();

    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`menualCeu.pdf`);
        setSendBoxVal(false);
        handleDownCLose();
        // onSubmit(allDetail);
      });
  };
  const handleDownCLose = () => {
    setSendBoxVal(false);
    setUsers([]);
    setCourseCode([]);
    setCourseLear([]);
    setCourseTrai([]);
    setDateCompletion();
    reset();
    if (Location.pathname === "/settings") {
      dispatch(CreteDataFor(false));
    } else {
      handleClose();
      if (changeDash) {
        dispatch(DashboardRe(false));
      } else {
        dispatch(DashboardRe(true));
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {sendBoxVal ? (
        <Paper
          className="table-box"
          style={{
            boxShadow: "none",
            marginTop: "2rem",
            // display: "none",
          }}
        >
          <div className="tab_panel row">
            <div className="tab_panel_input_mt text-center mb-3">
              <button
                type="button"
                className="popup_btn update"
                onClick={handleDownloadPdf}
              >
                DownLoad
              </button>
              <button
                type="button"
                className="popup_btn cancel"
                onClick={handleDownCLose}
              >
                cancel
              </button>
            </div>
          </div>
          <TableContainer ref={tableRef} className="" sx={back}>
            <div className="row m-0">
              <div
                className="details_box"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={header_logo}
                  className="header_logo img-fluid"
                  alt=""
                />
                <ul
                  style={{
                    margin: " 0 0 0 2rem",
                    padding: "0",
                    listStyle: "none",
                    width: "100%",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "0.5rem 0",
                    }}
                  >
                    <label style={{ width: "250px", fontWeight: "600" }}>
                      District Name
                    </label>
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        borderBottom: "1px dashed darkgrey",
                        paddingBottom: "0.3rem",
                        fontWeight: "400",
                      }}
                    >
                      {
                        schoolList?.filter(
                          (x) => x.schId === allDetail?.schoolDistrictId
                        )[0]?.schName
                      }
                    </span>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "0.5rem 0",
                    }}
                  >
                    <label style={{ width: "250px", fontWeight: "600" }}>
                      Training Title
                    </label>
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        borderBottom: "1px dashed darkgrey",
                        paddingBottom: "0.3rem",
                        fontWeight: "400",
                      }}
                    >
                      {allDetail?.ceuTitle}
                    </span>
                  </li>{" "}
                  <li
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "0.5rem 0",
                    }}
                  >
                    <label style={{ width: "250px", fontWeight: "600" }}>
                      Prof. Stnd. Codes
                    </label>
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        borderBottom: "1px dashed darkgrey",
                        paddingBottom: "0.3rem",
                        fontWeight: "400",
                      }}
                    >
                      {allDetail?.ceuTransaction2?.map((x, i) => {
                        return (
                          <>
                            {x.courseCategoryCode}{" "}
                            {allDetail?.ceuTransaction2?.length - 1 === i
                              ? ""
                              : ", "}
                          </>
                        );
                      })}
                    </span>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "0.5rem 0",
                    }}
                  >
                    <label style={{ width: "250px", fontWeight: "600" }}>
                      Instructor
                    </label>
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        borderBottom: "1px dashed darkgrey",
                        paddingBottom: "0.3rem",
                        fontWeight: "400",
                      }}
                    >
                      {allDetail?.trainingProviderName}
                    </span>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "0.5rem 0",
                    }}
                  >
                    <label style={{ width: "250px", fontWeight: "600" }}>
                      Date
                    </label>
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        borderBottom: "1px dashed darkgrey",
                        paddingBottom: "0.3rem",
                        fontWeight: "400",
                      }}
                    >
                      {allDetail?.completionDate}
                    </span>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "0.5rem 0",
                    }}
                  >
                    <label style={{ width: "250px", fontWeight: "600" }}>
                      CEU Credit Hours
                    </label>
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        borderBottom: "1px dashed darkgrey",
                        paddingBottom: "0.3rem",
                        fontWeight: "400",
                      }}
                    >
                      {allDetail?.trainingLengthInMinute}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <Table style={{ borderCollapse: "collapse", marginTop: "10px" }}>
              <EnhancedTableHeadVal />
              <TableBody>
                {userList
                  ?.filter((x) => allDetail?.users?.includes(x.value))
                  ?.map((row, index) => {
                    return (
                      <StyledTableRowVal className="table_body" key={index + 1}>
                        {headCellsVal?.map((column) => {
                          const value = row[column?.id];

                          return (
                            <StyledTableCellVal
                              className="column_data pdf"
                              key={column?.id}
                              style={{
                                border: "2px solid #22beca",
                                fontWeight: "400",
                                fontSize: "12px",
                              }}
                            >
                              {column?.format && typeof value === "number"
                                ? column?.format(value)
                                : value}
                            </StyledTableCellVal>
                          );
                        })}
                      </StyledTableRowVal>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <TabContext value={values}>
          <Box className="tab_group mb-3">
            <TabList
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
            >
              <Tab label="User" value="1" className="tab_1" />
              <Tab label="Group" value="2" className="tab_2" />
            </TabList>
          </Box>
          <TabPanel value="1" className="tab_panel">
            <div className="tab_panel row">
              {/* {LogUserId.roleId === 1 ? ( */}
              <div className="tab_panel_input_mt mt-0 col-md-6">
                <select
                  className={`form-select `}
                  aria-label="Default select example"
                  {...register("schoolDistrictId", {
                    required: "This field is required",
                    onChange: (e) => getUerList(e.target.value),
                  })}
                >
                  <option value=""> Select School District </option>
                  {schoolList &&
                    schoolList?.map((data, i) => (
                      <option value={data.schId} key={i}>
                        {data.schName}
                      </option>
                    ))}
                </select>
                {errors.schoolDistrictId && (
                  <span role="alert" className="error_text">
                    {errors.schoolDistrictId.message}
                  </span>
                )}
              </div>
              {/* ) : (
                ""
              )} */}
              <div className="tab_panel_input_mt mt-0 col-md-6">
                <Select
                  isMulti
                  name="colors"
                  options={userList}
                  className="course-caretory-multi-select p_14_text"
                  classNamePrefix="select"
                  placeholder="Select Users"
                  value={users ? users : null}
                  // onChange={handleChangeUsersMulti}
                  onChange={(selected) => {
                    true &&
                      selected.length &&
                      selected.find((option) => option.value === "all")
                      ? handleChangeUsersMulti(userList.slice(1))
                      : !true
                        ? handleChangeUsersMulti(
                          (selected && selected.value) || null
                        )
                        : handleChangeUsersMulti(selected);
                  }}
                />
                {derrors.userId && (
                  <span role="alert" className="error_text">
                    {derrors.userId}
                  </span>
                )}
              </div>
              <div className={`tab_panel_input_mt col-md-6`}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  {...register("ceuTitle", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                {errors.ceuTitle && (
                  <span role="alert" className="error_text">
                    {errors.ceuTitle.message}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt col-md-6">
                <Select
                  isMulti
                  name="colors"
                  options={courseCodeList}
                  className="course-caretory-multi-select p_14_text"
                  classNamePrefix="select"
                  placeholder="Key Area"
                  value={courseCodeList.filter((obj) =>
                    courseCode?.includes(obj.value)
                  )}
                  onChange={handleChangeCourceMulti}
                />
                {derrors?.course_code && (
                  <span role="alert" className="error_text">
                    {derrors?.course_code}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt col-md-6">
                <Select
                  isMulti
                  name="colors"
                  options={courseTraiList}
                  className="course-caretory-multi-select p_14_text"
                  placeholder="Training Topic"
                  classNamePrefix="select"
                  value={courseTraiList.filter((obj) =>
                    courseTrai?.includes(obj.value)
                  )}
                  onChange={handleChangeTraiMulti}
                />
                {derrors?.course_t && (
                  <span role="alert" className="error_text">
                    {derrors?.course_t}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt col-md-6">
                <Select
                  isMulti
                  name="colors"
                  options={courseLearList}
                  className="course-caretory-multi-select p_14_text"
                  placeholder="Learning Objective "
                  classNamePrefix="select"
                  value={courseLearList.filter((obj) =>
                    courseLear?.includes(obj.value)
                  )}
                  onChange={handleChangeLearMulti}
                />
                {derrors?.course_l && (
                  <span role="alert" className="error_text">
                    {derrors?.course_l}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="CEU"
                  {...register("ceus", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^\d+(\.\d{1,2})?$/,
                      message: "Enter valid Number",
                    },
                  })}
                />

                {errors.ceus && (
                  <span role="alert" className="error_text">
                    {errors.ceus.message}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Training Provider"
                  {...register("trainingProviderName", {
                    // required: {
                    //   value: true,
                    //   message: "This field is required",
                    // },
                  })}
                />
                {errors.trainingProviderName && (
                  <span role="alert" className="error_text">
                    {errors.trainingProviderName.message}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Training Format"
                  {...register("trainingFormat", {
                    // required: {
                    //   value: true,
                    //   message: "This field is required",
                    // },
                  })}
                />
                {errors.trainingFormat && (
                  <span role="alert" className="error_text">
                    {errors.trainingFormat.message}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt col-md-6">
                <input
                  type="text"
                  className="form-control timing_icon"
                  placeholder="Training Length (HH:MM:SS)"
                  {...register("trainingLengthInMinute", {
                    // required: {
                    //   value: true,
                    //   message: "This field is required",
                    // },
                    pattern: {
                      // value: /^([01]\d|2[0-3]):([0-5]\d|5[0-9]):([0-5]\d)$/,
                      // value: /^(0[1-9]|[1-9][0-9]):([0-5]\d|5[0-9]):([0-5]\d)$/,
                      value: /^([01]\d|[1-9][0-9]):([0-5]\d|5[0-9]):([0-5]\d)$/,
                      // value: /^([01]\d|2[0-3]):([0-5]\d)$/, (hh:mm)
                      message: "Please Enter Valid Time",
                    },
                  })}
                />
                {errors.trainingLengthInMinute && (
                  <span role="alert" className="error_text">
                    {errors.trainingLengthInMinute.message}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt col-md-6">
                <DatePicker
                  dateFormat="MM/dd/yyyy"
                  selected={dateCompletion}
                  onChange={setDateCompletion}
                  placeholderText="Completion Date"
                  className="Date_picker"
                />
                {derrors?.d && (
                  <span role="alert" className="error_text">
                    {derrors.d}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt col-md-6">
                <select
                  className="form-select dropdown_group"
                  aria-label="Default select example"
                  {...register("schoolYear", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                >
                  <option value=""> Select Year </option>
                  {schList &&
                    schList?.map((item, i) => (
                      <option key={i} value={item.academicYear}>
                        {item?.academicYear}{" "}
                        {item?.isCurrentYear === "True" ? "- Current Year" : ""}
                      </option>
                    ))}
                </select>
                {errors.schoolYear && (
                  <span role="alert" className="error_text">
                    {errors.schoolYear.message}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt">
                <div className="upload_img ceu">
                  <input
                    type="text"
                    className="form-control upload_box"
                    placeholder="No file Chosen"
                    readOnly
                    {...register("imgUpload", {})}
                  />
                  <input
                    type="file"
                    id="school_img_upload"
                    className="form-control"
                    style={{ display: "none" }}
                    placeholder="User You Want To Be In District"
                    onChange={handleImage}
                  />
                  <label
                    htmlFor="school_img_upload"
                    className="upload_img_label mb-0 "
                  >
                    <img src={uploadimg} className="img-fluid" alt="" />
                  </label>
                </div>
                {errors.imgUpload && (
                  <span role="alert" className="error_text">
                    {errors.imgUpload.message}
                  </span>
                )}
                {derrors.img_err && (
                  <span role="alert" className="error_text">
                    {derrors.img_err}
                  </span>
                )}
              </div>
              <div className="tab_panel_input_mt">
                <textarea
                  className="form-control"
                  placeholder="Comments"
                  {...register("remarks", {})}
                ></textarea>
                {errors.remarks && (
                  <span role="alert" className="error_text">
                    {errors.remarks.message}
                  </span>
                )}
              </div>

              <div className="tab_panel_input_mt col-md-6">
                <label className="form-control d-flex gap-3">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    {...register("isExpiringCertificate", {
                      onChange: (e) => {
                        showIsexpiring(e);
                      }
                    })}
                  />
                  Want to create cetificate?
                </label>
              </div>
              {isExpiringCertificate &&
                <>
                  <div className={`tab_panel_input_mt col-md-6`}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Course Name"
                      {...register("courseName", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                  {errors.courseName && (
                      <span role="alert" className="error_text">
                        {errors.courseName.message}
                      </span>
                  )}
                  </div>

                  <div className="tab_panel_input_mt col-md-6">
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={dateReminder}
                      onChange={setDateReminder}
                      placeholderText="Date Of Reminder"
                      className="Date_picker"
                    />
                    {derrors?.re && (
                  <span role="alert" className="error_text">
                    {derrors.re}
                  </span>
                )}
                    {/* {errors.dateReminder && (
                      <span role="alert" className="error_text">
                        {errors.dateReminder.message}
                      </span>
                  )} */}
                  </div>

                  <div className="tab_panel_input_mt col-md-6">
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      selected={dateExpiration}
                      onChange={setDateExpiration}
                      placeholderText="Date Of Expiration"
                      className="Date_picker"
                    />
                    {derrors?.Dex && (
                <span role="alert" className="error_text">
                  {derrors.Dex}
                </span>
                )}
                  </div>

                  
                </>
              }

                  <div className="tab_panel_input_mt">
                    <button
                      type="button"
                      className="popup_btn update"
                      onClick={handleSubmit(
                        async (data) =>
                          await onSubmit({ ...data, status: "Completed" })
                      )}
                    >
                      Add CEU
                    </button>
                    <button
                      type="button"
                      className="popup_btn cancel"
                      onClick={settingClose}
                    >
                      cancel
                    </button>
                    <button
                      type="button"
                      className="popup_btn update ms-3"
                      onClick={handleSubmit(
                        async (data) => await onSubmit({ ...data, status: "Draft" })
                      )}
                    >
                      Generate Sign-In Sheet
                    </button>
                  </div>

            </div>
          </TabPanel>
          <TabPanel value="2" className="tab_panel">
            <GroupCeu handleClose={settingClose} />
          </TabPanel>
        </TabContext>
      )}
    </>
  );
}

export default React.memo(AddCEUPopup);
