import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  Paper,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import Pagination from "../Paginations/Pagination";
import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "./index.scss";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  fontFamily: `"Poppins", sans-serif`,
  borderBottom: "0px !important",
  "&.pdf_download": {
    fontSize: "9px !important",
    padding: "3px !important",

    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ReuseTable(prop) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("No");
  const tableRef = useRef(null);
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [downloadExcel, setDownloadExcell] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    setCurrentPage(1);
  }, [prop?.ListData]);

  const LogUserde = useSelector((state) => state.login?.LoginDetails);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: prop?.title ? prop?.title : "Table",
    sheet: "Users",
  });
  function descendingComparator(a, b, orderBy) {
    if (orderBy === "No" || orderBy === "NumOfCom" || orderBy === "ComCEu") {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    } else {
      if (
        b[orderBy]?.toString()?.toLowerCase() <
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return -1;
      }
      if (
        b[orderBy]?.toString()?.toLowerCase() >
        a[orderBy]?.toString()?.toLowerCase()
      ) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {prop?.headCells?.map((headCell) => {
            if (downloadPDF && headCell?.pdfDownload === false) {
              return null;
            }
            return (
              <StyledTableCell
                key={headCell?.id}
                align="left"
                className={`${downloadPDF && "pdf_download"}`}
                sortDirection={orderBy === headCell?.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell?.id}
                  direction={orderBy === headCell?.id ? order : "asc"}
                  onClick={createSortHandler(headCell?.id)}
                >
                  {headCell.label}
                  {downloadPDF || downloadExcel ? (
                    ""
                  ) : (
                    <>
                      {orderBy === headCell?.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </>
                  )}
                </TableSortLabel>
              </StyledTableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [searchField, setSearchField] = useState("");

  const formattedSearchResults = prop?.ListData?.filter((user) => {
    if (searchField) {
      let state = false;
      prop?.headCells?.forEach((property) => {
        let value = String(user[property?.id]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });

  useEffect(() => {
    if (downloadExcel) {
      onDownload();
      setDownloadExcell(false);
    }
    // eslint-disable-next-line
  }, [downloadExcel]);
  useEffect(() => {
    if (downloadPDF) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPDF]);
  let Time = new Date();

  const handleDownloadPdf = async () => {
    setOpenCircle(true);
    const element = tableRef.current;

    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();

    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;

        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`${prop?.title}.pdf`);
        setDownloadPDF(false);
        setOpenCircle(false);
      });
  };
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const back = {
    maxWidth: `${downloadPDF ? "200mm" : "100%"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="report_page main_backcolor">
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="report_page_box">
              <Box
                sx={{
                  flexGrow: 1,
                  // bgcolor: "transparent",
                  // bgcolor: "background.paper",
                }}
              >
                <div
                  className="tabpannel_content"
                  style={{
                    backgroundColor:
                      "box-shadow: 0px 7px 8px rgba(50, 50, 50, 0.25)",
                  }}
                >
                  <div className="cr seprate">
                    <label className="me-2">{prop?.title}</label>
                    <div className="mb-4 listdata_control_sec ">
                      <div className="sep_report_head  d-flex justify-content-between">
                        <div className=" search_sec w-25  me-2 ">
                          <div className="search_input w-100">
                            <input
                              onChange={(e) => {
                                setSearchField(e.target.value);
                                setCurrentPage(1);
                              }}
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <img src={search_icon} alt="" />
                          </div>
                        </div>
                        <div className="d-flex">
                          {/* <select
                    name=""
                    id=""
                    className="form-select download_drop me-3 w-25"
                    value={acdYS}
                    onChange={(e) => handleAcademicYear(e.target.value)}
                  >
                    <option value="">Select Academic Year</option>
                    {acdYList &&
                      acdYList?.map((acy, i) => (
                        <option value={acy.academicYearId} key={i}>
                          {acy.academicYear}
                        </option>
                      ))}
                  </select> */}
                          {formattedSearchResults?.length > 0 ? (
                            <>
                              <Button
                                className="ms-3 delete_btn btn_space"
                                onClick={() => {
                                  setDownloadPDF(true);
                                }}
                              >
                                PDF
                              </Button>
                              <Button
                                className="ms-3 delete_btn btn_space"
                                onClick={() => setDownloadExcell(true)}
                              >
                                Download
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 school_district">
                      <Paper className="table-box">
                        <TableContainer className="" ref={tableRef} sx={back}>
                          {downloadPDF && (
                            <div
                              className="logo_img_pdf d-flex justify-content-between align-items-center mb-5"
                              style={{ padding: "0px 20px" }}
                            >
                              <img
                                src={require("../../../images/Icon/20211.png")}
                                alt=""
                              />
                              <div style={{ marginRight: "20px" }}>
                                <label
                                  style={{
                                    fontSize: "16px",
                                    color: "#2c2e35",
                                    fontWeight: 600,
                                    marginBottom: "7px",
                                  }}
                                >
                                  {prop?.title}
                                </label>
                                {/* <div
                      className="names"
                      style={{
                        fontSize: "16px",
                        color: "#2c2e35",
                        fontWeight: 600,
                        marginBottom: "7px",
                      }}
                    >
                      {schId?.label}
                    </div> */}
                                <div
                                  className="names"
                                  style={{
                                    fontSize: "16px",
                                    color: "#2c2e35",
                                    fontWeight: 600,
                                    marginBottom: "7px",
                                  }}
                                >
                                  {" "}
                                  {convertDate(
                                    Time.toLocaleString("en-us", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    }).replace(
                                      /(\d+)\/(\d+)\/(\d+)/,
                                      "$3-$1-$2"
                                    )
                                  )}{" "}
                                  {Time.toLocaleTimeString()}
                                </div>
                                <div
                                  className="names"
                                  style={{
                                    fontSize: "16px",
                                    color: "#2c2e35",
                                    fontWeight: 600,
                                  }}
                                >
                                  Report Generated By:{" "}
                                  {LogUserde?.firstName +
                                    " " +
                                    LogUserde?.lastName}
                                </div>
                              </div>
                            </div>
                          )}
                          <Table>
                            <EnhancedTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                              {stableSort(
                                formattedSearchResults,
                                getComparator(order, orderBy)
                              )
                                ?.slice(
                                  (currentPage - 1) * pageSize,
                                  (currentPage - 1) * pageSize + pageSize
                                )
                                ?.map((row, index) => {
                                  return (
                                    <StyledTableRow
                                      className="table_body"
                                      key={index + 1}
                                    >
                                      {prop?.headCells?.map((column) => {
                                        const value = row[column?.id];
                                        if (
                                          downloadPDF &&
                                          column?.pdfDownload === false
                                        ) {
                                          return null;
                                        }
                                        return (
                                          <StyledTableCell
                                            className={`column_data ${
                                              downloadPDF && "pdf_download"
                                            }`}
                                            key={column?.id}
                                          >
                                            {column?.format &&
                                            typeof value === "number"
                                              ? column?.format(value)
                                              : value}
                                          </StyledTableCell>
                                        );
                                      })}
                                    </StyledTableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {formattedSearchResults?.length === 0 ? (
                          <div className="text-center my-3 p_20_text">
                            Data Not Found
                          </div>
                        ) : (
                          <div className="page_nation">
                            <div className="rowsPerPageOptions">
                              <span className="p_18_text">show</span>
                              <select
                                className="pagination_select p_16_text"
                                value={pageSize}
                                onChange={(e) => {
                                  setPageSize(parseInt(e.target.value));
                                  setCurrentPage(1);
                                }}
                              >
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                              </select>
                              <span className="p_18_text">entries</span>
                            </div>
                            <Pagination
                              className="pagination-bar"
                              currentPage={currentPage}
                              totalCount={formattedSearchResults?.length}
                              pageSize={pageSize}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          </div>
                        )}
                      </Paper>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(ReuseTable);
