import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
// import Footer from "../Shared/Footer/Footer";
import Navigation from "../Shared/Navigation/Index";
import { SignalRProvider } from "../Utils/SignalRContext";
import { Box } from "@mui/material";
import SidebarChat from "../Shared/SidebarChat";
import Header_logo_1 from '../images/Header_logo_1.png';
// import { RxCross2 } from "react-icons/rx";
import "../index.scss"

function PrivateRoute() {
  const loggedIn = useSelector(
    (state) => state.login?.LoginDetails?.accessToken
  );
  let TopMenuList = useSelector((state) => state.setting?.TopMenuList);
  let { pathname } = useLocation();
  let psNameMessage = pathname?.split("/")[1] === "messages"

  const [isSidebarChatOpen, setIsSidebarChatOpen] = React.useState(); // Chat sidebar collapsed by default
  const handleLogoClick = () => {
    setIsSidebarChatOpen(isSidebarChatOpen ? false : true);
  };
  console.log(pathname, "issue", isSidebarChatOpen)
  return loggedIn !== undefined ? (
    <>
      <div
        className="chatLogoBtn"
        onClick={handleLogoClick}
      >
        <img
          className="img-fluid"
          src={Header_logo_1}
          alt="FIG K12 Logo"
        />
      </div>
      <div className="mainLayout">
        <div
          className={`wrapper ${isSidebarChatOpen ? "open" : "close"}`}
        >
          {TopMenuList?.filter(
            (x) => x.menuUrl?.split("/")[1] === pathname?.split("/")[1]
          )?.length > 0 ? (
            <>
              <SignalRProvider>
                <Navigation />
                <Outlet />
              </SignalRProvider>
            </>
          ) : TopMenuList?.length > 0 ? (
            <Navigate to={TopMenuList[0]?.menuUrl} />
          ) : (
            <Navigate to="/" />
          )}
        </div >
        <SidebarChat isOpen={isSidebarChatOpen} psNameMessage={psNameMessage} onClose={() => setIsSidebarChatOpen(false)} />
      </div >
      {/* <Footer /> */}
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRoute;
