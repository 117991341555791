import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    Backdrop,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Button,
    Paper,
    styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import { useSelector } from "react-redux";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import Pagination from "../../Reuseable_Components/Paginations/Pagination";
import { useDownloadExcel } from "react-export-table-to-excel";
import { jsPDF } from "jspdf";
import Swal from "sweetalert2";
import { GetCallApi } from "../../../Action/Action";
import Config from "../../../Utils/Config";
import pro_img from "../../../images/default_profile.jpg";
// import NoteUser from "../../Reuseable_Components/NoteUser";

const columns = [
    // { id: "col0", headerName: "No" },
    { id: "col1", headerName: "District Logo" },
    { id: "col2", headerName: "School District Name" },
    { id: "col3", headerName: "Preferred Partners" },
    { id: "col4", headerName: "Total User" },
    { id: "col5", headerName: "Active User" },
    { id: "col6", headerName: "InActive User" },
    { id: "col7", headerName: "Created Date" },
    { id: "col8", headerName: "IsActive" },
    // { id: "col8", headerName: "Notes" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  fontFamily: `"Poppins", sans-serif`,
  borderBottom: "0px !important",
  "&.pdf_download": {
    fontSize: "10px !important",
    padding: "5px !important",
    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#ffffff",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#F1F6FC",
    },
    "&.odd": {
        backgroundColor: "#ffffff",
    },
    "&.even": {
        backgroundColor: "#F1F6FC",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "&.pdf_download": {
        fontSize: "10px !important",
        fontWeight: "300 !important",
        padding: "5px !important",
    },
}));

function SuperAdminReport(prop) {
    const [open, setOpen] = useState(false);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("col0");
    const [searchField, setSearchField] = useState("");
    const [repo, setRepo] = useState([]);
    const [otherDetail, setOtherDetail] = useState([]);
    const [userDataAll, setUserDataAll] = useState(false);
    const tableRef = useRef(null);

    const LogUserd = useSelector((state) => state.login.LoginDetails);
    // const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
    const token = useSelector((state) => state.login.LoginDetails.accessToken);
    const ReportMenuName = useSelector((state) => state.setting?.ReportMenuName);

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: ReportMenuName,
        sheet: "Users",
    });
    function descendingComparator(a, b, orderBy) {
        if (orderBy === "col0") {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
        } else {
            if (
                b[orderBy]?.toString()?.toLowerCase() <
                a[orderBy]?.toString()?.toLowerCase()
            ) {
                return -1;
            }
            if (
                b[orderBy]?.toString()?.toLowerCase() >
                a[orderBy]?.toString()?.toLowerCase()
            ) {
                return 1;
            }
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis?.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>

                    <StyledTableCell
                        key={"col0"}
                        align="left"
                        sortDirection={orderBy === "col0" ? order : false}
                        colSpan={1}
                        style={{
                            width: `${downloadPdf ? "40px" : "auto"}`,
                            maxWidth: `${downloadPdf ? "40px" : "80px"}`,
                        }}
                        className={`${downloadPdf && "pdf_download"}`}
                    >
                        <TableSortLabel
                            active={orderBy === "col0"}
                            direction={orderBy === "col0" ? order : "asc"}
                            onClick={createSortHandler("col0")}
                        >
                            No
                            {!userDataAll && (
                                <>
                                    {orderBy === "col0" ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </>
                            )}
                        </TableSortLabel>
                    </StyledTableCell>

                    {columns?.map((headCell) => (
                        <StyledTableCell
                            key={headCell?.id}
                            align="left"
                            sortDirection={orderBy === headCell?.id ? order : false}
                            colSpan={1}
                            className={`${downloadPdf && "pdf_download"}`}
                        >
                            <TableSortLabel
                                active={orderBy === headCell?.id}
                                direction={orderBy === headCell?.id ? order : "asc"}
                                onClick={createSortHandler(headCell?.id)}
                            >
                                {headCell?.headerName}
                                {!userDataAll && (
                                    <>
                                        {orderBy === headCell?.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === "desc"
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </>
                                )}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [downloadPdf, setDownloadpdf] = useState(false);

    const formattedSearchResults = repo?.filter((user) => {
        if (searchField) {
            let state = false;
            columns?.forEach((property) => {
                let value = String(user[property?.id]);
                if (typeof value === "string") {
                    if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
                        state = true;
                    }
                }
            });
            return state;
        } else return user;
    });

    useEffect(() => {
        getAdminData("All");
        setCurrentPage(1);
        // eslint-disable-next-line
    }, [])

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    const getAdminData = async (type) => {
        setOpen(true);
        const sendData = {
            url: `/api/Report/GetAdminAllDistrictReport?type=${type}`,
            headers: headers
        }
        let response = await GetCallApi(sendData)
        setOpen(false);
        if (response?.status === 200) {
            if (response.data.success) {
                const dashData = response.data?.payload?.districtData;
                let userReport = dashData?.map((item, i) => {
                    return {
                        col0: i + 1,
                        col1: (
                            <div style={{ margin: "auto" }}>
                                <img
                                    // src={
                                    //     item?.schoolDistrictLogo
                                    //         ? `${Config.API_HOST_URL_live}${item?.schoolDistrictLogo}`
                                    //         : pro_img
                                    // }
                                    src={
                                        item?.thumbnailLogoUrl
                                            ? `${Config.API_HOST_URL_live}${item?.thumbnailLogoUrl}`
                                            : pro_img
                                    }
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = pro_img;
                                    }}
                                    alt="course"
                                    height={30}
                                    width={30}
                                />
                            </div>
                        ),
                        col2:
                            item?.schoolDistrictName === "" || item?.schoolDistrictName === null
                                ? ""
                                : item?.schoolDistrictName,
                        col3:
                            item?.isPreferredPartners === "" || item?.isPreferredPartners === null
                                ? ""
                                : item?.isPreferredPartners ? "True" : "False",
                        col4:
                            item?.totalUser === null || item?.totalUser === "" ? "" : item?.totalUser,
                        col5: item?.activeUser,
                        col6: item?.inActiveUser,
                        col7: item?.createdDate ? convert(item?.createdDate?.split("T")[0]) : "",
                        col8: item?.isActive === true ? "True" : "False",
                    };
                });

                setRepo(userReport);
                setOtherDetail(response.data?.payload)
            } else {
                setRepo([]);
                setOpen(false);
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: response?.data?.message
                        ? response?.data?.message
                        : "Something went wrong please try again.",
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "red",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        } else {
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: response?.data?.message
                    ? response?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    };

    useEffect(() => {
        if (userDataAll) {
            if (downloadPdf) {
                handleDownloadPdf();
            } else {
                onDownload();
                setUserDataAll(false);
            }
        }
        // eslint-disable-next-line
    }, [userDataAll]);

    function convertDate(str) {
        var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    const handleDownloadPdf = async () => {
        setOpen(true);

        const element = tableRef.current;
        let newPdfDate =
            convertDate(
                Time.toLocaleString("en-us", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
            ) +
            " " +
            Time.toLocaleTimeString();

        const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
        doc
            .html(element, {
                margin: [20, 10, 20, 10],
                autoPaging: "text",
                html2canvas: { scale: 0.57 },
            })
            .then(() => {
                let totalPages = doc.internal.pages.length - 1;
                for (let i = 1; i <= totalPages; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.setTextColor(150);
                    doc.text(
                        newPdfDate,
                        doc.internal.pageSize.width / 2,
                        doc.internal.pageSize.getHeight() - 10,
                        {
                            align: "center",
                        }
                    );
                }
                doc.save(`${ReportMenuName}.pdf`);
                setDownloadpdf(false);
                setUserDataAll(false);
                setOpen(false);
            });
    };
    const back = {
        maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
        width: "100%",
        height: "100%",
        position: "relative",
        margin: "0",
    };
    let Time = new Date();

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="mb-4  listdata_control_sec">
                <label>{ReportMenuName}</label>
                <div className="sep_report_head  d-flex justify-content-between">
                    <div className="search_input w-50 me-4">
                        <input
                            onChange={(e) => {
                                setSearchField(e.target.value);
                                setCurrentPage(1);
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Search By Name"
                        />
                        <img src={search_icon} alt="" />
                    </div>
                    <div className="grid_top_button_group d-flex w-100 justify-content-end btn-100 ms-4">
                        <select
                            name=""
                            id=""
                            className="form-select download_drop me-3 w-25"
                            onChange={(e) => {
                                getAdminData(e.target.value);
                                setCurrentPage(1)
                            }}
                        >
                            <option value="All">All</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                        </select>
                        <Button
                            className="delete_btn btn_space me-3"
                            onClick={() => {
                                setDownloadpdf(true);
                                setUserDataAll(true);
                            }}
                        >
                            PDF
                        </Button>
                        <Button
                            className="delete_btn btn_space"
                            onClick={() => {
                                setUserDataAll(true);
                            }}
                        >
                            Download
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mt-3 school_district">
                <Paper className="table-box">
                    <TableContainer className="" ref={tableRef} id="divToPrint" sx={back}>
                        <div className="logo_img_pdf col-md-12 align-items-center mb-4 mt-3">
                            <img src={`${Config?.API_HOST_URL_live}/assets/images/fig-logo.png`} alt="" width={100}
                                height={80} />
                            {/* <img src={require("../../../images/Icon/20211.png")} alt="" /> */}
                        </div>

                        <div className="logo_img_pdf d-flex justify-content-between mb-3">
                            <div className="col-md-6">
                                <label
                                    style={{
                                        fontSize: "16px",
                                        color: "#2c2e35",
                                        fontWeight: 600,
                                        marginBottom: "7px",
                                    }}
                                >
                                    {" "}
                                    {ReportMenuName}
                                </label>
                                <div
                                    className="names"
                                    style={{
                                        fontSize: "16px",
                                        color: "#2c2e35",
                                        fontWeight: 600,
                                        marginBottom: "7px",
                                    }}
                                >
                                    {" "}
                                    {convertDate(
                                        Time.toLocaleString("en-us", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
                                    )}{" "}
                                    {Time.toLocaleTimeString()}
                                </div>
                                <div
                                    className="names"
                                    style={{
                                        fontSize: "16px",
                                        color: "#2c2e35",
                                        fontWeight: 600,
                                    }}
                                >
                                    Report Generated By:{" "}
                                    {LogUserd?.firstName + " " + LogUserd?.lastName}
                                </div>
                            </div>
                            <div className="col-md-6 text-end">
                                <label
                                    style={{
                                        fontSize: "16px",
                                        color: "#2c2e35",
                                        fontWeight: 600,
                                        marginBottom: "7px",
                                    }}
                                >
                                    Active Districts : {otherDetail?.activeDistricts}
                                </label>
                                <br />
                                <label
                                    style={{
                                        fontSize: "16px",
                                        color: "#2c2e35",
                                        fontWeight: 600,
                                        marginBottom: "7px",
                                    }}
                                >
                                    InActive Districts : {otherDetail?.inActiveDistricts}
                                </label>
                                <br />
                                <label
                                    style={{
                                        fontSize: "16px",
                                        color: "#2c2e35",
                                        fontWeight: 600,
                                        marginBottom: "7px",
                                    }}
                                >
                                    Total Districts : {otherDetail?.totalDistricts}
                                </label>{" "}
                            </div>
                        </div>

                        <Table>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {stableSort(
                                    formattedSearchResults,
                                    getComparator(order, orderBy)
                                )
                                    ?.slice(
                                        (currentPage - 1) * pageSize,
                                        (currentPage - 1) * pageSize + pageSize
                                    )
                                    ?.map((row, index) => {
                                        return (
                                            <StyledTableRow
                                                className={`table_body ${downloadPdf && "pdf_download"
                                                    }  ${index % 2 === 0 ? "odd" : "even"}`}
                                                key={index + 1}
                                            >

                                                <StyledTableCell
                                                    className={`column_data ${downloadPdf && "pdf_download"
                                                        }`}
                                                    key={"col0"}
                                                    colSpan={1}
                                                    style={{
                                                        width: "40px",
                                                        maxWidth: `${downloadPdf ? "40px" : "80px"}`,
                                                    }}
                                                >
                                                    {row.col0}
                                                </StyledTableCell>

                                                {columns?.map((column) => {
                                                    const value = row[column?.id];
                                                    if (userDataAll && !downloadPdf) {
                                                        if (column?.id === "col1") {
                                                            return (
                                                                <StyledTableCell
                                                                    className="column_data"
                                                                    key={column?.id}
                                                                    style={{
                                                                        width: "80px",
                                                                        height: "30px",
                                                                    }}
                                                                >
                                                                    {value}
                                                                </StyledTableCell>
                                                            );
                                                        }
                                                    }
                                                    return (
                                                        <StyledTableCell
                                                            className={`column_data ${downloadPdf && "pdf_download"
                                                                }`}
                                                            key={column?.id}
                                                            colSpan={1}
                                                        >
                                                            <>
                                                                {column?.format && typeof value === "number"
                                                                    ? column?.format(value)
                                                                    : value}
                                                            </>
                                                        </StyledTableCell>
                                                    );
                                                })}
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {formattedSearchResults?.length === 0 ? (
                        <label className="r_cs_tit">Data Not Found</label>
                    ) : (
                        <div className="page_nation">
                            <div className="rowsPerPageOptions">
                                <span className="p_18_text">show</span>
                                <select
                                    className="pagination_select p_16_text"
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(parseInt(e.target.value));
                                        setCurrentPage(1);
                                    }}
                                >
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                </select>
                                <span className="p_18_text">entries</span>
                            </div>
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={formattedSearchResults?.length}
                                pageSize={pageSize}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    )}
                </Paper>
            </div>
        </>
    );
}
export default React.memo(SuperAdminReport);
