import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"; // Assuming you're using React Router for navigation
import { persistor } from "../../store";
import { PostCallApi } from "../../Action/Action";
import { SwalError, SwalMessage } from "../SwalToast";
import { Backdrop, CircularProgress } from "@mui/material";

const fetchVersion = async () => {
  try {
    const res = await fetch(`/meta.json?timestamp=${new Date().getTime()}`);
    const { version } = await res.json();
    return version;
  } catch (error) {
    console.error("Error fetching version:", error);
    return null;
  }
};
const validPaths = [
  "",
  "home",
  "about",
  "ceu",
  "figlifestyle",
  "coaches",
  "contactus",
  "meet",
  "free",
  "app-video",
  "login",
  "privacy-policy",
  "privacy",
  "clever-login",
  "sso-login",
  "resetpassword",
];
const Logout = () => {
  const [openCircle, setOpenCircle] = React.useState(false);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const LoginId = useSelector((state) => state?.login?.LoginDetails);
  // const currentVersion = "1.2.0"; // Dynamic version
  // const currentVersion = process.env.REACT_APP_VERSION; // Dynamic version

  useEffect(() => {
    const checkVersion = async () => {
      // setOpenCircle(true);

      const currentVersion = await fetchVersion(); // Dynamic version
      // setOpenCircle(false);
      const storedVersion = sessionStorage.getItem("app_version");
      // console.log("call on page change", currentVersion, storedVersion)
      // If the version has changed, trigger logout
      if (storedVersion !== currentVersion) {
        handleLogout(currentVersion); // If versions are different, handle logout
      }
    };
    const interval = setInterval(checkVersion, 60000);
    checkVersion();

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [navigate]);

  const handleLogout = async (currentVersion) => {
    const pathSegments = pathname.split("/").filter(Boolean);
    const isValidPath = validPaths.some((path) => pathSegments.includes(path));
    if (isValidPath || pathname === "/") {
      localStorage.clear("expireDate");
      navigate(0);
      if (LoginId?.userId) {
        setTimeout(() => {
          dispatch({ type: "LOGOUT" });
          persistor.purge();
        }, 0);
      }
      sessionStorage.setItem("app_version", currentVersion); // Update the version in localStorage
    } else if (LoginId?.userId) {
      let rv = await SwalMessage(
        "Your session has expired due to a recent update. Please log in again to continue.",
        10000
      );
      // debugger
      if (rv?.isConfirmed || rv?.isDismissed) {
        setOpenCircle(true);
        let apiBody = {
          createdBy: LoginId?.userId,
          logOutName: LoginId?.firstName + " " + LoginId?.lastName,
          email: LoginId?.email,
          type: "Web",
        };

        let sendData = {
          url: `/api/Common/SaveUserActivityLogOut`,
          body: apiBody,
        };

        let resp = await PostCallApi(sendData);
        if (resp?.status === 200 && resp?.data?.success) {
          localStorage.clear("expireDate");
          // window.location.reload();
          navigate("/", { replace: true });
          navigate(0);
          setTimeout(() => {
            dispatch({ type: "LOGOUT" });
            persistor.purge();
            setOpenCircle(false);
          }, 0);
          sessionStorage.setItem("app_version", currentVersion); // Update the version in localStorage

          // window.location.reload(true);
        } else {
          setOpenCircle(false);
          SwalError(resp?.data?.message);
          navigate("/", { replace: true });
          navigate(0);
          setTimeout(() => {
            dispatch({ type: "LOGOUT" });
            persistor.purge();
            setOpenCircle(false);
          }, 0);
          sessionStorage.setItem("app_version", currentVersion); // Update the version in localStorage
        }
      }
    } else {
      localStorage.clear("expireDate");
      // dispatch({ type: "LOGOUT" });
      // persistor.purge();
      sessionStorage.setItem("app_version", currentVersion); // Update the version in localStorage
      // window.location.reload();
      navigate(0);

      // window.location.reload(true);
    }
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openCircle}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ); // This component does not render anything visible
};

export default Logout;
