import React, { Suspense, lazy, useState, useEffect } from "react";
import "./index.scss";
import userimg12 from "../../../images/user.png";
import axios from "axios";
import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
import * as signalR from "@microsoft/signalr";
import { useSignalR } from "../../../Utils/SignalRContext";

const UsersList = lazy(() => import("./UsersList"));
const UserMessages = lazy(() => import("./UserMessages"));
const renderLoader = () => <p></p>;
function Messages() {
  const [searchField, setSearchField] = useState("");
  const [usList, setUsList] = useState([]);
  const [userChatDetail, setUserChatDeatil] = useState();
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const LogUserId = useSelector((state) => state.login.LoginDetails.userId);
  const { connection } = useSignalR();
  var ChatId = localStorage.getItem("chatId");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [seaUsList, setSeaUsList] = useState([]);
  const [startNewchat, setStartNewChat] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [userList, setUserList] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState(window.innerWidth);

  useEffect(() => {
    getSearchList(searchField);
    // eslint-disable-next-line
  }, [searchField]);

  useEffect(() => {
    if (ChatId) {
      if (usList?.find((x) => x?.chatId === ChatId))
        var data = usList?.find((x) => x?.chatId === ChatId);
      if (data) {
        connection.invoke("JoinRoom", data?.userInfo?.userId);
        setUserChatDeatil(data);
        localStorage.removeItem("chatId");
      }
    }
  }, [ChatId, usList]);

  const getSearchList = (name) => {
    if (name !== "") {
      if (name.length > 0) {
        setOpenLoader(true);
        axios
          .get(
            `${Config.API_HOST_URL_live}/api/Chat/SearchUserByName?userId=${LogUserId}&searchByName=${name}`,
            { headers }
          )
          .then((response) => {
            if (response.data.success) {
              let srlist = response.data.payload?.map((item) => {
                return {
                  userId: item?.userId,
                  name: item?.userName,
                  chatId:
                    item?.activeChatInfo === null ||
                      item?.activeChatInfo?.chatId === "" ||
                      item?.activeChatInfo?.chatId === null
                      ? ""
                      : item?.activeChatInfo?.chatId,
                  img:
                    item?.activeChatInfo === null ||
                      item?.activeChatInfo?.profileUrl === "" ||
                      item?.activeChatInfo?.profileUrl === null
                      ? userimg12
                      : `${Config.API_HOST_URL_live}${item?.activeChatInfo?.profileUrl}`,
                  userProfile:
                    item?.userProfile === undefined ||
                      item?.userProfile === null ||
                      item?.userProfile === ""
                      ? userimg12
                      : `${Config.API_HOST_URL_live}${item?.userProfile}`,
                };
              });
              setSeaUsList(srlist);
              setOpenLoader(false);
            }
          });
      }
    } else {
      setSeaUsList([]);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      searchField === "" ? setSeaUsList([]) : <></>;
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [searchField]);

  useEffect(() => {
    connection?.invoke("LeaveRoom");
    if (userChatDetail?.userInfo?.userId) {
      connection?.invoke("JoinRoom", userChatDetail?.userInfo?.userId);
      if (userList) {
        setUserList(false);
      }
    }
  }, [userChatDetail]);

  const handleDeleteChat = async (id) => {
    if (connection?.state === signalR.HubConnectionState.Connected) {
      try {
        await connection
          .invoke("DeleteChatData", id?.chatId, LogUserId)
          .then((data) => { });
      } catch (err) {
        console.error("Failed to fetch chat list: ", err);
      }
    } else {
      console.warn(
        "Connection not established. Current state:",
        connection?.state
      );
    }
  };

  useEffect(() => {
    const fetchChatList = async (userId) => {
      if (connection && connection.state === "Connected") {
        try {
          await connection.invoke("GetAllChatUsers", userId);
        } catch (err) {
          console.error("Failed to fetch chat list: ", err);
        }
      } else {
        console.warn(
          "Connection not established. Current state:",
          connection?.state
        );
      }
    };
    if (connection) {
      // Listen for the "GetAllMessages" event from the backend
      connection.on("GetAllChatUsers", (data) => {
        setUsList(data.payload); // Update the chat list state with received data
      });
      connection.on("DeleteChatData", (data) => {
        if (data?.isDelete) {
          setUserChatDeatil();
        }
      });

      // Invoke the GetAllMessages method from the backend when necessary

      connection?.on("UserStatusChanged", (userid, status) => {
        setUsList((old) => {
          return old?.map((x) => {
            if (x?.userInfo?.userId === userid) {
              return {
                ...x,
                userInfo: {
                  ...x?.userInfo,
                  userStatus: status ? "Online" : "Offline",
                },
              };
            }
            return x;
          });
        });
      });

      // Replace `LogUserId` with the actual user ID from your context
      if (LogUserId) {
        fetchChatList(LogUserId); // Fetch chat list for the logged-in user
      }

      // Cleanup the listener on unmount
      return () => {
        connection.off("GetAllChatUsers");
        connection?.invoke("LeaveRoom");
      };
    }
  }, [connection?.state, LogUserId, startNewchat]);

  // useEffect(()=>{

  // },[]);

  const toggleUserList = () => {
    if (windowDimensions <= 768) {
      setUserList((prevState) => !prevState);
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="chatScreen main_backcolor">
          <div className="chat_page">
            <UsersList
              usList={usList}
              filteredData={seaUsList}
              searchField={searchField}
              openLoader={openLoader}
              userChatDetail={userChatDetail}
              startNewChat={startNewchat}
              userList={userList}
              setUserList={setUserList}
              setSearchField={setSearchField}
              setUserChatDeatil={setUserChatDeatil}
              setStartNewChat={setStartNewChat}
              handleDeleteChat={handleDeleteChat}
            />
            <UserMessages
              userChatDetail={userChatDetail}
              connection={connection}
              userList={userList}
              windowDimensions={windowDimensions}
              toggleUserList={toggleUserList}
            />
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default React.memo(Messages);
