import React, { useEffect, useState } from "react";
import Pagination from "../../Reuseable_Components/Paginations/Pagination";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  styled,
  Box,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import search_icon from "../../../images/Icon/table_search_icon.svg";
import CreateGoal from "../../Reuseable_Components/Popup/CreateGoal/CreateGoal";
import Config from "../../../Utils/Config";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../Action/Action";
import Deletepopup from "../Settings/Delete_popup";
import "./index.scss";

const columns = [
  { field: "goalName", headerName: "Title" },
  { field: "goalCategoryName", headerName: "Category" },
  { field: "targetCompletionDate", headerName: "Target Completion Date" },
  { field: "completionDate", headerName: "Actual Completion Date" },
  { field: "status", headerName: "Status" },
  { field: "Actions", headerName: "Actions" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  fontFamily: `"Poppins", sans-serif`,
  borderBottom: "0px !important",
  "&.pdf_download": {
    fontSize: "9px !important",
    padding: "3px !important",

    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    background: "linear-gradient(90deg,#1ebec826 -7.19%,#86c65a30 105.94%)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function Goal() {
  const [goalList, setGoalList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  let changeDash = useSelector((state) => state.user.Drefresh);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  function descendingComparator(a, b, orderBy) {
    if (
      b[orderBy]?.toString()?.toLowerCase() <
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString()?.toLowerCase() >
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [searchField, setSearchField] = useState("");
  const formattedSearchResults = goalList?.filter((user) => {
    if (searchField) {
      let state = false;
      columns?.forEach((property) => {
        let value = String(user[property?.field]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {columns?.map((headCell) => (
            <StyledTableCell
              key={headCell?.field}
              align="left"
              sortDirection={orderBy === headCell?.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.field}
                direction={orderBy === headCell?.field ? order : "asc"}
                onClick={createSortHandler(headCell?.field)}
              >
                {headCell?.headerName}

                {orderBy === headCell?.field ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  useEffect(() => {
    getGoalList();
    // eslint-disable-next-line
  }, [changeDash]);

  const getGoalList = async () => {
    setGoalList([]);
    let seData = {
      url: `/api/Goal/GoalList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let gl = res.data.payload?.map((x) => {
          let newg = {
            ...x,
            targeteditDate: x?.targetCompletionDate,
            editDate: x?.completionDate,
          };
          return {
            ...x,
            targeteditDate: x?.targetCompletionDate,
            editDate: x?.completionDate,
            targetCompletionDate: x?.targetCompletionDate
              ? new Date(x?.targetCompletionDate)?.toLocaleDateString("EN-US", {
                  month: "2-digit",
                  year: "numeric",
                  day: "2-digit",
                })
              : "",
            completionDate: x?.completionDate
              ? new Date(x?.completionDate)?.toLocaleDateString("EN-US", {
                  month: "2-digit",
                  year: "numeric",
                  day: "2-digit",
                })
              : "",
            Actions: (
              <>
                {x.status !== "Completed" ? (
                  <>
                    <CreateGoal edit={newg} completed={true} />
                  </>
                ) : (
                  ""
                )}
                <CreateGoal edit={newg} />
                <Deletepopup
                  title="Delete Goal"
                  text={x?.goalName}
                  method={"post"}
                  url={`${Config.API_HOST_URL_live}/api/Goal/DeleteGoal?GoalId=${x.goalId}&userId=${LogDetail?.userId}`}
                />
              </>
            ),
          };
        });
        setGoalList(gl);
      }
    }
  };
  return (
    <section className="goal_page main_backcolor ">
      <div className="container-fluid">
        <div className="main_wrapper">
          <div className="chart_title_box d-flex justify-content-between">
            <label className="chart_title">Goal</label>
            <div className=" search_sec w-25 ms-auto me-4">
              <div className="search_input w-100">
                <input
                  onChange={(e) => {
                    setSearchField(e.target.value);
                    setCurrentPage(1);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <img src={search_icon} alt="" />
              </div>
            </div>
            <CreateGoal title="Goal" />
          </div>
          <div className="show_back table-responsive">
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {stableSort(
                  formattedSearchResults,
                  getComparator(order, orderBy)
                )
                  ?.slice(
                    (currentPage - 1) * pageSize,
                    (currentPage - 1) * pageSize + pageSize
                  )
                  ?.map((data, index) => (
                    <StyledTableRow key={index + 1} className="table_pading">
                      {columns?.map((column) => {
                        const value = data[column?.field];
                        return (
                          <StyledTableCell
                            className="column_data"
                            key={column?.field}
                          >
                            {column?.format && typeof value === "number"
                              ? column?.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            {formattedSearchResults?.length === 0 ? (
              <div className="text-center my-3 p_20_text">Data Not Found</div>
            ) : (
              ""
            )}
          </div>
          {formattedSearchResults?.length > 0 ? (
            <div className="page_nation mt-2">
              <div className="rowsPerPageOptions">
                <span className="p_18_text">show</span>
                <select
                  className="pagination_select p_16_text"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
                <span className="p_18_text">entries</span>
              </div>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={formattedSearchResults?.length}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
}

export default Goal;
